export default {
	SET_TITLE: "SET_TITLE",
	SET_FANCY_TITLES: "SET_FANCY_TITLES",
	SET_FANCY_TITLE_LOADER: "SET_FANCY_TITLE_LOADER",
	SET_KEYWORDS: "SET_KEYWORDS",
	SET_TITLE_OUTLINE: "SET_TITLE_OUTLINE",
	SET_ARTICLE: "SET_ARTICLE",
	SET_SAVE_ARTICLE: "SET_SAVE_ARTICLE",
	SET_SAVE_ARTICLE_FOR_USER: "SET_SAVE_ARTICLE_FOR_USER",
	SET_SAVE_EDITED_ARTICLE: "SET_SAVE_EDITED_ARTICLE",
	SET_USER_ARTICLES: "SET_USER_ARTICLES",
	SET_OUTLINE_CREATE_LOADER: "SET_OUTLINE_CREATE_LOADER",
	SET_ARTICLE_CREATE_LOADER: "SET_ARTICLE_CREATE_LOADER",
	SET_ARTICLE_BY_ID: "SET_ARTICLE_BY_ID",
	SET_ARTICLE_BY_ID_LOADER: "SET_ARTICLE_BY_ID_LOADER",
	SET_PANEL_LOADER: "SET_PANEL_LOADER",
	SET_VIEW_ARTICLE_BY_ID_LOADER: "SET_VIEW_ARTICLE_BY_ID_LOADER",
	SET_ARTICLE_SUMMARY: "SET_ARTICLE_SUMMARY",
	SET_TEAM_MEMBER_LIST: "SET_TEAM_MEMBER_LIST",
	SET_ARTICLE_SEO_SCORE: "SET_ARTICLE_SEO_SCORE",
};
