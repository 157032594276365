import React from "react";
import './viewTeamMembers.scss';
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { deleteTeamMember, viewTeamMembersList } from "../../../../redux/actions/panel";
import { ClimbingBoxLoader, DotLoader } from "react-spinners";
import moment from "moment";
import { Redirect } from "react-router-dom/cjs/react-router-dom";
import routes from "../../../../config/routes";

const ViewTeamMembers = () => {

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(viewTeamMembersList())
    }, [])

    const user =
        useSelector((state) => state?.Authentication?.user) ||
        localStorage.getItem("User");

    const {
        email,
        name,
        _id: userId,
        isSSO,
        role
    } = JSON.parse(user);

    const membersList = useSelector((state) => state?.PanelData?.teamMembersList);

    const loader = useSelector((state) => state?.PanelData?.panelLoader);

    const handleDeleteMember = (item) => {
        dispatch(deleteTeamMember(item?._id))
    }

    const expiryDetail = useSelector((state) => state?.Pricing?.expiryDetail);

    const teamMemberShow = expiryDetail?.subscription?.subscriptionPlan?.includes('Pro') || expiryDetail?.subscription?.subscriptionPlan?.includes('Enterprise') || expiryDetail?.subscription?.subscriptionPlan?.includes('Team') || expiryDetail?.subscription?.subscriptionPlan === "trollyai_tier1" || expiryDetail?.subscription?.subscriptionPlan === "trollyai_tier2"


    if (!teamMemberShow) {
        return <Redirect to={routes.panel.dashboard} />
    }

    if (role === 'Team Member') {
        return <Redirect to={routes.panel.dashboard} />
    }

    return (
        <>
            <section id="view-team-members">
                <div className="top">
                    <h2>Team Members</h2>
                </div>
                <div className="document_sec">
                    <div className="document_card_blk">
                        {
                            loader ?
                                <div className="loaderWrapper">
                                    <DotLoader className="login-clip-loader" color={"#7F56D9"} />
                                    <h1
                                        style={{
                                            color: "#7F56D9",
                                            fontSize: "2.4rem",
                                        }}
                                    >
                                        &nbsp;&nbsp;&nbsp;&nbsp;Fetching your data. Please wait...
                                    </h1>
                                </div>
                                :
                                <>
                                    {
                                        membersList?.length === 0 ?
                                            <div
                                                className="col-lg-12 col-md-12 col-sm-12 col-12"
                                                style={{
                                                    width: "100%",
                                                    display: "flex",
                                                    alignItems: "center",
                                                    justifyContent: "center",
                                                }}
                                            >
                                                <ClimbingBoxLoader color="#7F56D9" />
                                                <h3 style={{ color: "#7F56D9", marginBottom: "0px" }}>
                                                    Sorry No Data Available
                                                </h3>
                                            </div>
                                            :
                                            membersList.map((item, index) => {
                                                return (
                                                    <div
                                                        className="document_card"
                                                    // onClick={() => handleViewItem(item)}
                                                    >
                                                        <h2>{item?.email}</h2>
                                                        <span className="date">
                                                            {moment(item?.createdAt).format("LL")}
                                                        </span>
                                                        <br />
                                                        <span className="date" style={{ color: 'red', cursor: 'pointer' }}
                                                            onClick={() => handleDeleteMember(item)}
                                                        >
                                                            Delete
                                                        </span>
                                                    </div>
                                                )
                                            })
                                    }
                                </>

                        }
                    </div>
                </div>
            </section>
        </>
    )
}

export default ViewTeamMembers;
