import React, { useEffect, useState } from "react";
import { Link, Redirect } from "react-router-dom";
import logo from "../../../assets/images/logo.png";
import "./signup.scss";
import useForm from "./useForm";
import validate from "./validation";
import { TextField } from "../../Common/TextField";
import routes from "../../../config/routes";
import { useDispatch, useSelector } from "react-redux";
import {
	loginUserWithGoogle,
	registerUser,
	registerUserWithRefferal,
} from "../../../redux/actions/auth";
import { DotLoader } from "react-spinners";
import GoogleLogin from "react-google-login";
import { gapi } from "gapi-script";
import loginImages from "../../../assets/images/loginImages.jpg";
import { toast } from "react-toastify";

const Signup = (props) => {
	const referralCode = new URLSearchParams(props?.location?.search).get(
		"referralCode"
	);

	const parentId = new URLSearchParams(props?.location?.search).get(
		"parent-id"
	);
	const teamMemberName = new URLSearchParams(props?.location?.search).get(
		"team-member-name"
	);
	const teamMemberEmail = new URLSearchParams(props?.location?.search).get(
		"team-member-email"
	);

	const urlEmail = new URLSearchParams(props?.location?.search).get("email");

	const dispatch = useDispatch();

	const clientId =
		"651676208501-gfismbgiboie4p9p8i6m4nkrg11cboo4.apps.googleusercontent.com";

	useEffect(() => {
		const start = () => {
			gapi.client.init({
				clientId: clientId,
				scope: "profile email",
			});
		};

		gapi.load("client:auth2", start);
	});

	useEffect(() => { //this will be removed for sure 
		if (!user && !token) {
			localStorage.clear();
		}
	}, []);

	const user = useSelector((state) => state?.Authentication?.user);

	const token = useSelector((state) => state?.Authentication?.token);

	const authLoader = useSelector((state) => state?.Authentication?.authLoader);

	const { values, handleChange, errors, getErrors } = useForm(validate);

	const [passwordVisibility, setPasswordVisibility] = useState(false);
	const [confirmPasswordVisibility, setConfirmPasswordVisibility] =
		useState(false);

	useEffect(() => {
		if (urlEmail !== null) {
			handleChange({
				target: { name: "email", value: urlEmail },
			});
		}
	}, [urlEmail]);

	useEffect(() => {
		if (teamMemberName !== null) {
			handleChange({
				target: { name: "username", value: teamMemberName },
			});
			values.username = teamMemberName;
		}
	}, [teamMemberName]);

	useEffect(() => {
		if (teamMemberEmail !== null) {
			handleChange({
				target: { name: "email", value: teamMemberEmail },
			});
		}
	}, [teamMemberEmail]);


	const handleGoogleSuccess = (response) => {
		if (response) {
			const payload = {
				email: response?.wt?.cu,
				name: response?.wt?.Ad,
				isSSO: true,
				password: null,
				role: "Root User",
			};
			dispatch(loginUserWithGoogle(payload));
		}
	};

	const handleGoogleFailure = (err) => {
		toast.error("Failed to login with google.");
		console.log({ err });
	};

	const handleRegister = (e) => {
		e.preventDefault();
		getErrors(e);
		const validateErrors = validate(values);
		let isValid = true;
		Object.keys(validateErrors).forEach((key) => {
			if (validateErrors[key]) {
				isValid = false;
			}
		});

		if (isValid) {
			let payload = {
				name: values.username,
				email: values.email,
				password: values.password,
				role: parentId !== null ? "Team Member" : "Root User",
				parentUserId: parentId,
			};

			if (referralCode) {
				dispatch(registerUserWithRefferal(referralCode, payload));
			} else {
				dispatch(registerUser(payload));
			}
		}
	};

	const emailDisable =
		!(urlEmail === null && teamMemberEmail === null);
	let goForward;

	if (user) {
		goForward =
			JSON.parse(user)?.isEmailVerified === true ||
			JSON.parse(user)?.isSSO === true;
	}

	if (user && JSON.parse(user)?.isEmailVerified === false) {
		return <Redirect to={routes.auth.emailVerification} />;
	}

	if (user && goForward) {
		return <Redirect to={routes.panel.dashboard} />;
	}

	return (
		<>
			<section id="signUp">
				<div className="row m-0">
					<div className="col-md-4 px-0">
						<div className="inner">
							<div className="logo_blk">
								<img src={logo} alt="logo" />
							</div>
							<h1>Nice to see you again</h1>

							<form className="signup_form">
								<label>Name</label>
								<div className="input_blk">
									<TextField
										name="username"
										value={values.username}
										onChange={handleChange}
										placeholder="e.g John"
										isDisabled={teamMemberName !== null}
									/>
									{errors.username && (
										<p className="form-error-message">{errors.username}</p>
									)}
								</div>
								<label>Email</label>
								<div className="input_blk">
									<TextField
										name="email"
										value={values.email}
										onChange={handleChange}
										placeholder="e.g xyz@gmail.com"
										isDisabled={emailDisable}
									/>
									{errors.email && (
										<p className="form-error-message">{errors.email}</p>
									)}
								</div>

								<label>Password</label>
								<div className="input_blk">
									<TextField
										name="password"
										type={passwordVisibility ? "text" : "password"}
										value={values.password}
										onChange={handleChange}
										placeholder="********"
									/>
									<i
										className={`passwordVisibilityReset fa ${
											passwordVisibility
												? "fa-eye icon_blk"
												: "fa-eye-slash icon_blk"
										}`}
										onClick={() => setPasswordVisibility(!passwordVisibility)}
									/>
									{errors.password && (
										<p className="form-error-message">{errors.password}</p>
									)}
								</div>

								<label>Confirm Password</label>
								<div className="input_blk">
									<TextField
										name="confirmPassword"
										type={confirmPasswordVisibility ? "text" : "password"}
										value={values.confirmPassword}
										onChange={handleChange}
										placeholder="********"
									/>
									<i
										className={`passwordVisibilityReset fa ${
											confirmPasswordVisibility
												? "fa-eye icon_blk"
												: "fa-eye-slash icon_blk"
										}`}
										onClick={() =>
											setConfirmPasswordVisibility(!confirmPasswordVisibility)
										}
									/>
									{errors.confirmPassword && (
										<p className="form-error-message">
											{errors.confirmPassword}
										</p>
									)}
								</div>

								{authLoader ? (
									<div className="loaderWrapper">
										<DotLoader
											className="login-clip-loader"
											color={"#7F56D9"}
										/>
										<h1
											style={{
												color: "#7F56D9",
												fontSize: "2.4rem",
											}}
										>
											&nbsp;&nbsp;&nbsp;&nbsp;Logging you in...
										</h1>
									</div>
								) : (
									<button
										type="button"
										className="signup_btn"
										onClick={handleRegister}
									>
										Sign up
									</button>
								)}
								<hr />
								{emailDisable ? 
         									""
         								 : 
         									<GoogleLogin
         										className="googleLogin-Package"
         										scope="profile email"
         										clientId={clientId}
         										buttonText="Continue With Google"
         										onSuccess={handleGoogleSuccess}
         										onFailure={handleGoogleFailure}
         										cookiePolicy={"single_host_origin"}
         										accessType="online"
         									/>}
								<p className="login_acc">
									Do you have an account?&nbsp;
									<Link to={routes.auth.logIn} className="blue_text">
										Login
									</Link>
								</p>
							</form>
						</div>
					</div>
					<div className="col-md-8 px-0" style={{ background: "#fff" }}>
						<div className="img_blk">
							<img src={loginImages} alt="" />
						</div>
					</div>
				</div>
			</section>
		</>
	);
};

export default Signup;
