import React, { useEffect, useState } from "react";

import wizardIcon from "../../../assets/images/wizardIcon.svg";
import document from "../../../assets/images/document.svg";

import "./PanelHome.scss";
import { Link, Redirect } from "react-router-dom/cjs/react-router-dom.min";
import routes from "../../../config/routes";
import { useDispatch, useSelector } from "react-redux";
import {
  getArticleById,
  getUserArticles,
  setViewArticleByIdLoader,
} from "../../../redux/actions/panel";
import { ClimbingBoxLoader, DotLoader } from "react-spinners";
import moment from "moment/moment";

const PanelHome = () => {
  useEffect(() => {
    localStorage?.removeItem("Article-Generate-Check");
    localStorage.removeItem("Saved-Article");
    localStorage.removeItem("Article-Generated");
    localStorage.removeItem("Article-By-Id");
    localStorage.removeItem("Fancy-Titles");
    localStorage.removeItem("Selected-Outline");
    localStorage.removeItem("Selected-Fancy-Title");
    localStorage.removeItem("Fancy-Headline");
    localStorage.removeItem("article-length");
    localStorage.removeItem("123");
    localStorage.removeItem("Language");
    localStorage.removeItem("Title");
    localStorage.removeItem("Value");
    localStorage.removeItem("Keywords");
  }, []);

  const dispatch = useDispatch();

  const [redirectToWizard, setRedirectToWizard] = useState(false);

  const [redirect, setRedirect] = useState(false);
  const [redirectState, setRedirectState] = useState({});
  const user =
    useSelector((state) => state?.Authentication?.user) 

  const userId = JSON.parse(user)?._id;
  const role = JSON.parse(user)?.role;

  const token = useSelector((state) => state?.Authentication?.token);

  useEffect(() => {
    dispatch(setViewArticleByIdLoader(false));
  }, []);
  useEffect(() => {
    if (role === 'Team Member') {
      const parentUserId = JSON.parse(user)?.parentUserId;
      dispatch(getUserArticles(parentUserId));
    }
    if (role !== 'Team Member') {
      if (userId) {
        dispatch(getUserArticles(userId));
      }
    }
    localStorage.removeItem('refreshed');
  }, [dispatch, role]);

  const userArticles = useSelector((state) => state?.PanelData?.userArticles);
  const viewArticleByIdLoader = useSelector(
    (state) => state?.PanelData?.viewArticleByIdLoader
  );
  const panelLoader = useSelector((state) => state?.PanelData?.panelLoader);


  const handleViewItem = (value) => {
    if (value) {
      setRedirectState(value);
      dispatch(getArticleById(value?.dataId));
      setRedirect(true);
    }
  };

  if (redirect && viewArticleByIdLoader) {
    return (
      <Redirect
        to={{
          pathname: routes.panel.viewArticle,
          state: { itemDetail: redirectState },
        }}
      />
    );
  }

  return (
    <>
      <section id="PanelHome">
        <h2>Home</h2>

        <div className="card_blk">
          <div className="wizard_card">
            <Link to={routes.panel.wizard}>
              <div className="card_body">
                <div className="icon_blk">
                  <img src={wizardIcon} alt="" />
                </div>
                <div className="text_blk">
                  <h4>Wizard</h4>
                  <p>
                    Go from idea to outline jump start your creative process
                  </p>
                </div>
              </div>
            </Link>
          </div>

          <div className="document_card">
            <Link to={routes.panel.documents}>
              <div className="card_body">
                <div className="icon_blk">
                  <img src={document} alt="" />
                </div>
                <div className="text_blk">
                  <h4>My Documents</h4>
                  <p>Find all your Documents at one place</p>
                </div>
              </div>
            </Link>
          </div>
        </div>

        <div className="document_sec">
          <div className="head">
            <div className="left_sec">
              <h4>My Documents</h4>
              <span>Manage your documents here</span>
            </div>
            <button className="view_btn">
              <Link to={routes.panel.documents} style={{ color: "#475467" }}>
                View All
              </Link>
              <i className="fa fa-solid fa-arrow-right" />
            </button>
          </div>
          <div className="document_card_blk row">
            {panelLoader ? (
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <DotLoader color="#7F56D9" />
              </div>
            ) : userArticles?.length === 0 ? (
              <div
                className="col-lg-12 col-md-12 col-sm-12 col-12"
                style={{
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <ClimbingBoxLoader color="#7F56D9" />
                <h3 style={{ color: "#7F56D9", marginBottom: "0px" }}>
                  Sorry No Data Available
                </h3>
              </div>
            ) : (
              userArticles?.map((item, index) => {
                return (
                  index < 6 && (
                    <div
                      key={item.createdAt}
                      className="document_card col-md-3"
                      onClick={() => handleViewItem(item)}
                      style={{ cursor: "pointer" }}
                    >
                      <h2>{item?.dataTitle}</h2>
                      <span className="date">
                        {moment(item?.createdAt).format("LL")}
                      </span>
                    </div>
                  )
                );
              })
            )}
          </div>
        </div>
        <div className="upgrade_plan">
          <div className="left_sec">
            <h3>Unlock unlimited articles!</h3>
            <p>
              Join over 3,000+ users unleash the power of AI to write faster.
            </p>
          </div>
          <Link to={routes.panel.pricing}>
            <button type="button" className="upgrade_btn">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
              >
                <g clipPath="url(#clip0_185_6061)">
                  <path
                    d="M9.99999 13.3333V6.66666M9.99999 6.66666L12.9167 9.58332M9.99999 6.66666L7.08333 9.58332"
                    stroke="white"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M10 18.3333C14.6024 18.3333 18.3333 14.6024 18.3333 9.99999C18.3333 5.39762 14.6024 1.66666 10 1.66666C5.39763 1.66666 1.66667 5.39762 1.66667 9.99999C1.66667 14.6024 5.39763 18.3333 10 18.3333Z"
                    stroke="white"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_185_6061">
                    <rect width="20" height="20" fill="white" />
                  </clipPath>
                </defs>
              </svg>
              Upgrade Plan
            </button>
          </Link>
        </div>
      </section>
    </>
  );
};

export default PanelHome;
