import React, { useState } from "react";
import zapier from "../../assets/images/zapier.svg";
import "./zapier.scss";
import { Link } from "react-router-dom/cjs/react-router-dom";
import routes from "../../config/routes";
import { useDispatch, useSelector } from "react-redux";
import { Modal, Button } from "react-bootstrap";
import { FaWandMagicSparkles } from "react-icons/fa6";
import { FaRegCopy } from "react-icons/fa";
import { toast } from "react-toastify";
import {updatePassword } from "../../redux/actions/auth";

const Zapier = () => {
	const [generatePasswordModal, setGeneratePasswordModal] = useState(false);
	const [password, setPassword] = useState("");

	const user =
		useSelector((state) => state?.Authentication?.user)

	const userObj = JSON.parse(user);

	const isOAuthUser = !userObj?.password;

	const generatePassword = () => {
		const length = 15;
		const charset =
			"abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!@#$%^&*()_+~`|}{[]:;?><,./-=";
		let password = "";
		for (let i = 0, n = charset.length; i < length; ++i) {
			password += charset.charAt(Math.floor(Math.random() * n));
		}
		setPassword(password);
	};

	const copyPassword = () => {
		navigator.clipboard.writeText(password);
		toast.success("Password copied to clipboard!");
	};

	const dispatch = useDispatch();

	const token = useSelector((state) => state?.Authentication?.token);

	const handleResetPassword = () => {
		const payload = {
			password: password,
			token: token,
		};
		dispatch(updatePassword(payload));

		setGeneratePasswordModal(false);

		toast.success("Password updated successfully!");

		setPassword("");

		window.open("https://zapier.com/platform/login", "_blank");
	};

	return (
		<>
			<section id="zapier">
				<h2>Integrations</h2>

				<div className="inner">
					<h3>Integrations and connected apps</h3>
					<p>
						Supercharge your workflow and connect the tool you use every day.
					</p>

					<div className="zapier_card">
						<div className="top">
							<div className="icon_sec">
								<div className="icon_blk">
									<img src={zapier} />
								</div>
								<h4>Zapier</h4>
							</div>
							<button
								type="button"
								className="connect_btn"
								onClick={() => {
									isOAuthUser
										? setGeneratePasswordModal(true)
										: window.open(
												"https://zapier.com/platform/login",
												"_blank"
										  );
								}}
							>
								Connect
							</button>
						</div>
						<p>Build custom automations and integrations with apps.</p>
						<p>
							<Link
								to={routes.panel.zapier.zapierGuides}
								style={{ color: "black", textDecoration: "underline" }}
							>
								Read Guides
							</Link>
						</p>
					</div>
				</div>

				<Modal
					show={generatePasswordModal}
					size="lg"
					aria-labelledby="contained-modal-title-vcenter"
					centered
					style={{
						color: "black",
					}}
				>
					<Modal.Header
						style={{
							padding: "20px",
							display: "flex",
							flexDirection: "column",
							alignItems: "flex-start",
						}}
					>
						<h4
							style={{
								color: "black",
								fontWeight: "700",
								textAlign: "center",
							}}
						>
							Set Password to Connect with Zapier
						</h4>
						<p>
							You will use this password to connect with Zapier. You can change
							this password later.
						</p>
					</Modal.Header>
					<Modal.Body>
						<div
							style={{
								width: "100%",
								display: "grid",
								gridTemplateColumns: "1fr .3fr",
								gap: "10px",
							}}
						>
							<div className="input_blk">
								<input
									type="text"
									value={password}
									onChange={(e) => {
										setPassword(e.target.value);
									}}
									placeholder="Enter Password"
								/>
							</div>
							<div
								style={{
									display: "flex",
									alignItems: "center",
									justifyContent: "space-between",
									gap: "10px",
								}}
							>
								<button
									style={{
										backgroundColor: "transparent",
										border: "none",
										color: "black",
										fontWeight: "600",
										cursor: "pointer",
										fontSize: "14px",
										textDecoration: "underline",
										display: "flex",
										alignItems: "center",
										justifyContent: "center",
										gap: "5px",
									}}
									onClick={copyPassword}
								>
									<FaRegCopy />
									Copy
								</button>
								<button
									style={{
										backgroundColor: "transparent",
										border: "none",
										color: "black",
										fontWeight: "600",
										cursor: "pointer",
										fontSize: "14px",
										textDecoration: "underline",
										display: "flex",
										alignItems: "center",
										justifyContent: "center",
										gap: "5px",
										whiteSpace: "nowrap",
									}}
									onClick={generatePassword}
								>
									<FaWandMagicSparkles />
									Auto Generate
								</button>
							</div>
						</div>
					</Modal.Body>
					<Modal.Footer
						style={{
							display: "flex",
							alignItems: "center",
							justifyContent: "flex-end",
						}}
					>
						<Button
							className="modal-no-button"
							onClick={() => {
								setGeneratePasswordModal(false);
							}}
						>
							Cancel
						</Button>
						<Button className="modal-yes-button" onClick={handleResetPassword}>
							Continue
						</Button>
					</Modal.Footer>
				</Modal>
			</section>
		</>
	);
};

export default Zapier;
