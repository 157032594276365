import React, { useState } from "react";
import checkicon from "../../../assets/images/checkicon.svg";
import "./pricingPlans.scss";
import { useDispatch, useSelector } from "react-redux";
import { Redirect } from "react-router-dom/cjs/react-router-dom.min";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import routes from "../../../config/routes";
import { cancelSubscription, renewSubscription } from "../../../redux/actions/pricing";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

const PricingPlans = () => {

  const [pricingToggle, setPricingToggle] = useState(false);

  const dispatch = useDispatch();

  const [modalShow, setModalShow] = useState(false);
  const [renewModal, setRenewModal] = useState(false);
  const [appSomoDeal, setAppSomoDeal] = useState(false);

  const token = useSelector((state) => state?.Authentication?.token);

  const user =
    useSelector((state) => state?.Authentication?.user) ||
    localStorage.getItem("User");
  const { _id: userId, role } = JSON.parse(user);

  const [monthPlan, setMonthPlan] = useState(false);
  const [yearPlan, setYearPlan] = useState(false);
  const [monthPlanPro, setMonthPlanPro] = useState(false);
  const [yearPlanPro, setYearPlanPro] = useState(false);
  const [monthPlanTeam, setMonthPlanTeam] = useState(false);
  const [yearPlanTeam, setYearPlanTeam] = useState(false);
  const [monthPlanEnterprise, setMonthPlanEnterprise] = useState(false);
  const [yearPlanEnterprise, setYearPlanEnterprise] = useState(false);

  const expiryDetail = useSelector((state) => state?.Pricing?.expiryDetail);

  const handleMonthPlan = (e) => {
    e.preventDefault();
    setMonthPlan(true);
  };

  const handleYearPlan = (e) => {
    e.preventDefault();
    setYearPlan(true);
  };

  const handleMonthPlanPro = (e) => {
    e.preventDefault();
    setMonthPlanPro(true);
  };

  const handleYearPlanPro = (e) => {
    e.preventDefault();
    setYearPlanPro(true);
  };

  const handleMonthPlanTeam = (e) => {
    e.preventDefault();
    setMonthPlanTeam(true);
  }

  const handleYearPlanTeam = (e) => {
    e.preventDefault();
    setYearPlanTeam(true);
  }

  const handleMonthPlanEnterprise = (e) => {
    e.preventDefault();
    setMonthPlanEnterprise(true);
  }

  const handleYearPlanEnterprise = (e) => {
    e.preventDefault();
    setYearPlanEnterprise(true);
  }

  const handleCancelPackage = (e) => {
    e.preventDefault();
    dispatch(cancelSubscription(userId));
    setModalShow(false)
  }

  const handleRenewPackage = (e) => {
    let priceId = ''
    e.preventDefault();

    const subscriptionId = expiryDetail?.subscription?.subscriptionId;

    // if (expiryDetail?.subscription?.subscriptionPlan === "Basic Monthly") {
    //   priceId = "price_1NCr1zEfBpiSjTWh1T0wnHLl";
    // }
    // if (expiryDetail?.subscription?.subscriptionPlan === "Pro Monthly") {
    //   priceId = "price_1NCr1YEfBpiSjTWhEOC4aUE9";
    // }
    // if (expiryDetail?.subscription?.subscriptionPlan === "Team Monthly") {
    //   priceId = "price_1NZvnYEfBpiSjTWhDy1brk7j";
    // }
    // if (expiryDetail?.subscription?.subscriptionPlan === "Enterprise Monthly") {
    //   priceId = "price_1NZvnqEfBpiSjTWh7ki7IwW4";
    // }
    // if (expiryDetail?.subscription?.subscriptionPlan === "Basic Yearly") {
    //   priceId = "price_1NZvr5EfBpiSjTWh9KTDWCEw";
    // }
    // if (expiryDetail?.subscription?.subscriptionPlan === "Pro Yearly") {
    //   priceId = "price_1NZvmiEfBpiSjTWhQtmiIF9B";
    // }
    // if (expiryDetail?.subscription?.subscriptionPlan === "Team Yearly") {
    //   priceId = "price_1NZvnhEfBpiSjTWhvyzQGWe7";
    // }
    // if (expiryDetail?.subscription?.subscriptionPlan === "Enterprise Yearly") {
    //   priceId = "price_1NZvnwEfBpiSjTWhOnYzMZVQ";
    // }

    const payload = {subscriptionId} 
    dispatch(renewSubscription(payload, userId));
    setRenewModal(false)
  }

  if (monthPlan) {
    return <Redirect push to={routes.panel.pricingPlans.basicMonthly} />;
  }

  if (monthPlanPro) {
    return <Redirect push to={routes.panel.pricingPlans.proMonthly} />;
  }

  if (yearPlan) {
    return <Redirect push to={routes.panel.pricingPlans.basicYearly} />;
  }

  if (yearPlanPro) {
    return <Redirect push to={routes.panel.pricingPlans.proYearly} />;
  }
  if (monthPlanTeam) {
    return <Redirect push to={routes.panel.pricingPlans.teamMonthly} />;
  }

  if (monthPlanEnterprise) {
    return <Redirect push to={routes.panel.pricingPlans.enterpriseMonthly} />;
  }

  if (yearPlanTeam) {
    return <Redirect push to={routes.panel.pricingPlans.teamYearly} />;
  }

  if (yearPlanEnterprise) {
    return <Redirect push to={routes.panel.pricingPlans.enterpriseYearly} />;
  }

  let monthlyBasicDisable = false;
  let monthlyProDisable = false;
  let yearlyBasicDisable = false;
  let yearlyProDisable = false;
  let monthlyTeamDisable = false;
  let yearlyTeamDisable = false;
  let enterpriseMonthDisable = false;
  let enterpriseYearDisable = false;

  if (expiryDetail?.subscription?.subscriptionPlan === "Basic Monthly") {
    if (expiryDetail?.subscription?.isCanceled === true) {
      monthlyBasicDisable = true;
      monthlyProDisable = false;
      yearlyBasicDisable = false;
      yearlyProDisable = false;
      monthlyTeamDisable = false;
      yearlyTeamDisable = false;
      enterpriseMonthDisable = false;
      enterpriseYearDisable = false;
    }
    else {
      monthlyBasicDisable = true;
      monthlyProDisable = true;
      yearlyBasicDisable = true;
      yearlyProDisable = true;
      monthlyTeamDisable = true;
      yearlyTeamDisable = true;
      enterpriseMonthDisable = true;
      enterpriseYearDisable = true;
    }
  }

  if (expiryDetail?.subscription?.subscriptionPlan === "Pro Monthly") {
    if (expiryDetail?.subscription?.isCanceled === true) {
      monthlyBasicDisable = false;
      monthlyProDisable = true;
      yearlyBasicDisable = false;
      yearlyProDisable = false;
      monthlyTeamDisable = false;
      yearlyTeamDisable = false;
      enterpriseMonthDisable = false;
      enterpriseYearDisable = false;
    }
    else {
      monthlyBasicDisable = true;
      monthlyProDisable = true;
      yearlyBasicDisable = true;
      yearlyProDisable = true;
      monthlyTeamDisable = true;
      yearlyTeamDisable = true;
      enterpriseMonthDisable = true;
      enterpriseYearDisable = true;
    }
  }

  if (expiryDetail?.subscription?.subscriptionPlan === "Basic Yearly") {
    if (expiryDetail?.subscription?.isCanceled === true) {
      monthlyBasicDisable = false;
      monthlyProDisable = false;
      yearlyBasicDisable = true;
      yearlyProDisable = false;
      monthlyTeamDisable = false;
      yearlyTeamDisable = false;
      enterpriseMonthDisable = false;
      enterpriseYearDisable = false;
    }
    else {
      monthlyBasicDisable = true;
      monthlyProDisable = true;
      yearlyBasicDisable = true;
      yearlyProDisable = true;
      monthlyTeamDisable = true;
      yearlyTeamDisable = true;
      enterpriseMonthDisable = true;
      enterpriseYearDisable = true;
    }
  }

  if (expiryDetail?.subscription?.subscriptionPlan === "Pro Yearly") {
    if (expiryDetail?.subscription?.isCanceled === true) {
      monthlyBasicDisable = false;
      monthlyProDisable = false;
      yearlyBasicDisable = false;
      yearlyProDisable = true;
      monthlyTeamDisable = false;
      yearlyTeamDisable = false;
      enterpriseMonthDisable = false;
      enterpriseYearDisable = false;
    }
    else {
      monthlyBasicDisable = true;
      monthlyProDisable = true;
      yearlyBasicDisable = true;
      yearlyProDisable = true;
      monthlyTeamDisable = true;
      yearlyTeamDisable = true;
      enterpriseMonthDisable = true;
      enterpriseYearDisable = true;
    }
  }

  if (expiryDetail?.subscription?.subscriptionPlan === "Team Monthly") {
    if (expiryDetail?.subscription?.isCanceled === true) {
      monthlyBasicDisable = false;
      monthlyProDisable = false;
      yearlyBasicDisable = false;
      yearlyProDisable = false;
      monthlyTeamDisable = true;
      yearlyTeamDisable = false;
      enterpriseMonthDisable = false;
      enterpriseYearDisable = false;
    }
    else {
      monthlyBasicDisable = true;
      monthlyProDisable = true;
      yearlyBasicDisable = true;
      yearlyProDisable = true;
      monthlyTeamDisable = true;
      yearlyTeamDisable = true;
      enterpriseMonthDisable = true;
      enterpriseYearDisable = true;
    }
  }

  if (expiryDetail?.subscription?.subscriptionPlan === "Team Yearly") {
    if (expiryDetail?.subscription?.isCanceled === true) {
      monthlyBasicDisable = false;
      monthlyProDisable = false;
      yearlyBasicDisable = false;
      yearlyProDisable = false;
      monthlyTeamDisable = false;
      yearlyTeamDisable = true;
      enterpriseMonthDisable = false;
      enterpriseYearDisable = false;
    }
    else {
      monthlyBasicDisable = true;
      monthlyProDisable = true;
      yearlyBasicDisable = true;
      yearlyProDisable = true;
      monthlyTeamDisable = true;
      yearlyTeamDisable = true;
      enterpriseMonthDisable = true;
      enterpriseYearDisable = true;
    }
  }

  if (expiryDetail?.subscription?.subscriptionPlan === "Enterprise Monthly") {
    if (expiryDetail?.subscription?.isCanceled === true) {
      monthlyBasicDisable = false;
      monthlyProDisable = false;
      yearlyBasicDisable = false;
      yearlyProDisable = false;
      monthlyTeamDisable = false;
      yearlyTeamDisable = false;
      enterpriseMonthDisable = true;
      enterpriseYearDisable = false;
    }
    else {
      monthlyBasicDisable = true;
      monthlyProDisable = true;
      yearlyBasicDisable = true;
      yearlyProDisable = true;
      monthlyTeamDisable = true;
      yearlyTeamDisable = true;
      enterpriseMonthDisable = true;
      enterpriseYearDisable = true;
    }
  }

  if (expiryDetail?.subscription?.subscriptionPlan === "Enterprise Yearly") {
    if (expiryDetail?.subscription?.isCanceled === true) {
      monthlyBasicDisable = false;
      monthlyProDisable = false;
      yearlyBasicDisable = false;
      yearlyProDisable = false;
      monthlyTeamDisable = false;
      yearlyTeamDisable = false;
      enterpriseMonthDisable = false;
      enterpriseYearDisable = true;
    }
    else {
      monthlyBasicDisable = true;
      monthlyProDisable = true;
      yearlyBasicDisable = true;
      yearlyProDisable = true;
      monthlyTeamDisable = true;
      yearlyTeamDisable = true;
      enterpriseMonthDisable = true;
      enterpriseYearDisable = true;
    }
  }

  let lifeTimePlan = expiryDetail?.subscription?.subscriptionPlan === 'LifeTime Deal' ? true : false;

  let renew = expiryDetail?.subscription?.isCanceled === true ? true : false;

  let boughtAppSomoDeal = (expiryDetail?.subscription?.subscriptionPlan === 'trollyai_tier1' || expiryDetail?.subscription?.subscriptionPlan === 'trollyai_tier2') ? true : false;

  if (user === undefined || token === undefined) {
    return <Redirect to={routes.auth.logIn} />
  }

  if (role === 'Team Member') {
    return <Redirect to={routes.panel.dashboard} />
  }

  return (
    <>
      {
        lifeTimePlan ?
          <section id="pricing-plan">
            <h2>You have life time deal.</h2>
            <div className="radio_blk">
              <span>Billed Monthly</span>
              <label className="switch">
                <input type="checkbox" onChange={(e) => setPricingToggle(!pricingToggle)} />
                <span className="slider round"></span>
              </label>
              <span>Billed Yearly (save 17%)</span>
            </div>
            {
              !pricingToggle ?
                <div className="row">
                  <div className="col-xl-3 col-lg-4 col-md-4 col-sm-12">
                    <div className="pricing_card">
                      <h3>Basic</h3>
                      <h3>
                        $29<span>/month</span>
                      </h3>
                      <p>Start unlocking the magic SEO superpowers of AI today</p>
                      <hr />
                      <ul>
                        <li>
                          <div className="icon_blk">
                            <img src={checkicon} alt="" />
                          </div>
                          100 Articles
                        </li>
                        <li>
                          <div className="icon_blk">
                            <img src={checkicon} alt="" />
                          </div>
                          Text Editor
                        </li>
                        <li>
                          <div className="icon_blk">
                            <img src={checkicon} alt="" />
                          </div>
                          Auto SEO Optimization
                        </li>
                        <li>
                          <div className="icon_blk">
                            <img src={checkicon} alt="" />
                          </div>
                          10+ Output Styles
                        </li>
                        <li>
                          <div className="icon_blk">
                            <img src={checkicon} alt="" />
                          </div>
                          20+ Languages
                        </li>
                        <li>
                          <div className="icon_blk">
                            <img src={checkicon} alt="" />
                          </div>
                          Bulk Generation
                        </li>
                        <li>
                          <div className="icon_blk">
                            <img src={checkicon} alt="" />
                          </div>
                          Zapier Automation
                        </li>
                        <li>
                          <div className="icon_blk">
                            <img src={checkicon} alt="" />
                          </div>
                          In-Articles Images
                        </li>
                        <li>
                          <div className="icon_blk">
                            <img src={checkicon} alt="" />
                          </div>
                          Grammar Checker
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="col-xl-3 col-lg-4 col-md-4 col-sm-12">
                    <div className="pricing_card">
                      <h3>Pro </h3>
                      <h3>
                        $59<span>/month</span>
                      </h3>
                      <p>
                        All the AI-features you need for outranking your competitors.
                      </p>
                      <hr />
                      <ul>
                        <li>
                          <div className="icon_blk">
                            <img src={checkicon} alt="" />
                          </div>
                          300 Articles
                        </li>
                        <li>
                          <div className="icon_blk">
                            <img src={checkicon} alt="" />
                          </div>
                          Text Editor
                        </li>
                        <li>
                          <div className="icon_blk">
                            <img src={checkicon} alt="" />
                          </div>
                          Auto SEO Optimization
                        </li>
                        <li>
                          <div className="icon_blk">
                            <img src={checkicon} alt="" />
                          </div>
                          10+ Output Styles
                        </li>
                        <li>
                          <div className="icon_blk">
                            <img src={checkicon} alt="" />
                          </div>
                          20+ Languages
                        </li>
                        <li>
                          <div className="icon_blk">
                            <img src={checkicon} alt="" />
                          </div>
                          Bulk Generation
                        </li>
                        <li>
                          <div className="icon_blk">
                            <img src={checkicon} alt="" />
                          </div>
                          Zapier Automation
                        </li>
                        <li>
                          <div className="icon_blk">
                            <img src={checkicon} alt="" />
                          </div>
                          In-Articles Images
                        </li>
                        <li>
                          <div className="icon_blk">
                            <img src={checkicon} alt="" />
                          </div>
                          Grammar Checker
                        </li>
                        <li>
                          <div className="icon_blk">
                            <img src={checkicon} alt="" />
                          </div>
                          API Access
                        </li>
                        <li>
                          <div className="icon_blk">
                            <img src={checkicon} alt="" />
                          </div>
                          Add 1 Member
                        </li>
                        <li>
                          <div className="icon_blk">
                            <img src={checkicon} alt="" />
                          </div>
                          SERP Analyzer (soon)
                        </li>
                        <li>
                          <div className="icon_blk">
                            <img src={checkicon} alt="" />
                          </div>
                          SEO Analyzer (soon)
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="col-xl-3 col-lg-4 col-md-4 col-sm-12">
                    <div className="pricing_card">
                      <h3>Team</h3>
                      <h3>
                        $149<span>/month</span>
                      </h3>
                      <p>For those who need more amounts of content generation</p>
                      <hr />
                      <ul>
                        <li>
                          <div className="icon_blk">
                            <img src={checkicon} alt="" />
                          </div>
                          1000 Articles
                        </li>
                        <li>
                          <div className="icon_blk">
                            <img src={checkicon} alt="" />
                          </div>
                          Text Editor
                        </li>
                        <li>
                          <div className="icon_blk">
                            <img src={checkicon} alt="" />
                          </div>
                          Auto SEO Optimization
                        </li>
                        <li>
                          <div className="icon_blk">
                            <img src={checkicon} alt="" />
                          </div>
                          10+ Output Styles
                        </li>
                        <li>
                          <div className="icon_blk">
                            <img src={checkicon} alt="" />
                          </div>
                          20+ Languages
                        </li>
                        <li>
                          <div className="icon_blk">
                            <img src={checkicon} alt="" />
                          </div>
                          Bulk Generation
                        </li>
                        <li>
                          <div className="icon_blk">
                            <img src={checkicon} alt="" />
                          </div>
                          Zapier Automation
                        </li>
                        <li>
                          <div className="icon_blk">
                            <img src={checkicon} alt="" />
                          </div>
                          In-Articles Images
                        </li>
                        <li>
                          <div className="icon_blk">
                            <img src={checkicon} alt="" />
                          </div>
                          Grammar Checker
                        </li>
                        <li>
                          <div className="icon_blk">
                            <img src={checkicon} alt="" />
                          </div>
                          API Access
                        </li>
                        <li>
                          <div className="icon_blk">
                            <img src={checkicon} alt="" />
                          </div>
                          Add 5 Member
                        </li>
                        <li>
                          <div className="icon_blk">
                            <img src={checkicon} alt="" />
                          </div>
                          SERP Analyzer (soon)
                        </li>
                        <li>
                          <div className="icon_blk">
                            <img src={checkicon} alt="" />
                          </div>
                          SEO Analyzer (soon)
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="col-xl-3 col-lg-4 col-md-4 col-sm-12">
                    <div className="pricing_card">
                      <h3>Enterprise</h3>
                      <h3>
                        $499<span>/month</span>
                      </h3>
                      <p>
                        Need something with no limits? Go fast & furious along with your
                        team.
                      </p>
                      <hr />
                      <ul>
                        <li>
                          <div className="icon_blk">
                            <img src={checkicon} alt="" />
                          </div>
                          Unlimited Articles
                        </li>
                        <li>
                          <div className="icon_blk">
                            <img src={checkicon} alt="" />
                          </div>
                          Text Editor
                        </li>
                        <li>
                          <div className="icon_blk">
                            <img src={checkicon} alt="" />
                          </div>
                          Auto SEO Optimization
                        </li>
                        <li>
                          <div className="icon_blk">
                            <img src={checkicon} alt="" />
                          </div>
                          10+ Output Styles
                        </li>
                        <li>
                          <div className="icon_blk">
                            <img src={checkicon} alt="" />
                          </div>
                          20+ Languages
                        </li>
                        <li>
                          <div className="icon_blk">
                            <img src={checkicon} alt="" />
                          </div>
                          Bulk Generation
                        </li>
                        <li>
                          <div className="icon_blk">
                            <img src={checkicon} alt="" />
                          </div>
                          Zapier Automation
                        </li>
                        <li>
                          <div className="icon_blk">
                            <img src={checkicon} alt="" />
                          </div>
                          In-Articles Images
                        </li>
                        <li>
                          <div className="icon_blk">
                            <img src={checkicon} alt="" />
                          </div>
                          Grammar Checker
                        </li>
                        <li>
                          <div className="icon_blk">
                            <img src={checkicon} alt="" />
                          </div>
                          API Access
                        </li>
                        <li>
                          <div className="icon_blk">
                            <img src={checkicon} alt="" />
                          </div>
                          Add 10 Member
                        </li>
                        <li>
                          <div className="icon_blk">
                            <img src={checkicon} alt="" />
                          </div>
                          SERP Analyzer (soon)
                        </li>
                        <li>
                          <div className="icon_blk">
                            <img src={checkicon} alt="" />
                          </div>
                          SEO Analyzer (soon)
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                :
                <div className="row">
                  <div className="col-xl-3 col-lg-4 col-md-4 col-sm-12">
                    <div className="pricing_card">
                      <h3>Basic</h3>
                      <h3>
                        $299<span>/year</span>
                      </h3>
                      <p>Start unlocking the magic SEO superpowers of AI today</p>
                      <hr />
                      <ul>
                        <li>
                          <div className="icon_blk">
                            <img src={checkicon} alt="" />
                          </div>
                          100 Articles
                        </li>
                        <li>
                          <div className="icon_blk">
                            <img src={checkicon} alt="" />
                          </div>
                          Text Editor
                        </li>
                        <li>
                          <div className="icon_blk">
                            <img src={checkicon} alt="" />
                          </div>
                          Auto SEO Optimization
                        </li>
                        <li>
                          <div className="icon_blk">
                            <img src={checkicon} alt="" />
                          </div>
                          10+ Output Styles
                        </li>
                        <li>
                          <div className="icon_blk">
                            <img src={checkicon} alt="" />
                          </div>
                          20+ Languages
                        </li>
                        <li>
                          <div className="icon_blk">
                            <img src={checkicon} alt="" />
                          </div>
                          Bulk Generation
                        </li>
                        <li>
                          <div className="icon_blk">
                            <img src={checkicon} alt="" />
                          </div>
                          Zapier Automation
                        </li>
                        <li>
                          <div className="icon_blk">
                            <img src={checkicon} alt="" />
                          </div>
                          In-Articles Images
                        </li>
                        <li>
                          <div className="icon_blk">
                            <img src={checkicon} alt="" />
                          </div>
                          Grammar Checker
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="col-xl-3 col-lg-4 col-md-4 col-sm-12">
                    <div className="pricing_card">
                      <h3>Pro </h3>
                      <h3>
                        $599<span>/year</span>
                      </h3>
                      <p>
                        All the AI-features you need for outranking your competitors.
                      </p>
                      <hr />
                      <ul>
                        <li>
                          <div className="icon_blk">
                            <img src={checkicon} alt="" />
                          </div>
                          300 Articles
                        </li>
                        <li>
                          <div className="icon_blk">
                            <img src={checkicon} alt="" />
                          </div>
                          Text Editor
                        </li>
                        <li>
                          <div className="icon_blk">
                            <img src={checkicon} alt="" />
                          </div>
                          Auto SEO Optimization
                        </li>
                        <li>
                          <div className="icon_blk">
                            <img src={checkicon} alt="" />
                          </div>
                          10+ Output Styles
                        </li>
                        <li>
                          <div className="icon_blk">
                            <img src={checkicon} alt="" />
                          </div>
                          20+ Languages
                        </li>
                        <li>
                          <div className="icon_blk">
                            <img src={checkicon} alt="" />
                          </div>
                          Bulk Generation
                        </li>
                        <li>
                          <div className="icon_blk">
                            <img src={checkicon} alt="" />
                          </div>
                          Zapier Automation
                        </li>
                        <li>
                          <div className="icon_blk">
                            <img src={checkicon} alt="" />
                          </div>
                          In-Articles Images
                        </li>
                        <li>
                          <div className="icon_blk">
                            <img src={checkicon} alt="" />
                          </div>
                          Grammar Checker
                        </li>
                        <li>
                          <div className="icon_blk">
                            <img src={checkicon} alt="" />
                          </div>
                          API Access
                        </li>
                        <li>
                          <div className="icon_blk">
                            <img src={checkicon} alt="" />
                          </div>
                          Add 1 Member
                        </li>
                        <li>
                          <div className="icon_blk">
                            <img src={checkicon} alt="" />
                          </div>
                          SERP Analyzer (soon)
                        </li>
                        <li>
                          <div className="icon_blk">
                            <img src={checkicon} alt="" />
                          </div>
                          SEO Analyzer (soon)
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="col-xl-3 col-lg-4 col-md-4 col-sm-12">
                    <div className="pricing_card">
                      <h3>Team</h3>
                      <h3>
                        $1499<span>/year</span>
                      </h3>
                      <p>For those who need more amounts of content generation</p>
                      <hr />
                      <ul>
                        <li>
                          <div className="icon_blk">
                            <img src={checkicon} alt="" />
                          </div>
                          1000 Articles
                        </li>
                        <li>
                          <div className="icon_blk">
                            <img src={checkicon} alt="" />
                          </div>
                          Text Editor
                        </li>
                        <li>
                          <div className="icon_blk">
                            <img src={checkicon} alt="" />
                          </div>
                          Auto SEO Optimization
                        </li>
                        <li>
                          <div className="icon_blk">
                            <img src={checkicon} alt="" />
                          </div>
                          10+ Output Styles
                        </li>
                        <li>
                          <div className="icon_blk">
                            <img src={checkicon} alt="" />
                          </div>
                          20+ Languages
                        </li>
                        <li>
                          <div className="icon_blk">
                            <img src={checkicon} alt="" />
                          </div>
                          Bulk Generation
                        </li>
                        <li>
                          <div className="icon_blk">
                            <img src={checkicon} alt="" />
                          </div>
                          Zapier Automation
                        </li>
                        <li>
                          <div className="icon_blk">
                            <img src={checkicon} alt="" />
                          </div>
                          In-Articles Images
                        </li>
                        <li>
                          <div className="icon_blk">
                            <img src={checkicon} alt="" />
                          </div>
                          Grammar Checker
                        </li>
                        <li>
                          <div className="icon_blk">
                            <img src={checkicon} alt="" />
                          </div>
                          API Access
                        </li>
                        <li>
                          <div className="icon_blk">
                            <img src={checkicon} alt="" />
                          </div>
                          Add 5 Member
                        </li>
                        <li>
                          <div className="icon_blk">
                            <img src={checkicon} alt="" />
                          </div>
                          SERP Analyzer (soon)
                        </li>
                        <li>
                          <div className="icon_blk">
                            <img src={checkicon} alt="" />
                          </div>
                          SEO Analyzer (soon)
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="col-xl-3 col-lg-4 col-md-4 col-sm-12">
                    <div className="pricing_card">
                      <h3>Enterprise</h3>
                      <h3>
                        $4999<span>/year</span>
                      </h3>
                      <p>
                        Need something with no limits? Go fast & furious along with your
                        team.
                      </p>
                      <hr />
                      <ul>
                        <li>
                          <div className="icon_blk">
                            <img src={checkicon} alt="" />
                          </div>
                          Unlimited Articles
                        </li>
                        <li>
                          <div className="icon_blk">
                            <img src={checkicon} alt="" />
                          </div>
                          Text Editor
                        </li>
                        <li>
                          <div className="icon_blk">
                            <img src={checkicon} alt="" />
                          </div>
                          Auto SEO Optimization
                        </li>
                        <li>
                          <div className="icon_blk">
                            <img src={checkicon} alt="" />
                          </div>
                          10+ Output Styles
                        </li>
                        <li>
                          <div className="icon_blk">
                            <img src={checkicon} alt="" />
                          </div>
                          20+ Languages
                        </li>
                        <li>
                          <div className="icon_blk">
                            <img src={checkicon} alt="" />
                          </div>
                          Bulk Generation
                        </li>
                        <li>
                          <div className="icon_blk">
                            <img src={checkicon} alt="" />
                          </div>
                          Zapier Automation
                        </li>
                        <li>
                          <div className="icon_blk">
                            <img src={checkicon} alt="" />
                          </div>
                          In-Articles Images
                        </li>
                        <li>
                          <div className="icon_blk">
                            <img src={checkicon} alt="" />
                          </div>
                          Grammar Checker
                        </li>
                        <li>
                          <div className="icon_blk">
                            <img src={checkicon} alt="" />
                          </div>
                          API Access
                        </li>
                        <li>
                          <div className="icon_blk">
                            <img src={checkicon} alt="" />
                          </div>
                          Add 10 Member
                        </li>
                        <li>
                          <div className="icon_blk">
                            <img src={checkicon} alt="" />
                          </div>
                          SERP Analyzer (soon)
                        </li>
                        <li>
                          <div className="icon_blk">
                            <img src={checkicon} alt="" />
                          </div>
                          SEO Analyzer (soon)
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
            }
            <div className="free_trial_blk">
              <div className="text">
                <h4>Free Trial</h4>
                <p>Not Available</p>
              </div>
              <button type="button" className="subscribe_btn">
                You have Life Time Deal
              </button>
            </div>
            <div className="redeem_blk">
              <div className="text">
                <h4>Manage Your AppSumo Deal</h4>
                <span>Not Available</span>
              </div>
              <button type="button" className="subscribe_btn">
                You have Life Time Deal
              </button>
            </div>
          </section>
          :
          boughtAppSomoDeal ?
            <section id="pricing-plan">
              <h2>{" "}</h2>
              <div className="row">
                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                  <div className={`${expiryDetail?.subscription?.subscriptionPlan === 'trollyai_tier1' ? 'pricing_card active-app' : 'pricing_card'}`}>
                    <h3>{expiryDetail?.subscription?.subscriptionPlan === 'trollyai_tier1' ? 'Bought License Tier 1' : 'License Tier 1'}</h3>
                    <h3>
                      $49
                    </h3>
                    <p>Start unlocking the magic SEO superpowers of AI today</p>
                    <hr />
                    <ul>
                      <li>
                        <div className="icon_blk">
                          <img src={checkicon} alt="" />
                        </div>
                        30 Articles
                      </li>
                      <li>
                        <div className="icon_blk">
                          <img src={checkicon} alt="" />
                        </div>
                        Text Editor
                      </li>
                      <li>
                        <div className="icon_blk">
                          <img src={checkicon} alt="" />
                        </div>
                        Auto SEO Optimization
                      </li>
                      <li>
                        <div className="icon_blk">
                          <img src={checkicon} alt="" />
                        </div>
                        20+ Languages
                      </li>
                      <li>
                        <div className="icon_blk">
                          <img src={checkicon} alt="" />
                        </div>
                        Grammar Checker
                      </li>
                      <li>
                        <div className="icon_blk">
                          <img src={checkicon} alt="" />
                        </div>
                        Bulk Generation
                      </li>
                      <li>
                        <div className="icon_blk">
                          <img src={checkicon} alt="" />
                        </div>
                        Zapier Automation
                      </li>
                      <li>
                        <div className="icon_blk">
                          <img src={checkicon} alt="" />
                        </div>
                        In-Articles Images
                      </li>

                      <li>
                        <div className="icon_blk">
                          <img src={checkicon} alt="" />
                        </div>
                        API Access
                      </li>
                      <li>
                        <div className="icon_blk">
                          <img src={checkicon} alt="" />
                        </div>
                        Add 1 Member
                      </li>
                      <li>
                        <div className="icon_blk">
                          <img src={checkicon} alt="" />
                        </div>
                        WordPress Integration
                      </li>
                      <li>
                        <div className="icon_blk">
                          <img src={checkicon} alt="" />
                        </div>
                        Built-in Templates
                      </li>
                      <li>
                        <div className="icon_blk">
                          <img src={checkicon} alt="" />
                        </div>
                        AI Engine: OpenAI CHATGPT-3.5 & CHATGPT-4 (& All Future Added AI Models)
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                  <div className={`${expiryDetail?.subscription?.subscriptionPlan === 'trollyai_tier2' ? 'pricing_card active-app' : 'pricing_card'}`}>
                    <h3>{expiryDetail?.subscription?.subscriptionPlan === 'trollyai_tier2' ? 'Bought License Tier 2' : 'License Tier 2'}</h3>
                    <h3>
                      $179
                    </h3>
                    <p>
                      All the AI-features you need for outranking your competitors.
                    </p>
                    <hr />
                    <ul>
                      <li>
                        <div className="icon_blk">
                          <img src={checkicon} alt="" />
                        </div>
                        Unlimited Articles
                      </li>
                      <li>
                        <div className="icon_blk">
                          <img src={checkicon} alt="" />
                        </div>
                        Text Editor
                      </li>
                      <li>
                        <div className="icon_blk">
                          <img src={checkicon} alt="" />
                        </div>
                        Auto SEO Optimization
                      </li>
                      <li>
                        <div className="icon_blk">
                          <img src={checkicon} alt="" />
                        </div>
                        20+ Languages
                      </li>
                      <li>
                        <div className="icon_blk">
                          <img src={checkicon} alt="" />
                        </div>
                        Grammar Checker
                      </li>
                      <li>
                        <div className="icon_blk">
                          <img src={checkicon} alt="" />
                        </div>
                        Bulk Generation
                      </li>
                      <li>
                        <div className="icon_blk">
                          <img src={checkicon} alt="" />
                        </div>
                        Zapier Automation
                      </li>
                      <li>
                        <div className="icon_blk">
                          <img src={checkicon} alt="" />
                        </div>
                        In-Articles Images
                      </li>

                      <li>
                        <div className="icon_blk">
                          <img src={checkicon} alt="" />
                        </div>
                        API Access
                      </li>
                      <li>
                        <div className="icon_blk">
                          <img src={checkicon} alt="" />
                        </div>
                        Add 5 Members
                      </li>
                      <li>
                        <div className="icon_blk">
                          <img src={checkicon} alt="" />
                        </div>
                        WordPress Integration
                      </li>
                      <li>
                        <div className="icon_blk">
                          <img src={checkicon} alt="" />
                        </div>
                        Built-in Templates
                      </li>
                      <li>
                        <div className="icon_blk">
                          <img src={checkicon} alt="" />
                        </div>
                        AI Engine: OpenAI CHATGPT-3.5 & CHATGPT-4 (& All Future Added AI Models)
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </section>
            :
            appSomoDeal ?
              <section id="pricing-plan">
                <h1><Link onClick={() => setAppSomoDeal(false)}>Go Back To Pricing</Link></h1>
                <h2>AppSomo Deals</h2>
                <div className="row">
                  <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                    <div className="pricing_card">
                      <h3>License Tier 1</h3>
                      <h3>
                        $49
                      </h3>
                      <p>Start unlocking the magic SEO superpowers of AI today</p>
                      <hr />
                      <ul>
                        <li>
                          <div className="icon_blk">
                            <img src={checkicon} alt="" />
                          </div>
                          30 Articles
                        </li>
                        <li>
                          <div className="icon_blk">
                            <img src={checkicon} alt="" />
                          </div>
                          Text Editor
                        </li>
                        <li>
                          <div className="icon_blk">
                            <img src={checkicon} alt="" />
                          </div>
                          Auto SEO Optimization
                        </li>
                        <li>
                          <div className="icon_blk">
                            <img src={checkicon} alt="" />
                          </div>
                          20+ Languages
                        </li>
                        <li>
                          <div className="icon_blk">
                            <img src={checkicon} alt="" />
                          </div>
                          Grammar Checker
                        </li>
                        <li>
                          <div className="icon_blk">
                            <img src={checkicon} alt="" />
                          </div>
                          Bulk Generation
                        </li>
                        <li>
                          <div className="icon_blk">
                            <img src={checkicon} alt="" />
                          </div>
                          Zapier Automation
                        </li>
                        <li>
                          <div className="icon_blk">
                            <img src={checkicon} alt="" />
                          </div>
                          In-Articles Images
                        </li>

                        <li>
                          <div className="icon_blk">
                            <img src={checkicon} alt="" />
                          </div>
                          API Access
                        </li>
                        <li>
                          <div className="icon_blk">
                            <img src={checkicon} alt="" />
                          </div>
                          Add 1 Member
                        </li>
                        <li>
                          <div className="icon_blk">
                            <img src={checkicon} alt="" />
                          </div>
                          WordPress Integration
                        </li>
                        <li>
                          <div className="icon_blk">
                            <img src={checkicon} alt="" />
                          </div>
                          Built-in Templates
                        </li>
                        <li>
                          <div className="icon_blk">
                            <img src={checkicon} alt="" />
                          </div>
                          AI Engine: OpenAI CHATGPT-3.5 & CHATGPT-4 (& All Future Added AI Models)
                        </li>
                      </ul>
                      <button
                        type="button"
                        className='try_btn'
                      >
                        View Now
                      </button>
                    </div>
                  </div>
                  <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                    <div className="pricing_card">
                      <h3>License Tier 2 </h3>
                      <h3>
                        $179
                      </h3>
                      <p>
                        All the AI-features you need for outranking your competitors.
                      </p>
                      <hr />
                      <ul>
                        <li>
                          <div className="icon_blk">
                            <img src={checkicon} alt="" />
                          </div>
                          Unlimited Articles
                        </li>
                        <li>
                          <div className="icon_blk">
                            <img src={checkicon} alt="" />
                          </div>
                          Text Editor
                        </li>
                        <li>
                          <div className="icon_blk">
                            <img src={checkicon} alt="" />
                          </div>
                          Auto SEO Optimization
                        </li>
                        <li>
                          <div className="icon_blk">
                            <img src={checkicon} alt="" />
                          </div>
                          20+ Languages
                        </li>
                        <li>
                          <div className="icon_blk">
                            <img src={checkicon} alt="" />
                          </div>
                          Grammar Checker
                        </li>
                        <li>
                          <div className="icon_blk">
                            <img src={checkicon} alt="" />
                          </div>
                          Bulk Generation
                        </li>
                        <li>
                          <div className="icon_blk">
                            <img src={checkicon} alt="" />
                          </div>
                          Zapier Automation
                        </li>
                        <li>
                          <div className="icon_blk">
                            <img src={checkicon} alt="" />
                          </div>
                          In-Articles Images
                        </li>

                        <li>
                          <div className="icon_blk">
                            <img src={checkicon} alt="" />
                          </div>
                          API Access
                        </li>
                        <li>
                          <div className="icon_blk">
                            <img src={checkicon} alt="" />
                          </div>
                          Add 5 Members
                        </li>
                        <li>
                          <div className="icon_blk">
                            <img src={checkicon} alt="" />
                          </div>
                          WordPress Integration
                        </li>
                        <li>
                          <div className="icon_blk">
                            <img src={checkicon} alt="" />
                          </div>
                          Built-in Templates
                        </li>
                        <li>
                          <div className="icon_blk">
                            <img src={checkicon} alt="" />
                          </div>
                          AI Engine: OpenAI CHATGPT-3.5 & CHATGPT-4 (& All Future Added AI Models)
                        </li>
                      </ul>
                      <button
                        type="button"
                        className='try_btn'
                      >
                        View Now
                      </button>
                    </div>
                  </div>
                </div>
              </section>
              :
              <section id="pricing-plan">
                
                <div className="radio_blk">
                  <span>Billed Monthly</span>
                  <label className="switch">
                    <input type="checkbox" onChange={(e) => setPricingToggle(!pricingToggle)} />
                    <span className="slider round"></span>
                  </label>
                  <span>Billed Yearly (save 17%)</span>
                </div>
                {
                  !pricingToggle ?
                    <div className="row">
                      <div className="col-xl-3 col-lg-4 col-md-4 col-sm-12">
                        <div className="pricing_card">
                          <h3>Basic</h3>
                          <h3>
                            $29<span>/month</span>
                          </h3>
                          <p>Start unlocking the magic SEO superpowers of AI today</p>
                          <hr />
                          <ul>
                            <li>
                              <div className="icon_blk">
                                <img src={checkicon} alt="" />
                              </div>
                              100 Articles
                            </li>
                            <li>
                              <div className="icon_blk">
                                <img src={checkicon} alt="" />
                              </div>
                              Text Editor
                            </li>
                            <li>
                              <div className="icon_blk">
                                <img src={checkicon} alt="" />
                              </div>
                              Auto SEO Optimization
                            </li>
                            <li>
                              <div className="icon_blk">
                                <img src={checkicon} alt="" />
                              </div>
                              10+ Output Styles
                            </li>
                            <li>
                              <div className="icon_blk">
                                <img src={checkicon} alt="" />
                              </div>
                              20+ Languages
                            </li>
                            <li>
                              <div className="icon_blk">
                                <img src={checkicon} alt="" />
                              </div>
                              Bulk Generation
                            </li>
                            <li>
                              <div className="icon_blk">
                                <img src={checkicon} alt="" />
                              </div>
                              Zapier Automation
                            </li>
                            <li>
                              <div className="icon_blk">
                                <img src={checkicon} alt="" />
                              </div>
                              In-Articles Images
                            </li>
                            <li>
                              <div className="icon_blk">
                                <img src={checkicon} alt="" />
                              </div>
                              Grammar Checker
                            </li>
                          </ul>
                          <button
                            type="button"
                            className={`${monthlyBasicDisable ? "disabled-pricing-btn" : "try_btn"
                              }`}
                            onClick={handleMonthPlan}
                            disabled={monthlyBasicDisable}
                          >
                            {
                              expiryDetail?.subscription?.subscriptionPlan ===
                                "Basic Monthly" ?
                                'Subscribed' :
                                'Subscribe Now'
                            }
                          </button>
                          {
                            expiryDetail?.subscription?.subscriptionPlan ===
                            "Basic Monthly" &&
                            !renew && (
                              <span className="info" onClick={() => setModalShow(true)} style={{ marginTop: '10px', color: 'black', display: 'flex', alignItems: 'center', justifyContent: 'center', cursor: 'pointer' }}>
                                Cancel Any Time
                              </span>
                            )}
                          {expiryDetail?.subscription?.subscriptionPlan ===
                            "Basic Monthly" &&
                            renew && (
                              <span className="info" onClick={() => setRenewModal(true)} style={{ marginTop: '10px', color: 'black', display: 'flex', alignItems: 'center', justifyContent: 'center', cursor: 'pointer' }}>Renew your subscription</span>
                            )}
                        </div>
                      </div>
                      <div className="col-xl-3 col-lg-4 col-md-4 col-sm-12">
                        <div className="pricing_card">
                          <h3>Pro </h3>
                          <h3>
                            $59<span>/month</span>
                          </h3>
                          <p>
                            All the AI-features you need for outranking your competitors.
                          </p>
                          <hr />
                          <ul>
                            <li>
                              <div className="icon_blk">
                                <img src={checkicon} alt="" />
                              </div>
                              300 Articles
                            </li>
                            <li>
                              <div className="icon_blk">
                                <img src={checkicon} alt="" />
                              </div>
                              Text Editor
                            </li>
                            <li>
                              <div className="icon_blk">
                                <img src={checkicon} alt="" />
                              </div>
                              Auto SEO Optimization
                            </li>
                            <li>
                              <div className="icon_blk">
                                <img src={checkicon} alt="" />
                              </div>
                              10+ Output Styles
                            </li>
                            <li>
                              <div className="icon_blk">
                                <img src={checkicon} alt="" />
                              </div>
                              20+ Languages
                            </li>
                            <li>
                              <div className="icon_blk">
                                <img src={checkicon} alt="" />
                              </div>
                              Bulk Generation
                            </li>
                            <li>
                              <div className="icon_blk">
                                <img src={checkicon} alt="" />
                              </div>
                              Zapier Automation
                            </li>
                            <li>
                              <div className="icon_blk">
                                <img src={checkicon} alt="" />
                              </div>
                              In-Articles Images
                            </li>
                            <li>
                              <div className="icon_blk">
                                <img src={checkicon} alt="" />
                              </div>
                              Grammar Checker
                            </li>
                            <li>
                              <div className="icon_blk">
                                <img src={checkicon} alt="" />
                              </div>
                              API Access
                            </li>
                            <li>
                              <div className="icon_blk">
                                <img src={checkicon} alt="" />
                              </div>
                              Add 1 Member
                            </li>
                            <li>
                              <div className="icon_blk">
                                <img src={checkicon} alt="" />
                              </div>
                              SERP Analyzer (soon)
                            </li>
                            <li>
                              <div className="icon_blk">
                                <img src={checkicon} alt="" />
                              </div>
                              SEO Analyzer (soon)
                            </li>
                          </ul>
                          <button
                            type="button"
                            className={`${monthlyProDisable ? "disabled-pricing-btn" : "try_btn"
                              }`}
                            onClick={handleMonthPlanPro}
                            disabled={monthlyProDisable}
                          >
                            {
                              expiryDetail?.subscription?.subscriptionPlan ===
                                "Pro Monthly" ?
                                'Subscribed' :
                                'Subscribe Now'
                            }
                          </button>
                          {
                            expiryDetail?.subscription?.subscriptionPlan ===
                            "Pro Monthly" &&
                            !renew && (
                              <span className="info" onClick={() => setModalShow(true)} style={{ marginTop: '10px', color: 'black', display: 'flex', alignItems: 'center', justifyContent: 'center', cursor: 'pointer' }}>
                                Cancel Any Time
                              </span>
                            )}
                          {expiryDetail?.subscription?.subscriptionPlan ===
                            "Pro Monthly" &&
                            renew && (
                              <span className="info" onClick={() => setRenewModal(true)} style={{ marginTop: '10px', color: 'black', display: 'flex', alignItems: 'center', justifyContent: 'center', cursor: 'pointer' }}>Renew your subscription</span>
                            )}
                        </div>
                      </div>
                      <div className="col-xl-3 col-lg-4 col-md-4 col-sm-12">
                        <div className="pricing_card">
                          <h3>Team</h3>
                          <h3>
                            $149<span>/month</span>
                          </h3>
                          <p>For those who need more amounts of content generation</p>
                          <hr />
                          <ul>
                            <li>
                              <div className="icon_blk">
                                <img src={checkicon} alt="" />
                              </div>
                              1000 Articles
                            </li>
                            <li>
                              <div className="icon_blk">
                                <img src={checkicon} alt="" />
                              </div>
                              Text Editor
                            </li>
                            <li>
                              <div className="icon_blk">
                                <img src={checkicon} alt="" />
                              </div>
                              Auto SEO Optimization
                            </li>
                            <li>
                              <div className="icon_blk">
                                <img src={checkicon} alt="" />
                              </div>
                              10+ Output Styles
                            </li>
                            <li>
                              <div className="icon_blk">
                                <img src={checkicon} alt="" />
                              </div>
                              20+ Languages
                            </li>
                            <li>
                              <div className="icon_blk">
                                <img src={checkicon} alt="" />
                              </div>
                              Bulk Generation
                            </li>
                            <li>
                              <div className="icon_blk">
                                <img src={checkicon} alt="" />
                              </div>
                              Zapier Automation
                            </li>
                            <li>
                              <div className="icon_blk">
                                <img src={checkicon} alt="" />
                              </div>
                              In-Articles Images
                            </li>
                            <li>
                              <div className="icon_blk">
                                <img src={checkicon} alt="" />
                              </div>
                              Grammar Checker
                            </li>
                            <li>
                              <div className="icon_blk">
                                <img src={checkicon} alt="" />
                              </div>
                              API Access
                            </li>
                            <li>
                              <div className="icon_blk">
                                <img src={checkicon} alt="" />
                              </div>
                              Add 5 Member
                            </li>
                            <li>
                              <div className="icon_blk">
                                <img src={checkicon} alt="" />
                              </div>
                              SERP Analyzer (soon)
                            </li>
                            <li>
                              <div className="icon_blk">
                                <img src={checkicon} alt="" />
                              </div>
                              SEO Analyzer (soon)
                            </li>
                          </ul>
                          <button
                            type="button"
                            className={`${monthlyTeamDisable ? "disabled-pricing-btn" : "try_btn"
                              }`}
                            onClick={handleMonthPlanTeam}
                            disabled={monthlyTeamDisable}
                          >
                            {
                              expiryDetail?.subscription?.subscriptionPlan ===
                                "Team Monthly" ?
                                'Subscribed' :
                                'Subscribe Now'
                            }
                          </button>
                          {
                            expiryDetail?.subscription?.subscriptionPlan ===
                            "Team Monthly" &&
                            !renew && (
                              <span className="info" onClick={() => setModalShow(true)} style={{ marginTop: '10px', color: 'black', display: 'flex', alignItems: 'center', justifyContent: 'center', cursor: 'pointer' }}>
                                Cancel Any Time
                              </span>
                            )}
                          {expiryDetail?.subscription?.subscriptionPlan ===
                            "Team Monthly" &&
                            renew && (
                              <span className="info" onClick={() => setRenewModal(true)} style={{ marginTop: '10px', color: 'black', display: 'flex', alignItems: 'center', justifyContent: 'center', cursor: 'pointer' }}>Renew your subscription</span>
                            )}
                        </div>
                      </div>
                      <div className="col-xl-3 col-lg-4 col-md-4 col-sm-12">
                        <div className="pricing_card">
                          <h3>Enterprise</h3>
                          <h3>
                            $499<span>/month</span>
                          </h3>
                          <p>
                            Need something with no limits? Go fast & furious along with your
                            team.
                          </p>
                          <hr />
                          <ul>
                            <li>
                              <div className="icon_blk">
                                <img src={checkicon} alt="" />
                              </div>
                              Unlimited Articles
                            </li>
                            <li>
                              <div className="icon_blk">
                                <img src={checkicon} alt="" />
                              </div>
                              Text Editor
                            </li>
                            <li>
                              <div className="icon_blk">
                                <img src={checkicon} alt="" />
                              </div>
                              Auto SEO Optimization
                            </li>
                            <li>
                              <div className="icon_blk">
                                <img src={checkicon} alt="" />
                              </div>
                              10+ Output Styles
                            </li>
                            <li>
                              <div className="icon_blk">
                                <img src={checkicon} alt="" />
                              </div>
                              20+ Languages
                            </li>
                            <li>
                              <div className="icon_blk">
                                <img src={checkicon} alt="" />
                              </div>
                              Bulk Generation
                            </li>
                            <li>
                              <div className="icon_blk">
                                <img src={checkicon} alt="" />
                              </div>
                              Zapier Automation
                            </li>
                            <li>
                              <div className="icon_blk">
                                <img src={checkicon} alt="" />
                              </div>
                              In-Articles Images
                            </li>
                            <li>
                              <div className="icon_blk">
                                <img src={checkicon} alt="" />
                              </div>
                              Grammar Checker
                            </li>
                            <li>
                              <div className="icon_blk">
                                <img src={checkicon} alt="" />
                              </div>
                              API Access
                            </li>
                            <li>
                              <div className="icon_blk">
                                <img src={checkicon} alt="" />
                              </div>
                              Add 10 Member
                            </li>
                            <li>
                              <div className="icon_blk">
                                <img src={checkicon} alt="" />
                              </div>
                              SERP Analyzer (soon)
                            </li>
                            <li>
                              <div className="icon_blk">
                                <img src={checkicon} alt="" />
                              </div>
                              SEO Analyzer (soon)
                            </li>
                          </ul>
                          <button
                            type="button"
                            className={`${enterpriseMonthDisable ? "disabled-pricing-btn" : "try_btn"
                              }`}
                            onClick={handleMonthPlanEnterprise}
                            disabled={enterpriseMonthDisable}
                          >
                            {
                              expiryDetail?.subscription?.subscriptionPlan ===
                                "Enterprise Monthly" ?
                                'Subscribed' :
                                'Subscribe Now'
                            }
                          </button>
                          {
                            expiryDetail?.subscription?.subscriptionPlan ===
                            "Enterprise Monthly" &&
                            !renew && (
                              <span className="info" onClick={() => setModalShow(true)} style={{ marginTop: '10px', color: 'black', display: 'flex', alignItems: 'center', justifyContent: 'center', cursor: 'pointer' }}>
                                Cancel Any Time
                              </span>
                            )}
                          {expiryDetail?.subscription?.subscriptionPlan ===
                            "Enterprise Monthly" &&
                            renew && (
                              <span className="info" onClick={() => setRenewModal(true)} style={{ marginTop: '10px', color: 'black', display: 'flex', alignItems: 'center', justifyContent: 'center', cursor: 'pointer' }}>Renew your subscription</span>
                            )}
                        </div>
                      </div>
                    </div>
                    :
                    <div className="row">
                      <div className="col-xl-3 col-lg-4 col-md-4 col-sm-12">
                        <div className="pricing_card">
                          <h3>Basic</h3>
                          <h3>
                            $299<span>/year</span>
                          </h3>
                          <p>Start unlocking the magic SEO superpowers of AI today</p>
                          <hr />
                          <ul>
                            <li>
                              <div className="icon_blk">
                                <img src={checkicon} alt="" />
                              </div>
                              100 Articles
                            </li>
                            <li>
                              <div className="icon_blk">
                                <img src={checkicon} alt="" />
                              </div>
                              Text Editor
                            </li>
                            <li>
                              <div className="icon_blk">
                                <img src={checkicon} alt="" />
                              </div>
                              Auto SEO Optimization
                            </li>
                            <li>
                              <div className="icon_blk">
                                <img src={checkicon} alt="" />
                              </div>
                              10+ Output Styles
                            </li>
                            <li>
                              <div className="icon_blk">
                                <img src={checkicon} alt="" />
                              </div>
                              20+ Languages
                            </li>
                            <li>
                              <div className="icon_blk">
                                <img src={checkicon} alt="" />
                              </div>
                              Bulk Generation
                            </li>
                            <li>
                              <div className="icon_blk">
                                <img src={checkicon} alt="" />
                              </div>
                              Zapier Automation
                            </li>
                            <li>
                              <div className="icon_blk">
                                <img src={checkicon} alt="" />
                              </div>
                              In-Articles Images
                            </li>
                            <li>
                              <div className="icon_blk">
                                <img src={checkicon} alt="" />
                              </div>
                              Grammar Checker
                            </li>
                          </ul>
                          <button
                            type="button"
                            className={`${yearlyBasicDisable ? "disabled-pricing-btn" : "try_btn"
                              }`}
                            onClick={handleYearPlan}
                            disabled={yearlyBasicDisable}
                          >
                            {
                              expiryDetail?.subscription?.subscriptionPlan ===
                                "Basic Yearly" ?
                                'Subscribed' :
                                'Subscribe Now'
                            }
                          </button>
                          {
                            expiryDetail?.subscription?.subscriptionPlan ===
                            "Basic Yearly" &&
                            !renew && (
                              <span className="info" onClick={() => setModalShow(true)} style={{ marginTop: '10px', color: 'black', display: 'flex', alignItems: 'center', justifyContent: 'center', cursor: 'pointer' }}>
                                Cancel Any Time
                              </span>
                            )}
                          {expiryDetail?.subscription?.subscriptionPlan ===
                            "Basic Yearly" &&
                            renew && (
                              <span className="info" onClick={() => setRenewModal(true)} style={{ marginTop: '10px', color: 'black', display: 'flex', alignItems: 'center', justifyContent: 'center', cursor: 'pointer' }}>Renew your subscription</span>
                            )}
                        </div>
                      </div>
                      <div className="col-xl-3 col-lg-4 col-md-4 col-sm-12">
                        <div className="pricing_card">
                          <h3>Pro </h3>
                          <h3>
                            $599<span>/year</span>
                          </h3>
                          <p>
                            All the AI-features you need for outranking your competitors.
                          </p>
                          <hr />
                          <ul>
                            <li>
                              <div className="icon_blk">
                                <img src={checkicon} alt="" />
                              </div>
                              300 Articles
                            </li>
                            <li>
                              <div className="icon_blk">
                                <img src={checkicon} alt="" />
                              </div>
                              Text Editor
                            </li>
                            <li>
                              <div className="icon_blk">
                                <img src={checkicon} alt="" />
                              </div>
                              Auto SEO Optimization
                            </li>
                            <li>
                              <div className="icon_blk">
                                <img src={checkicon} alt="" />
                              </div>
                              10+ Output Styles
                            </li>
                            <li>
                              <div className="icon_blk">
                                <img src={checkicon} alt="" />
                              </div>
                              20+ Languages
                            </li>
                            <li>
                              <div className="icon_blk">
                                <img src={checkicon} alt="" />
                              </div>
                              Bulk Generation
                            </li>
                            <li>
                              <div className="icon_blk">
                                <img src={checkicon} alt="" />
                              </div>
                              Zapier Automation
                            </li>
                            <li>
                              <div className="icon_blk">
                                <img src={checkicon} alt="" />
                              </div>
                              In-Articles Images
                            </li>
                            <li>
                              <div className="icon_blk">
                                <img src={checkicon} alt="" />
                              </div>
                              Grammar Checker
                            </li>
                            <li>
                              <div className="icon_blk">
                                <img src={checkicon} alt="" />
                              </div>
                              API Access
                            </li>
                            <li>
                              <div className="icon_blk">
                                <img src={checkicon} alt="" />
                              </div>
                              Add 1 Member
                            </li>
                            <li>
                              <div className="icon_blk">
                                <img src={checkicon} alt="" />
                              </div>
                              SERP Analyzer (soon)
                            </li>
                            <li>
                              <div className="icon_blk">
                                <img src={checkicon} alt="" />
                              </div>
                              SEO Analyzer (soon)
                            </li>
                          </ul>
                          <button
                            type="button"
                            className={`${yearlyProDisable ? "disabled-pricing-btn" : "try_btn"
                              }`}
                            onClick={handleYearPlanPro}
                            disabled={yearlyProDisable}
                          >
                            {
                              expiryDetail?.subscription?.subscriptionPlan ===
                                "Pro Yearly" ?
                                'Subscribed' :
                                'Subscribe Now'
                            }
                          </button>
                          {
                            expiryDetail?.subscription?.subscriptionPlan ===
                            "Pro Yearly" &&
                            !renew && (
                              <span className="info" onClick={() => setModalShow(true)} style={{ marginTop: '10px', color: 'black', display: 'flex', alignItems: 'center', justifyContent: 'center', cursor: 'pointer' }}>
                                Cancel Any Time
                              </span>
                            )}
                          {expiryDetail?.subscription?.subscriptionPlan ===
                            "Pro Yearly" &&
                            renew && (
                              <span className="info" onClick={() => setRenewModal(true)} style={{ marginTop: '10px', color: 'black', display: 'flex', alignItems: 'center', justifyContent: 'center', cursor: 'pointer' }}>Renew your subscription</span>
                            )}
                        </div>
                      </div>
                      <div className="col-xl-3 col-lg-4 col-md-4 col-sm-12">
                        <div className="pricing_card">
                          <h3>Team</h3>
                          <h3>
                            $1499<span>/year</span>
                          </h3>
                          <p>For those who need more amounts of content generation</p>
                          <hr />
                          <ul>
                            <li>
                              <div className="icon_blk">
                                <img src={checkicon} alt="" />
                              </div>
                              1000 Articles
                            </li>
                            <li>
                              <div className="icon_blk">
                                <img src={checkicon} alt="" />
                              </div>
                              Text Editor
                            </li>
                            <li>
                              <div className="icon_blk">
                                <img src={checkicon} alt="" />
                              </div>
                              Auto SEO Optimization
                            </li>
                            <li>
                              <div className="icon_blk">
                                <img src={checkicon} alt="" />
                              </div>
                              10+ Output Styles
                            </li>
                            <li>
                              <div className="icon_blk">
                                <img src={checkicon} alt="" />
                              </div>
                              20+ Languages
                            </li>
                            <li>
                              <div className="icon_blk">
                                <img src={checkicon} alt="" />
                              </div>
                              Bulk Generation
                            </li>
                            <li>
                              <div className="icon_blk">
                                <img src={checkicon} alt="" />
                              </div>
                              Zapier Automation
                            </li>
                            <li>
                              <div className="icon_blk">
                                <img src={checkicon} alt="" />
                              </div>
                              In-Articles Images
                            </li>
                            <li>
                              <div className="icon_blk">
                                <img src={checkicon} alt="" />
                              </div>
                              Grammar Checker
                            </li>
                            <li>
                              <div className="icon_blk">
                                <img src={checkicon} alt="" />
                              </div>
                              API Access
                            </li>
                            <li>
                              <div className="icon_blk">
                                <img src={checkicon} alt="" />
                              </div>
                              Add 5 Member
                            </li>
                            <li>
                              <div className="icon_blk">
                                <img src={checkicon} alt="" />
                              </div>
                              SERP Analyzer (soon)
                            </li>
                            <li>
                              <div className="icon_blk">
                                <img src={checkicon} alt="" />
                              </div>
                              SEO Analyzer (soon)
                            </li>
                          </ul>
                          <button
                            type="button"
                            className={`${yearlyTeamDisable ? "disabled-pricing-btn" : "try_btn"
                              }`}
                            onClick={handleYearPlanTeam}
                            disabled={yearlyTeamDisable}
                          >
                            {
                              expiryDetail?.subscription?.subscriptionPlan ===
                                "Team Yearly" ?
                                'Subscribed' :
                                'Subscribe Now'
                            }
                          </button>
                          {
                            expiryDetail?.subscription?.subscriptionPlan ===
                            "Team Yearly" &&
                            !renew && (
                              <span className="info" onClick={() => setModalShow(true)} style={{ marginTop: '10px', color: 'black', display: 'flex', alignItems: 'center', justifyContent: 'center', cursor: 'pointer' }}>
                                Cancel Any Time
                              </span>
                            )}
                          {expiryDetail?.subscription?.subscriptionPlan ===
                            "Team Yearly" &&
                            renew && (
                              <span className="info" onClick={() => setRenewModal(true)} style={{ marginTop: '10px', color: 'black', display: 'flex', alignItems: 'center', justifyContent: 'center', cursor: 'pointer' }}>Renew your subscription</span>
                            )}
                        </div>
                      </div>
                      <div className="col-xl-3 col-lg-4 col-md-4 col-sm-12">
                        <div className="pricing_card">
                          <h3>Enterprise</h3>
                          <h3>
                            $4999<span>/year</span>
                          </h3>
                          <p>
                            Need something with no limits? Go fast & furious along with your
                            team.
                          </p>
                          <hr />
                          <ul>
                            <li>
                              <div className="icon_blk">
                                <img src={checkicon} alt="" />
                              </div>
                              Unlimited Articles
                            </li>
                            <li>
                              <div className="icon_blk">
                                <img src={checkicon} alt="" />
                              </div>
                              Text Editor
                            </li>
                            <li>
                              <div className="icon_blk">
                                <img src={checkicon} alt="" />
                              </div>
                              Auto SEO Optimization
                            </li>
                            <li>
                              <div className="icon_blk">
                                <img src={checkicon} alt="" />
                              </div>
                              10+ Output Styles
                            </li>
                            <li>
                              <div className="icon_blk">
                                <img src={checkicon} alt="" />
                              </div>
                              20+ Languages
                            </li>
                            <li>
                              <div className="icon_blk">
                                <img src={checkicon} alt="" />
                              </div>
                              Bulk Generation
                            </li>
                            <li>
                              <div className="icon_blk">
                                <img src={checkicon} alt="" />
                              </div>
                              Zapier Automation
                            </li>
                            <li>
                              <div className="icon_blk">
                                <img src={checkicon} alt="" />
                              </div>
                              In-Articles Images
                            </li>
                            <li>
                              <div className="icon_blk">
                                <img src={checkicon} alt="" />
                              </div>
                              Grammar Checker
                            </li>
                            <li>
                              <div className="icon_blk">
                                <img src={checkicon} alt="" />
                              </div>
                              API Access
                            </li>
                            <li>
                              <div className="icon_blk">
                                <img src={checkicon} alt="" />
                              </div>
                              Add 10 Member
                            </li>
                            <li>
                              <div className="icon_blk">
                                <img src={checkicon} alt="" />
                              </div>
                              SERP Analyzer (soon)
                            </li>
                            <li>
                              <div className="icon_blk">
                                <img src={checkicon} alt="" />
                              </div>
                              SEO Analyzer (soon)
                            </li>
                          </ul>
                          <button
                            type="button"
                            className={`${enterpriseYearDisable ? "disabled-pricing-btn" : "try_btn"
                              }`}
                            onClick={handleYearPlanEnterprise}
                            disabled={enterpriseYearDisable}
                          >
                            {
                              expiryDetail?.subscription?.subscriptionPlan ===
                                "Enterprise Yearly" ?
                                'Subscribed' :
                                'Subscribe Now'
                            }
                          </button>
                          {
                            expiryDetail?.subscription?.subscriptionPlan ===
                            "Enterprise Yearly" &&
                            !renew && (
                              <span className="info" onClick={() => setModalShow(true)} style={{ marginTop: '10px', color: 'black', display: 'flex', alignItems: 'center', justifyContent: 'center', cursor: 'pointer' }}>
                                Cancel Any Time
                              </span>
                            )}
                          {expiryDetail?.subscription?.subscriptionPlan ===
                            "Enterprise Yearly" &&
                            renew && (
                              <span className="info" onClick={() => setRenewModal(true)} style={{ marginTop: '10px', color: 'black', display: 'flex', alignItems: 'center', justifyContent: 'center', cursor: 'pointer' }}>Renew your subscription</span>
                            )}
                        </div>
                      </div>
                    </div>
                }
                <div className="free_trial_blk">
                  <div className="text">
                    <h4>Free Trial</h4>
                    <span>5 Articles</span>
                  </div>
                  <button type="button" className="subscribe_btn">
                    {
                      expiryDetail?.subscription?.subscriptionPlan === 'Free Trial' ?
                        'Subscribed'
                        :
                        'Claimed'
                    }
                  </button>
                </div>
                <div className="redeem_blk">
                  <div className="text">
                    <h4>Manage Your AppSumo Deal</h4>
                    <span>click on the manage button to access the packages.</span>
                  </div>
                  <button type="button" className="subscribe_btn" onClick={() => setAppSomoDeal(true)}>
                    Manage
                  </button>
                </div>
              </section>
      }
      <Modal
        show={modalShow}
        size="lg"
        ariaLabelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">
            Modal heading
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h4 style={{
            marginTop: '10px',
            color: "black",
            fontWeight: "600",
            textAlign: "center",
          }}
            className="mb-4">
            Are you sure you want to cancel?
          </h4>
        </Modal.Body>
        <Modal.Footer style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}>
          <Button className="modal-yes-button" onClick={handleCancelPackage}>Yes</Button>
          <Button className="modal-no-button" onClick={() => setModalShow(false)}>Close</Button>
        </Modal.Footer>
      </Modal>
      <Modal
        show={renewModal}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">
            Modal heading
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h4 style={{
            marginTop: '10px',
            color: "black",
            fontWeight: "600",
            textAlign: "center",
          }}
            className="mb-4">
            Are you sure you want to re-new subscription?
          </h4>
        </Modal.Body>
        <Modal.Footer style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}>
          <Button className="modal-yes-button" onClick={handleRenewPackage}>Yes</Button>
          <Button className="modal-no-button" onClick={() => setRenewModal(false)}>Close</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default PricingPlans;
