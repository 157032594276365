import React, { useState } from "react";
import './teamMember.scss';
import routes from "../../../config/routes";
import { useDispatch, useSelector } from "react-redux";
import { Redirect } from "react-router-dom";
import { toast } from "react-toastify";
import { DotLoader } from "react-spinners";
import { addNewTeamMember } from "../../../redux/actions/panel";

const TeamMember = () => {

    const dispatch = useDispatch()
    const [memberEmail, setMemberEmail] = useState('');
    const [viewTeamMembers, setViewTeamMembers] = useState(false);
    const [memberUsername, setMemberUsername] = useState('');

    const user = useSelector((state) => state?.Authentication?.user)
    const {
        role
    } = JSON.parse(user);


    const token = useSelector((state) => state?.Authentication?.token);
    const loader = useSelector((state) => state?.PanelData?.panelLoader);
    const expiryDetail = useSelector((state) => state?.Pricing?.expiryDetail);

    const handleAddMember = async (e) => {
        e.preventDefault();

      
        if (!memberEmail) {
            toast.error('Enter team member email.')
        } else if (!/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(memberEmail)
        ) {
            toast.error('Email format not correct.')
        } else if (!memberUsername) {
            toast.error('Write team member username.')
        } else if (/^(?=.*[0-9])/i.test(memberUsername) || /^(?=.*[!@#$%^&*])/i.test(memberUsername)) {
            toast.error('Username can not have numeric and special characters.')
        } else {
            dispatch(addNewTeamMember(memberEmail, memberUsername))
            setMemberEmail('');
            setMemberUsername('');
        }
    }

    const teamMemberShow = expiryDetail?.subscription?.subscriptionPlan?.includes('Pro') || expiryDetail?.subscription?.subscriptionPlan?.includes('Enterprise') || expiryDetail?.subscription?.subscriptionPlan?.includes('Team') || expiryDetail?.subscription?.subscriptionPlan === "trollyai_tier1" || expiryDetail?.subscription?.subscriptionPlan === "trollyai_tier2"
   
    if (user === undefined || token === undefined) {
        return <Redirect to={routes.auth.logIn} />
    }

    if (JSON.parse(user)?.role === 'Team Member') {
        return <Redirect to={routes.panel.dashboard} />
    }

    if (!teamMemberShow) {
        return <Redirect to={routes.panel.dashboard} />
    }

    if (viewTeamMembers) {
        return <Redirect push to={routes.panel.viewTeamMembers} />
    }

    if (role === 'Team Member') {
        return <Redirect to={routes.panel.dashboard} />
    }

    return (
        <>
            <section id="setting">
                <div>
                    <h4 style={{ color: 'black', fontSize: '1.4rem', display: 'flex', width: '100%', justifyContent: 'end', paddingRight: '30px', cursor: 'pointer' }} onClick={() => setViewTeamMembers(true)}>View Team Members &nbsp;<i className="fa fa-solid fa-arrow-right" /></h4>
                </div>
                <div className="site_container">
                    <h2 className="title">Add a team Member</h2>
                    <form className="setting_form">
                        <label className="mb-4 mt-4">Enter team member email</label>
                        <div className="input_blk">
                            <input
                                type="text"
                                value={memberEmail}
                                onChange={(e) => setMemberEmail(e.target.value)}
                                placeholder="Enter team member email"
                            />
                        </div>
                        <label className="mb-4 mt-4">Enter team member username</label>
                        <div className="input_blk">
                            <input
                                type="text"
                                value={memberUsername}
                                onChange={(e) => setMemberUsername(e.target.value)}
                                placeholder="Enter team member username"
                            />
                        </div>
                        <button type="button" className="save_btn" onClick={handleAddMember}>
                            {
                                loader ?
                                    <>
                                        <DotLoader color="white" size={22} />
                                    </>
                                    :
                                    'Add Team Member'
                            }
                        </button>
                    </form>
                </div>
            </section>
        </>
    )
}
export default TeamMember;
