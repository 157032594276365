import React from "react";

import { ContentState, EditorState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import { convertFromHTML } from "draft-js";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { stateToHTML } from "draft-js-export-html";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { saveEditedArticle } from "../../../../redux/actions/panel";
import { Redirect } from "react-router-dom/cjs/react-router-dom.min";
import routes from "../../../../config/routes";
import "../../ArticleEditor/articleEditor.scss";
import { toast } from "react-toastify";
import axios from "axios";
import { DotLoader } from "react-spinners";
import Button from "react-bootstrap/Button";
import { FaRegCopy } from "react-icons/fa6";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import { MdCropPortrait } from "react-icons/md";
import { MdCropLandscape } from "react-icons/md";
import ReactSpeedometer from "react-d3-speedometer/slim";

const ViewArticleFromHistory = () => {
	const dispatch = useDispatch();

	const [openAddImages, setOpenAddImages] = useState(false);
	const [imagesData, setImagesData] = useState(null);
	const [imageSearch, setImageSearch] = useState("technology");
	const [loaderForImages, setLoaderForImages] = useState(false);

	const [orientation, setOrientation] = useState("landscape");

	const token = useSelector((state) => state?.Authentication?.token);
	const user =
		useSelector((state) => state?.Authentication?.user) 
	const [value, setValue] = useState(() => EditorState.createEmpty());

	const articleLoaded =
		useSelector((state) => state?.PanelData?.articleById) ||
		localStorage.getItem("Article-By-Id");

	let articleById;

	if (articleLoaded) {
		articleById = articleLoaded?._id
			? articleLoaded
			: JSON.parse(articleLoaded);
	}

	const articleByIdLoader = useSelector(
		(state) => state?.PanelData?.articleByIdLoader
	);

	const seo = localStorage.getItem("Article-SEO");

	let articleSeoData = null;
	try {
		articleSeoData = seo ? JSON.parse(seo) : null;
	} catch (e) {
		console.error("Parsing error:", e);
	}

	console.log(articleSeoData);

	const contentState = value?.getCurrentContent();
	const contentHTML = stateToHTML(contentState);

	const accessKeyUnsplash = "rQdDZ-pCCOSDeBNKyurLxCryT2kgfPkvtTzWiORqNB4";

	const [articleTitle, setArticleTitle] = useState("");
	const [articleMetaDescription, setArticleMetaDescription] = useState("");
	const [saveData, setSaveData] = useState(false);

	useEffect(() => {
		if (openAddImages) {
			setLoaderForImages(true);
			axios
				.get(
					`https://api.unsplash.com/search/photos?page=1&per_page=30&query=${imageSearch}&client_id=${accessKeyUnsplash}&orientation=${orientation}`
				)
				.then((response) => {
					setImagesData(response?.data?.results);
					setLoaderForImages(false);
				})
				.catch((err) => {
					setLoaderForImages(false);
					console.log({ err });
				});
		}
	}, [openAddImages, orientation]);

	const handleCopyImageUrl = (url) => {
		navigator.clipboard
			.writeText(url)
			.then(() => {
				toast.success("Image Url Copied!");
			})
			.catch((error) => {
				toast.error(`Didn't copy. Try Again.`);
			});
	};

	const handleSearchImages = (e) => {
		e.preventDefault();

		if (!imageSearch) {
			toast.error("Please type something to search.");
		} else {
			setLoaderForImages(true);
			axios
				.get(
					`https://api.unsplash.com/search/photos?page=1&per_page=30&query=${imageSearch}&client_id=${accessKeyUnsplash}&orientation=${orientation}`
				)
				.then((response) => {
					setImagesData(response?.data?.results);
					setLoaderForImages(false);
				})
				.catch((err) => {
					setLoaderForImages(false);
					console.log({ err });
				});
		}
	};

	useEffect(() => {
		if (articleById && saveData) {
			const payload = {
				data: contentHTML,
				dataTitle: articleTitle,
				metaDescription: articleMetaDescription,
			};
			dispatch(saveEditedArticle(articleById?._id, payload));
		}
	}, [contentHTML, articleMetaDescription, articleTitle, saveData]);

	useEffect(() => {
		setValue(() =>
			EditorState.createWithContent(
				ContentState.createFromBlockArray(
					convertFromHTML(`${articleById?.data}`)
				)
			)
		);
		setArticleTitle(articleById?.dataTitle);
		setArticleMetaDescription(articleById?.metaDescription);
		setSaveData(true);

		// }
	}, [articleLoaded, articleById?._id]);

	if (user === undefined || token === undefined) {
		return <Redirect to={routes.auth.logIn} />;
	}

	const downloadFile = (content, fileType) => {
		let blob;
		let fileName = "download";

		switch (fileType) {
			case "html":
				blob = new Blob([content], { type: "text/html" });
				fileName += ".html";
				break;
			case "txt":
				blob = new Blob([content], { type: "text/plain" });
				fileName += ".txt";
				break;
			default:
				break;
		}

		// For HTML (and potentially other types directly supported by Blob), proceed to create a download link.
		if (blob) {
			const url = URL.createObjectURL(blob);
			const link = document.createElement("a");
			link.href = url;
			link.download = fileName;
			document.body.appendChild(link);
			link.click();
			document.body.removeChild(link);
			URL.revokeObjectURL(url);
		}
	};

	return (
		<div className="row">
			<div className="add-image-button-wrapper">
				<button
					type="button"
					className="add_img"
					onClick={() => setOpenAddImages(true)}
				>
					Add Images
				</button>
			</div>
			{articleByIdLoader ? (
				<div className="col-md-12">
					<p style={{ color: "black" }}>Loader</p>
				</div>
			) : articleById ? (
				<div
					className="relative"
					id="editor"
					style={{
						display: "grid",
						gridTemplateColumns: "3fr 1fr",
						width: "100%",
						gap: "30px",
					}}
				>
					<div
						style={{
							display: "flex",
							flexDirection: "column",
							gap: "10px",
							width: "100%",
						}}
					>
						<div
							className="d-flex align-items-start pt-4"
							style={{
								position: "sticky",
								top: 0,
								background: "white",
							}}
						>
							<textarea
								className="article-textarea-input align-items-center justify-content-between"
								style={{ position: "sticky", top: 0 }}
								value={articleTitle}
								onChange={(e) => setArticleTitle(e.target.value)}
							/>
							<div
								style={{
									display: "flex",
									alignItems: "center",
									gap: "6px",
									marginTop: "8px",
								}}
							>
								<Button
									variant="secondary"
									size="lg"
									style={{
										borderRadius: "20px",
										display: "flex",
										alignItems: "center",
										gap: "5px",
									}}
									onClick={() => {
										// copy the whole content of the editor as markdown
										navigator.clipboard.writeText(
											`# ${articleTitle}\n\n${articleMetaDescription}\n\n${contentHTML}`
										);
										toast.success("Article Copied!");
									}}
								>
									<FaRegCopy />
									Copy Article
								</Button>
								<DropdownButton
									id="dropdown-item-button"
									title="Download as"
									style={{
										borderRadius: "60px",
									}}
									size="lg"
									as={Button}
								>
									<Dropdown.Item
										as="button"
										style={{
											width: "100%",
											fontSize: "15px",
										}}
										onClick={() => {
											downloadFile(contentHTML, "html");
										}}
									>
										HTML File
									</Dropdown.Item>
									<Dropdown.Item
										as="button"
										style={{
											width: "100%",
											fontSize: "15px",
										}}
										onClick={() => {
											downloadFile(contentHTML, "txt");
										}}
									>
										Text File
									</Dropdown.Item>
								</DropdownButton>
							</div>
						</div>
						<span
							style={{ color: "black", fontSize: "2.3rem", fontWeight: "bold" }}
						>
							Meta Description
						</span>
						<textarea
							id="article"
							className="meta-description"
							value={articleMetaDescription}
							onChange={(e) => setArticleMetaDescription(e.target.value)}
						/>
						<Editor
							editorState={value}
							onEditorStateChange={(content) => setValue(content)}
							wrapperClassName="demo-wrapper"
							editorClassName="text-editor-package"
						/>
					</div>
					<div
						style={{
							width: "100%",
							minHeight: "200px",
							background: "#ececec",
							borderRadius: "10px",
							marginBottom: "10px",
							display: "flex",
							justifyContent: "flex-start",
							alignItems: "flex-start",
							flexDirection: "column",
							gap: "20px",
							padding: "30px 20px",
						}}
					>
						<h3
							style={{
								color: "black",
								fontSize: "1.6rem",
								fontWeight: "700",
							}}
						>
							Content Score:
						</h3>
						<ReactSpeedometer
							value={articleSeoData?.score ?? 0}
							maxValue="100"
							forceRender
							height={200}
						/>
						<div
							style={{
								display: "flex",
								flexDirection: "column",
								gap: "5px",
								alignItems: "flex-start",
							}}
						>
							<h3
								style={{
									color: "black",
									fontSize: "1.6rem",
									fontWeight: "700",
								}}
							>
								Semantic Keywords:
							</h3>
							<div
								style={{
									display: "flex",
									flexWrap: "wrap",
									gap: "10px",
									justifyContent: "flex-start",
									alignItems: "flex-start",
									width: "100%",
									textAlign: "center",
									padding: "10px 0",
								}}
							>
								{articleSeoData?.semanticallyRelatedWords &&
									articleSeoData?.semanticallyRelatedWords?.map(
										(item, index) => {
											return (
												<span
													key={index}
													style={{
														background: "#7F56D9",
														padding: "5px 10px",
														borderRadius: "5px",
														color: "white",
														textTransform: "capitalize",
													}}
												>
													{item}
												</span>
											);
										}
									)}
							</div>
						</div>
					</div>
				</div>
			) : (
				<div className="col-md-12">
					<p style={{ color: "black" }}>Sorry couldn't load the document</p>
				</div>
			)}
			{openAddImages && (
				<div className="popup">
					<div className="popup-card">
						<div className="popup-button-wrapper">
							<button
								className="popup-acceptButton"
								onClick={() => setOpenAddImages(false)}
							>
								Close
							</button>
						</div>
						<div className="popup-input-wrapper">
							<input
								placeholder="Search Images By Name"
								name="imageSearch"
								value={imageSearch}
								onChange={(e) => setImageSearch(e.target.value)}
							/>
							<div className="button-popup">
								<button
									className="popup-acceptButton-search"
									onClick={handleSearchImages}
								>
									Search
								</button>
							</div>

							<div
								style={{
									display: "flex",
									alignItems: "center",
									gap: "6px",
									color: "#000",
									marginLeft: "20px",
								}}
							>
								<div
									style={{
										display: "flex",
										alignItems: "center",
										gap: "2px",
										padding: "8px 20px",
										borderRadius: "5px",
										cursor: "pointer",
										background:
											orientation === "portrait" ? "#dcccff" : "white",
									}}
									onClick={() => setOrientation("portrait")}
								>
									<MdCropPortrait
										style={{
											fontSize: "2.5rem",
										}}
									/>
									<p
										style={{
											fontWeight: "normal",
										}}
									>
										Portrait
									</p>
								</div>
								<div
									style={{
										display: "flex",
										alignItems: "center",
										gap: "2px",
										padding: "8px 20px",
										borderRadius: "5px",
										cursor: "pointer",
										background:
											orientation === "landscape" ? "#dcccff" : "white",
									}}
									onClick={() => setOrientation("landscape")}
								>
									<MdCropLandscape
										style={{
											fontSize: "2.5rem",
										}}
									/>
									<p
										style={{
											fontWeight: "normal",
										}}
									>
										Landscape
									</p>
								</div>
								<div
									style={{
										display: "flex",
										alignItems: "center",
										gap: "2px",
										padding: "8px 20px",
										borderRadius: "5px",
										cursor: "pointer",
										background:
											orientation === "squarish" ? "#dcccff" : "white",
									}}
									onClick={() => setOrientation("squarish")}
								>
									<MdCropLandscape
										style={{
											fontSize: "2.5rem",
										}}
									/>
									<p
										style={{
											fontWeight: "normal",
										}}
									>
										Square
									</p>
								</div>
							</div>
						</div>
						{loaderForImages ? (
							<div className="loaderWrapper-popup">
								<DotLoader color="#7F56D9" className="login-clip-loader" />
								<h1
									style={{
										color: "#7F56D9",
										fontSize: "2.4rem",
									}}
								>
									&nbsp;&nbsp;&nbsp;&nbsp;Loading your images...
								</h1>
							</div>
						) : (
							<div className="popup-images-container">
								<div className="row" style={{ justifyContent: "center" }}>
									{imagesData?.length === 0 ? (
										<div className="heading-wrapper-popup">
											<h1 style={{ color: "black", height: "100%" }}>
												Sorry No Data Available for the search.
											</h1>
										</div>
									) : (
										imagesData?.map((item) => {
											return (
												<div className="col-md-4" key={item?.id}>
													<div className="popup-image-wrapper">
														<img
															className="popup-image"
															src={item?.urls?.small_s3}
															alt={item?.alt_description}
															height={item?.height}
															width={item?.width}
														/>
													</div>
													<div className="popup-image-button">
														<button
															className="popup-acceptButton-search"
															onClick={() =>
																handleCopyImageUrl(item?.urls?.small_s3)
															}
														>
															Copy URL
														</button>
													</div>
												</div>
											);
										})
									)}
								</div>
							</div>
						)}
						<p className="popup-cookieDescription">
							Copy the url and paste it in the editor where you want to use it.{" "}
							<br />
							<span className="popup-span">
								Recommended image dimensions{" "}
								<b>
									{orientation === "landscape"
										? "1920x1080"
										: orientation === "portrait"
										? "1080x1920"
										: "1080x1080"}
								</b>
								.
							</span>
						</p>
					</div>
				</div>
			)}
		</div>
	);
};

export default ViewArticleFromHistory;
