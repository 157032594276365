import React from "react";
import { Redirect, Route } from "react-router-dom";
import { connect, useSelector } from "react-redux";

const mapStateToProps = (state) => {
    return {
        user: state.Authentication.user,
        token: state.Authentication.token,
    };
};


export const AuthRoute = ({ children, ...props }) => {
    
    return (
        <Route
            {...props}
            render={
                ({ location }) =>
                    props?.token && props?.user && (JSON?.parse(props?.user)?.isEmailVerified === true || JSON?.parse(props?.user)?.isSSO === true) ? (
                        children
                    ) : (
                        <Redirect
                            to={{
                                pathname: "/",
                                state: { from: location },
                            }}
                        />
                    )
            }
        />
    );
};

export default connect(mapStateToProps)(AuthRoute);
