import { createBrowserHistory } from 'history';
import { applyMiddleware, createStore } from 'redux';
import { routerMiddleware } from 'connected-react-router';
import createSagaMiddleware from 'redux-saga';
import thunk from 'redux-thunk';
import createRootReducer from './reducer';

import localforage from 'localforage'
import { persistReducer } from "redux-persist"

export const history = createBrowserHistory();

// localforage.config({
//     driver: localforage.INDEXEDDB,
//     name: 'MIDL_APP',
//     version: 1.0,
//     size: 4980736,
//     storeName: "MIDL_APP_STORE",
// })

// const persistanceConfigurations = {
//     key: "MIDL_BORDELESS_APP",
//     storage: localforage,
// }

// const persistedReducers = persistReducer(persistanceConfigurations, createRootReducer(history))

export const doCreateStore = () => {
    const sagaMiddleware = createSagaMiddleware();
    const middleware = [
        thunk,
        sagaMiddleware,
        routerMiddleware(history)
    ];



    const store = createStore(
        createRootReducer(history),
        // persistedReducers,
        applyMiddleware(...middleware)
    );

    return store;
};
