import React from "react";
import { Link } from "react-router-dom";
import logo from "../../assets/images/logo.png";
import facebook from "../../assets/images/facebook.svg";
import twitter from "../../assets/images/twitter.svg";
import instagram from "../../assets/images/instagram.svg";

import "./footer.scss";
const Footer = () => {
  return (
    <>
      <footer id="footer">
        <div className="site_container">
          <div className="footer_top">
            <div className="logo_sec">
              <div className="logo_blk">
                <img src={logo} alt="" />
              </div>
              <p className="slogan">
                What matters is productivity with fun culture
              </p>
            </div>
            <ul className="social_blk">
              {/* <li>
                <a href="#" className="icon_blk">
                  <img src={facebook} alt="facebook" />
                </a>
              </li> */}
              <li>
                <a
                  href="http://www.twitter.com/octalabsxyz"
                  className="icon_blk"
                  target="_blank"
                >
                  <img src={twitter} alt="facebook" />
                </a>
              </li>{" "}
              {/* <li>
                <a href="#" className="icon_blk">
                  <img src={instagram} alt="facebook" />
                </a>
              </li> */}
            </ul>
          </div>
          <div className="footer_bottom">
            <ul className="links">
              <li>
                <a href="">About</a>
              </li>
              <li>
                <a href="">Company</a>
              </li>{" "}
              <li>
                <a href="">Press</a>
              </li>
            </ul>
            <p className="rights">All rights reserved.</p>
          </div>
        </div>
      </footer>
      {/* <footer id="footer">
        <div className="site_container">
          <div className="row">
            <div className="col-lg-3 col-md-3 col-sm-12 col-12 mb-sm-5">
              <div className="logo_blk">
                <img src={logo} alt="logo" />
              </div>
            </div>
            <div className="col-lg-3 col-md-3 col-sm-12 col-12 footer_cards mb-sm-5">
              <ul>
                <li>
                  <Link to="">Resources</Link>
                </li>
                <li>
                  <Link to="">Return Policy</Link>
                </li>
                <li>
                  <Link to="">FAQ's</Link>
                </li>
                <li>
                  <Link to="">Privacy Policy</Link>
                </li>
                <li>
                  <Link to="">Customer Support</Link>
                </li>
              </ul>
            </div>
            <div className="col-lg-3 col-md-3 col-sm-12 col-12 mb-sm-5">
              <ul>
                <li>
                  <Link to="">About us</Link>
                </li>
                <li>
                  <Link to="">Our Story</Link>
                </li>
                <li>
                  <Link to="">Press</Link>
                </li>
                <li>
                  <Link to="">Careers</Link>
                </li>
              </ul>
            </div>
            <div className="col-lg-3 col-md-3 col-sm-12 col-12 mt-sm-5">
              <ul className="social_media_icons">
                <li>
                  <Link to="">
                    <i class="fa-brands fa-facebook"></i>
                  </Link>
                </li>
                <li>
                  <Link to="">
                    <i class="fa-brands fa-square-twitter"></i>
                  </Link>
                </li>
                <li>
                  <Link to="">
                    <i class="fa-brands fa-square-instagram"></i>
                  </Link>
                </li>
                <li>
                  <Link to="">
                    <i class="fa-brands fa-square-youtube"></i>
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </footer> */}
    </>
  );
};
export default Footer;
