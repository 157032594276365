import React, { useEffect } from "react";
import Login from "./components/Authentication/Login/Login";
import Signup from "./components/Authentication/Signup/Signup";
import LandingPage from "./components/LandingPage/LandingPage";
import Pricing from "./components/Panel/Pricing/PricingPlans";
import PanelHome from "./components/Panel/PanelHome/PanelHome";
import PanelLayout from "./layout/PanelLayout";
import WizardPage from "./components/Panel/WizardPage/WizardPage";
import { Router, Switch, Route } from "react-router";
import { history } from "./redux/store/createStore";
import routes from "./config/routes";
import ForgotPassword from "./components/Authentication/ForgotPassword/ForgotPassword";
import ResetPassword from "./components/Authentication/ResetPassword/ResetPassword";
import AuthRoute from "./privateRoutes/AuthRoute";
import FancyTitles from "./components/Panel/FancyTitles/FancyTitles";
import TitleOutline from "./components/Panel/TitleOutline/TitleOutline";
import ApiDocumentation from "./components/Panel/APIDocumentation/ApiDocumentation";
import Settings from "./components/Panel/Settings/Settings";
import EmailVerificationPage from "./components/Authentication/EmailVerificationPage/EmailVerificationPage";
import EmailVerified from "./components/Authentication/EmailVerificationPage/EmailVerified";
import ArticleEditor from "./components/Panel/ArticleEditor/ArticleEditor";
import ViewArticleFromHistory from "./components/Panel/MyDocuments/ViewDocument/ViewDocument";
import MyDocuments from "./components/Panel/MyDocuments/MyDocuments";
import BulkGenerationHome from "./components/Panel/BulkPages/BulkGeneration";
import CreateNewCampaign from "./components/Panel/BulkPages/CreateNewCampaign/NewCampaign";
import SelectedCampaign from "./components/Panel/BulkPages/SelectedCampaign/SelectedCampaign";
import ViewCampaignArticle from "./components/Panel/BulkPages/ViewCampaignArticle/ViewCampaignArticle";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import BasicMonthPlan from "./components/Panel/Pricing/PricingPlans/Month/BasicMonthPlan";
import ProMonthPlan from "./components/Panel/Pricing/PricingPlans/Month/ProMonthPlan";
import TeamMonthPlan from "./components/Panel/Pricing/PricingPlans/Month/TeamMonthPlan";
import EnterpriseMonthPlan from "./components/Panel/Pricing/PricingPlans/Month/EnterpriseMonthPlan";
import BasicYearPlan from "./components/Panel/Pricing/PricingPlans/Year/BasicYearPlan";
import ProYearPlan from "./components/Panel/Pricing/PricingPlans/Year/ProYearPlan";
import TeamYearPlan from "./components/Panel/Pricing/PricingPlans/Year/TeamYearPlan";
import EnterpriseYearPlan from "./components/Panel/Pricing/PricingPlans/Year/EnterpriseYearPlan";
import LifeTimePlan from "./components/Panel/LifeTimePlan/LifeTimePlan";
import TeamMember from "./components/Panel/TeamMember/TeamMember";
import ViewTeamMembers from "./components/Panel/TeamMember/ViewTeamMembers/ViewTeamMembers";
import ContactUs from "./components/ContactUs/ContactUs";
import TermsAndConditions from "./components/TermsAndConditions/TermsAndConditions";
import PrivacyPolicy from "./components/PrivacyPolicy/PrivacyPolicy";
import ZapierIntegrationGuide from "./components/Zapier/Guides/ZapierIntegrationGuide";
import Zapier from "./components/Zapier/Zapier";
import ReactGA from "react-ga";
import { useLocation } from "react-router-dom";

const GA_MEASUREMENT_ID = "G-3V2FVF42GK";

ReactGA.initialize(GA_MEASUREMENT_ID);

function usePageTracking() {
	let location = useLocation();

	React.useEffect(() => {
		ReactGA.send({ hitType: "pageview", page: location.pathname });
	}, [location]);
}

const App = () => {
	usePageTracking();

	// const stripePromise = loadStripe( 
	// 	"pk_live_51MpuXqEfBpiSjTWhNqbefXgJ18KZ87PU6QOao2HaOXcxGhJRoF6BYMv8CSqmn0ufEI0b3b7nv7Osm0rJJ9taHcX500C4BuJRhf"
	// );

	useEffect(() => {
		ReactGA.initialize("G-3V2FVF42GK");
		ReactGA.pageview(window.location.pathname + window.location.search);
	}, []);

	const stripePromise = loadStripe(process.env.REACT_APP_PUBLISHABLE_KEY);

	return (
			<Router history={history}>
				<Switch>
					<Route exact path={routes.home}>
						<LandingPage />
					</Route>
					<Route exact path={routes.contact}>
						<ContactUs />
					</Route>
					<Route exact path={routes.auth.logIn} component={Login} />
					<Route exact path={routes.auth.signUp} component={Signup} />
					<Route
						exact
						path={routes.auth.forgotPassword}
						component={ForgotPassword}
					/>
					<Route
						exact
						path={routes.auth.resetPassword}
						component={ResetPassword}
					/>
					<Route exact path={routes.auth.emailVerification}>
						<EmailVerificationPage />
					</Route>
					<Route exact path={routes.auth.emailVerified}>
						<EmailVerified />
					</Route>
					<AuthRoute exact path={routes.termsConditions}>
						<PanelLayout>
							<TermsAndConditions />
						</PanelLayout>
					</AuthRoute>
					<Route exact path={routes.privacyPolicy}>
						<PrivacyPolicy />
					</Route>
					<AuthRoute exact path={routes.panel.dashboard}>
						<PanelLayout>
							<PanelHome />
						</PanelLayout>
					</AuthRoute>
					<AuthRoute exact path={routes.panel.wizard}>
						<PanelLayout>
							<WizardPage />
						</PanelLayout>
					</AuthRoute>
					<AuthRoute exact path={routes.panel.fancyTitles}>
						<PanelLayout>
							<FancyTitles />
						</PanelLayout>
					</AuthRoute>
					<AuthRoute exact path={routes.panel.titleOutline}>
						<PanelLayout>
							<TitleOutline />
						</PanelLayout>
					</AuthRoute>
					<AuthRoute exact path={routes.panel.wizardEditor}>
						<PanelLayout>
							<ArticleEditor />
						</PanelLayout>
					</AuthRoute>
					<AuthRoute exact path={routes.panel.documents}>
						<PanelLayout>
							<MyDocuments />
						</PanelLayout>
					</AuthRoute>
					<AuthRoute exact path={routes.panel.viewArticle}>
						<PanelLayout>
							<ViewArticleFromHistory />
						</PanelLayout>
					</AuthRoute>
					<AuthRoute exact path={routes.panel.apiDocumentation}>
						<PanelLayout>
							<ApiDocumentation />
						</PanelLayout>
					</AuthRoute>
					<AuthRoute exact path={routes.panel.pricing}>
						<PanelLayout>
							<Pricing />
						</PanelLayout>
					</AuthRoute>
					<AuthRoute exact path={routes.panel.setting}>
						<PanelLayout>
							<Settings />
						</PanelLayout>
					</AuthRoute>
					<AuthRoute exact path={routes.panel.bulkGeneration}>
						<PanelLayout>
							<BulkGenerationHome />
						</PanelLayout>
					</AuthRoute>
					<AuthRoute exact path={routes.panel.createCampaigns}>
						<PanelLayout>
							<CreateNewCampaign />
						</PanelLayout>
					</AuthRoute>
					<AuthRoute exact path={routes.panel.selectedCampaign}>
						<PanelLayout>
							<SelectedCampaign />
						</PanelLayout>
					</AuthRoute>
					<AuthRoute exact path={routes.panel.previewCampaignArticle}>
						<PanelLayout>
							<ViewCampaignArticle />
						</PanelLayout>
					</AuthRoute>
					<AuthRoute exact path={routes.panel.zapier.zapier}>
						<PanelLayout>
							<Zapier />
						</PanelLayout>
					</AuthRoute>
					<AuthRoute exact path={routes.panel.zapier.zapierGuides}>
						<PanelLayout>
							<ZapierIntegrationGuide />
						</PanelLayout>
					</AuthRoute>

					{/* Pricing */}
					{/* Month */}
					<AuthRoute exact path={routes.panel.pricingPlans.basicMonthly}>
						<Elements stripe={stripePromise}>
							<PanelLayout>
								<BasicMonthPlan />
							</PanelLayout>
						</Elements>
					</AuthRoute>
					<AuthRoute exact path={routes.panel.pricingPlans.proMonthly}>
						<Elements stripe={stripePromise}>
							<PanelLayout>
								<ProMonthPlan />
							</PanelLayout>
						</Elements>
					</AuthRoute>
					<AuthRoute exact path={routes.panel.pricingPlans.teamMonthly}>
						<Elements stripe={stripePromise}>
							<PanelLayout>
								<TeamMonthPlan />
							</PanelLayout>
						</Elements>
					</AuthRoute>
					<AuthRoute exact path={routes.panel.pricingPlans.enterpriseMonthly}>
						<Elements stripe={stripePromise}>
							<PanelLayout>
								<EnterpriseMonthPlan />
							</PanelLayout>
						</Elements>
					</AuthRoute>

					<AuthRoute exact path={routes.panel.pricingPlans.basicYearly}>
						<Elements stripe={stripePromise}>
							<PanelLayout>
								<BasicYearPlan />
							</PanelLayout>
						</Elements>
					</AuthRoute>
					<AuthRoute exact path={routes.panel.pricingPlans.proYearly}>
						<Elements stripe={stripePromise}>
							<PanelLayout>
								<ProYearPlan />
							</PanelLayout>
						</Elements>
					</AuthRoute>
					<AuthRoute exact path={routes.panel.pricingPlans.teamYearly}>
						<Elements stripe={stripePromise}>
							<PanelLayout>
								<TeamYearPlan />
							</PanelLayout>
						</Elements>
					</AuthRoute>
					<AuthRoute exact path={routes.panel.pricingPlans.enterpriseYearly}>
						<Elements stripe={stripePromise}>
							<PanelLayout>
								<EnterpriseYearPlan />
							</PanelLayout>
						</Elements>
					</AuthRoute>
					<AuthRoute exact path={routes.panel.pricingPlans.lifeTimePlan}>
						<Elements stripe={stripePromise}>
							<PanelLayout>
								{/* <EnterpriseYearPlan/> */}
								<LifeTimePlan />
							</PanelLayout>
						</Elements>
					</AuthRoute>

					{/*Add Team Member */}
					<AuthRoute exact path={routes.panel.addTeamMember}>
						<PanelLayout>
							<TeamMember />
						</PanelLayout>
					</AuthRoute>
					<AuthRoute exact path={routes.panel.viewTeamMembers}>
						<PanelLayout>
							<ViewTeamMembers />
						</PanelLayout>
					</AuthRoute>
				</Switch>
			</Router>
	);
};

export default App;
