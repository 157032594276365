import React from "react";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import routes from "../../../config/routes";

const PricinglandingPage = () => {
  return (
    <section id="pricing">
      <h2 className="title">Pricing</h2>
      <p>Check out our amazing reviews</p>
      <Tabs>
        <TabList style={{ color: "black", cursor: "pointer" }}>
          <Tab>Monthly</Tab>
          <Tab className="second_tab">Yearly ( save upto 17%)</Tab>
        </TabList>

        <TabPanel>
          <div className="site_container">
            <div className="row">
              <div className="col-lg-3 col-md-4 col-sm-12 col-12  price_card_col">
                <div className="pricing_cards">
                  <h6>Basic</h6>
                  <p className="free_tag">
                    <b>$29</b>
                  </p>
                  <span className="pricing_tag">
                    Start unlocking the magic SEO superpowers <br /> of AI today
                  </span>
                  <ul>
                    <li>
                      <span className="points">
                        100-Articles
                        {/* ( Almost 100,000 words) */}
                      </span>
                    </li>
                    <li>
                      <span className="points">Text Editor</span>
                    </li>
                    <li>
                      <span className="points">
                        Auto SEO Optimized Articles
                      </span>
                    </li>
                  </ul>
                  <button type="button" className="card_btn">
                    <Link
                      style={{ color: "white" }}
                      to={routes.auth.signUp}
                      type="button"
                    >
                      Start Trial
                    </Link>
                  </button>
                  <span className="info">Unlock the potential instantly</span>
                  <span className="info">Cancel Any Time</span>
                </div>
              </div>
              <div className="col-lg-3 col-md-4 col-sm-12 col-12  price_card_col">
                <div className="pricing_cards">
                  <h6>Pro </h6>
                  <p className="free_tag">
                    <b>$59</b>
                  </p>
                  <span className="pricing_tag">
                    All the AI-features you need for outranking your
                    competitors.
                  </span>
                  <ul>
                    <li>
                      <span className="points">300-Articles</span>
                    </li>
                    <li>
                      <span className="points">Text Editor</span>
                    </li>
                    <li>
                      <span className="points">Auto SEO Optimized Article</span>
                    </li>
                  </ul>
                  <button type="button" className="card_btn">
                    <Link
                      style={{ color: "white" }}
                      to={routes.auth.signUp}
                      type="button"
                    >
                      Start Trial
                    </Link>
                  </button>
                  <span className="info">Unlock the potential instantly</span>
                  <span className="info" style={{ color: "black" }}>
                    Cancel Any Time
                  </span>
                </div>
              </div>
              <div className="col-lg-3 col-md-4 col-sm-12 col-12  price_card_col">
                <div className="pricing_cards">
                  <h6>Team</h6>
                  <p className="free_tag">
                    <b>$149</b>
                  </p>
                  <span className="pricing_tag">
                    For those who need more amounts of content generation
                  </span>
                  <ul>
                    <li>
                      <span className="points">1000-Articles</span>
                    </li>
                    <li>
                      <span className="points">Text Editor</span>
                    </li>
                    <li>
                      <span className="points">
                        Auto SEO Optimized Articles
                      </span>
                    </li>
                  </ul>
                  <button type="button" className="card_btn">
                    <Link
                      style={{ color: "white" }}
                      to={routes.auth.signUp}
                      type="button"
                    >
                      Start Trial
                    </Link>
                  </button>
                  <span className="info">Unlock the potential instantly</span>
                  <span className="info">Cancel Any Time</span>
                </div>
              </div>
              <div className="col-lg-3   col-md-4 col-sm-12 col-12  price_card_col">
                <div className="pricing_cards">
                  <h6>Enterprise</h6>
                  <p className="free_tag">
                    <b>$499</b>
                  </p>
                  <span className="pricing_tag">
                    Need something with no limits? Go fast & furious along with
                    your team.
                  </span>
                  <ul>
                    <li>
                      <span className="points">Unlimited Articles</span>
                    </li>
                    <li>
                      <span className="points">Text Editor</span>
                    </li>
                    <li>
                      <span className="points">Auto SEO Optimized Article</span>
                    </li>
                  </ul>
                  <button type="button" className="card_btn">
                    <Link
                      style={{ color: "white" }}
                      to={routes.auth.signUp}
                      type="button"
                    >
                      Start Trial
                    </Link>
                  </button>
                  <span className="info">Unlock the potential instantly</span>
                  <span className="info" style={{ color: "black" }}>
                    Cancel Any Time
                  </span>
                </div>
              </div>
            </div>
          </div>
        </TabPanel>
        <TabPanel>
          <div className="site_container">
            <div className="row">
              <div className="col-lg-3 col-md-4 col-sm-12 col-12  price_card_col">
                <div className="pricing_cards">
                  <h6>Basic</h6>
                  <p className="free_tag">
                    <b>$299</b>
                  </p>
                  <span className="pricing_tag">
                    Start unlocking the magic SEO superpowers <br /> of AI today
                  </span>
                  <ul>
                    <li>
                      <span className="points">
                        100-Articles
                        {/* ( Almost 100,000 words) */}
                      </span>
                    </li>
                    <li>
                      <span className="points">Text Editor</span>
                    </li>
                    <li>
                      <span className="points">
                        Auto SEO Optimized Articles
                      </span>
                    </li>
                  </ul>
                  <button type="button" className="card_btn">
                    <Link
                      style={{ color: "white" }}
                      to={routes.auth.signUp}
                      type="button"
                    >
                      Start Trial
                    </Link>
                  </button>
                  <span className="info">Unlock the potential instantly</span>
                  <span className="info">Cancel Any Time</span>
                </div>
              </div>
              <div className="col-lg-3 col-md-4 col-sm-12 col-12  price_card_col">
                <div className="pricing_cards">
                  <h6>Pro </h6>
                  <p className="free_tag">
                    <b>$599</b>
                  </p>
                  <span className="pricing_tag">
                    All the AI-features you need for outranking your
                    competitors.
                  </span>
                  <ul>
                    <li>
                      <span className="points">300-Articles</span>
                    </li>
                    <li>
                      <span className="points">Text Editor</span>
                    </li>
                    <li>
                      <span className="points">Auto SEO Optimized Article</span>
                    </li>
                  </ul>
                  <button type="button" className="card_btn">
                    <Link
                      style={{ color: "white" }}
                      to={routes.auth.signUp}
                      type="button"
                    >
                      Start Trial
                    </Link>
                  </button>
                  <span className="info">Unlock the potential instantly</span>
                  <span className="info" style={{ color: "black" }}>
                    Cancel Any Time
                  </span>
                </div>
              </div>
              <div className="col-lg-3 col-md-4 col-sm-12 col-12  price_card_col">
                <div className="pricing_cards">
                  <h6>Team</h6>
                  <p className="free_tag">
                    <b>$1499</b>
                  </p>
                  <span className="pricing_tag">
                    For those who need more amounts of content generation
                  </span>
                  <ul>
                    <li>
                      <span className="points">1000-Articles</span>
                    </li>
                    <li>
                      <span className="points">Text Editor</span>
                    </li>
                    <li>
                      <span className="points">
                        Auto SEO Optimized Articles
                      </span>
                    </li>
                  </ul>
                  <button type="button" className="card_btn">
                    <Link
                      style={{ color: "white" }}
                      to={routes.auth.signUp}
                      type="button"
                    >
                      Start Trial
                    </Link>
                  </button>
                  <span className="info">Unlock the potential instantly</span>
                  <span className="info">Cancel Any Time</span>
                </div>
              </div>
              <div className="col-lg-3   col-md-4 col-sm-12 col-12  price_card_col">
                <div className="pricing_cards">
                  <h6>Enterprise</h6>
                  <p className="free_tag">
                    <b>$4999</b>
                  </p>
                  <span className="pricing_tag">
                    Need something with no limits? Go fast & furious along with
                    your team.
                  </span>
                  <ul>
                    <li>
                      <span className="points">Unlimited Articles</span>
                    </li>
                    <li>
                      <span className="points">Text Editor</span>
                    </li>
                    <li>
                      <span className="points">Auto SEO Optimized Article</span>
                    </li>
                  </ul>
                  <button type="button" className="card_btn">
                    <Link
                      style={{ color: "white" }}
                      to={routes.auth.signUp}
                      type="button"
                    >
                      Start Trial
                    </Link>
                  </button>
                  <span className="info">Unlock the potential instantly</span>
                  <span className="info" style={{ color: "black" }}>
                    Cancel Any Time
                  </span>
                </div>
              </div>
            </div>
          </div>
        </TabPanel>
      </Tabs>
    </section>
  );
};

export default PricinglandingPage;
