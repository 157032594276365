import React, { useEffect, useState } from "react";
import { Redirect } from "react-router-dom/cjs/react-router-dom.min";
import routes from "../../../config/routes";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import {
	generateFancyHeadline,
	setArticle,
	setKeywords,
	setPanelTitle,
} from "../../../redux/actions/panel";
import { DotLoader } from "react-spinners";
import progress1 from "../../../assets/images/progress1.jpg";
import "./WizardPage.scss";

import { languagesOption } from "../../../constants/LanguagesOption";

const WizardPage = () => {
	const dispatch = useDispatch();

	const token = useSelector((state) => state?.Authentication?.token);

	const [articleLength, setArticleLength] = useState("");

	const goToFancyTitlesCheck = useSelector(
		(state) => state?.PanelData?.fancyTitleCreationLoader
	);

	const [language, setLanguage] = useState("English");

	let keywords = JSON.parse(localStorage.getItem("Keywords"));

	const [redirect, setRedirect] = useState(false);

	const [tags, setTags] = useState([]);
	const [error, setError] = useState("");

	const panelLoader = useSelector((state) => state?.PanelData?.panelLoader);

	const user = useSelector((state) => state?.Authentication?.user) 
	const expiryDetail = useSelector((state) => state?.Pricing?.expiryDetail);

	useEffect(() => {
		for (let i = 0; i < keywords?.length; i++) {
			setTags([...keywords]);
		}
	}, []);

	const [title, setTitle] = useState(localStorage.getItem("Title") || "");
	const [titleError, setTitleError] = useState("");

	const [showErrorDiv, setShowErrorDiv] = useState(false);

	useEffect(() => {
		setTitleError("");
		if (title?.startsWith(" ")) {
			setTitleError("Please write a proper title.");
		}
	}, [title]);

	useEffect(() => {
		localStorage?.removeItem("Article-Generate-Check");
		localStorage.removeItem("Saved-Article");
		localStorage.removeItem("Article-Generated");
		localStorage.removeItem("Article-By-Id");
		localStorage.removeItem("Fancy-Titles");
		localStorage.removeItem("Selected-Outline");
		localStorage.removeItem("Selected-Fancy-Title");
		localStorage.removeItem("Fancy-Headline");
		localStorage.removeItem("article-length");
		localStorage.removeItem("123");
		localStorage.removeItem("Language");
		localStorage.removeItem("Title");
		localStorage.removeItem("Value");
		localStorage.removeItem("Keywords");
		localStorage.removeItem("refreshed");
		dispatch(setArticle(null));
	}, [title, tags, language, articleLength]);

	// Add or remove tags by using the key
	const handleTags = (event) => {
		if (
			(event.key === "Enter" || event.key === ",") &&
			event.target.value !== "" &&
			tags.length < 10
		) {
			event.preventDefault();
			setTags([...tags, event.target.value]);
			event.target.value = "";
		} else if (
			event.key === "Backspace" &&
			tags.length &&
			event.target.value == 0
		) {
			const tagsCopy = [...tags];
			tagsCopy.pop();
			event.preventDefault();
			setTags(tagsCopy);
		} else if (tags.length === 0 && event.key === "Backspace") {
			setError(
				"Since there are no keywords you can't able to delete any keywords"
			);
		} else if (tags.length >= 10) {
			event.preventDefault();
			setError("You can't add more keywords");
		} else if (tags.length >= 10 && event.key === ",") {
			event.preventDefault();
		} else if (event.target.value == "" && event.code == "Space") {
			setError("The keyword should be one character long!");
		} else if (
			event.target.value == "" &&
			(event.key === "Enter" || event.key === ",")
		) {
			event.preventDefault();
			setError("The keyword should be one character long!");
		}
	};

	//Remove tags by clicking the cross sign
	const removeTags = (index) => {
		setTags([...tags.filter((tag) => tags.indexOf(tag) !== index)]);
	};

	//To update the error after user changes something
	const handleError = () => {
		setError("");
	};

	const handleTitle = (e) => {
		setTitle(e.target.value);
	};

	const handleGenerateFancyTitles = (e) => {
		e.preventDefault();
	  
		const disabled = expiryDetail?.expiryStatus !== "active";
		const remainingCredits = expiryDetail?.subscription?.noOfArticles || 0;
		const referredNumberOfArticles = expiryDetail?.subscription?.referredNofArticles || 0;
		const creditsPerDay = expiryDetail?.subscription?.creditsPerDay;
	  
		if (disabled) {
		  toast.error("Your payment plan has ended. Go to pricing to buy/upgrade your subscription.");
		  return;
		}
	  
		if (title === "") {
		  setTitleError("Add a title to create fancy headlines.");
		  return;
		}
	  
		if (tags.length === 0) {
		  setError("There should be at least one keyword");
		  return;
		}
	  
		if (articleLength === "") {
		  toast.error("Select article length.");
		  return;
		}
	  
		const requiredCredits = articleLength === "short" ? 1 : (articleLength === "long" || articleLength === "longPro" ? 2 : 0);
		const lessCredit = remainingCredits < requiredCredits && creditsPerDay < requiredCredits && referredNumberOfArticles < requiredCredits;
	  
		if (lessCredit) {
		  toast.error("You do not have enough credits.");
		  return;
		}
	  
		localStorage.setItem("Title", title);
		dispatch(setPanelTitle(title));
		localStorage.setItem("Language", language);
		localStorage.setItem("Keywords", JSON.stringify(tags));
	  
		const payload = {
		  topic: title,
		  language: language,
		};
	  
		dispatch(setKeywords(tags));
		dispatch(generateFancyHeadline(payload));
		setRedirect(true);
	  };
	  
	useEffect(() => {
		localStorage.setItem("article-length", JSON.stringify(articleLength));
	}, [articleLength]);

	if (redirect && goToFancyTitlesCheck) {
		return <Redirect to={routes.panel.fancyTitles} />;
	}

	if (user === undefined || token === undefined) {
		return <Redirect to={routes.auth.logIn} />;
	}

	let tabShowLongPro =
		!(expiryDetail?.subscription?.subscriptionPlan === "Free Trial");

	return (
		<>
			<section id="WizardPage">
				<h2>Wizard</h2>

				<div className="inner">
					<h3>Generate SEO Optimized Article</h3>
					<p>Please fill the form below to receive a well optimized article</p>
					<form className="headline_form">
						<div className="progress_blk">
							<img src={progress1} alt="progress" />
						</div>
						<hr />
						<label className="mb-4">Headline/Topic</label>
						<div className="input_blk">
							<input
								type="text"
								value={title}
								onChange={handleTitle}
								placeholder="Enter Your Topic"
							/>
						</div>
						{titleError && (
							<div className="error-message mb-4">{titleError}</div>
						)}
						<label>Enter SEO Keywords (Max 10)</label>
						<span>Add one keyword & press enter for adding next</span>
						<div className="input_blk">
							<input
								type="text"
								onKeyDown={(event) => handleTags(event)}
								onChange={handleError}
								placeholder="Add keywords that are related to topic"
							/>
						</div>
						<div className="error-message mb-4">{error}</div>
						<ul className="keyword">
							{tags.map((tag, index) => (
								<li className="keyword_list" key={index}>
									{tag}
									<i
										className="fa-solid fa-xmark mr-2"
										onClick={() => removeTags(index)}
									></i>
								</li>
							))}
						</ul>
						<label className="mb-4">Choose Language</label>
						<select
							className="select_blk"
							placeholder="e.g Malaysia Travel Plan"
							value={language}
							onChange={(e) => setLanguage(e.target.value)}
						>
							{
								languagesOption.map(language => (
									<option key={language}>{language}</option>
								))
							}
						</select>
						<label className="mb-4">Choose Template</label>
						<button
							type="button"
							className={`tab_btn ${articleLength === "short" ? "active" : ""}`}
							onClick={() => {
								setShowErrorDiv(false);
								setArticleLength("short");
							}}
						>
							Short Form
						</button>
						{articleLength === "short" && (
							<div className="tab_data">
								Cost: 1 Credit - Article Length: 1500-2000 words
							</div>
						)}
						<button
							type="button"
							className={`tab_btn ${articleLength === "long" ? "active" : ""}`}
							onClick={() => {
								setShowErrorDiv(false);
								setArticleLength("long");
							}}
						>
							Long Form
						</button>
						{articleLength === "long" && (
							<div className="tab_data">
								Cost: 2 Credits - Article Length: 2000-3000 words
							</div>
						)}
						{tabShowLongPro ? (
							<>
								<button
									type="button"
									className={`tab_btn ${
										articleLength === "longPro" ? "active" : ""
									}`}
									onClick={() => setArticleLength("longPro")}
								>
									Long Form Pro
								</button>
								{articleLength === "longPro" && (
									<div className="tab_data">
										Cost: 2 Credits - Article Length: 5000-7000 words
									</div>
								)}
							</>
						) : (
							<>
								<button
									type="button"
									className={`tab_btn ${
										articleLength === "longPro" ? "active" : ""
									}`}
									onClick={() => {
										setShowErrorDiv(!showErrorDiv);
										toast.info(
											"You are not subscribed to monthly or yearly plan."
										);
									}}
								>
									Long Form Pro
								</button>
								{showErrorDiv && (
									<div className="tab_data_red">
										Feature only available to monthly/yearly subscription users.
										<br />
										Cost: 2 Credits - Article Length: 5000-7000 words
									</div>
								)}
							</>
						)}
					</form>
					{panelLoader ? (
						<div className="loaderWrapper">
							<DotLoader className="login-clip-loader" color={"#7F56D9"} />
							<h1
								style={{
									color: "#7F56D9",
									fontSize: "2.4rem",
								}}
							>
								&nbsp;&nbsp;&nbsp;&nbsp;Creating Fancy Titles...
							</h1>
						</div>
					) : (
						<button
							className="generate_title_btn"
							onClick={handleGenerateFancyTitles}
						>
							Generate Title
						</button>
					)}
				</div>
			</section>
		</>
	);
};

export default WizardPage;
