import React, { useEffect, useState } from "react";
import bulkProgress1 from "../../../../assets/images/bulkProgress1.jpg";
import bulkProgress2 from "../../../../assets/images/bulkProgress2.jpg";

import "./newCampaign.scss";
import { useDispatch, useSelector } from "react-redux";
import { createCampaign, setCreatedCampaignRedirection } from "../../../../redux/actions/bulkGeneration";
import { toast } from "react-toastify";
import { Redirect } from "react-router-dom/cjs/react-router-dom.min";
import routes from "../../../../config/routes";
import { languagesOption } from "../../../../constants/LanguagesOption";
let listOfArticles = []

const CreateNewCampaign = () => {

    const dispatch = useDispatch();
    const [publishTime, setPublishTime] = useState('day'); // Default to 'day'
    const [interval, setPublishTimeInterval] = useState('1'); // Default interval value

    const [redirection, setRedirection] = useState(false);
    const [articleLength, setArticleLength] = useState("");
    const [showErrorDiv, setShowErrorDiv] = useState(false);

    useEffect(() => {
        dispatch(setCreatedCampaignRedirection(false));
    }, [])

    const [generateArticles, setGenerateArticles] = useState(false);

    const [currentForm, setCurrentForm] = useState(1);
    const [tags, setTags] = useState([]);
    const [error, setError] = useState("");
    const [title, setTitle] = useState("");

    const [articlesList, setArticlesList] = useState([]);

    const [titleError, setTitleError] = useState("");

    useEffect(() => {
        setTitleError("");
        if (title?.startsWith(" ")) {
            setTitleError("Please write a proper title.");
        }
    }, [title]);

    const handleTitle = (e) => {
        setTitle(e.target.value);
    };

    const handleTags = (event) => {
        if ((event.key === "Enter" || event.key === ",") && event.target.value !== "" && tags.length < 10) {
            event.preventDefault();
            setTags([...tags, event.target.value]);
            event.target.value = "";
        }
        else if (
            event.key === "Backspace" &&
            tags.length &&
            event.target.value == 0
        ) {
            const tagsCopy = [...tags];
            tagsCopy.pop();
            event.preventDefault();
            setTags(tagsCopy);
        } else if (tags.length === 0 && event.key === "Backspace") {
            setError(
                "Since there are no keywords you can't able to delete any keywords"
            );
        } else if (tags.length >= 10 && (event.key === "," || event.key === "Enter")) {
            event.preventDefault();
            setError("You can't add more keywords");
        } else if (event.target.value == "" && event.code == "Space") {
            setError("The keyword should be one character long!");
        } else if (event.target.value == "" && (event.key === "Enter" || event.key === ",")) {
            event.preventDefault();
            setError("The keyword should be one character long!");
        }
    };

    const removeTags = (index) => {
        setTags([...tags.filter((tag) => tags.indexOf(tag) !== index)]);
    };

    const handleError = () => {
        setError("");
    };

    const [values, setValues] = useState({
        campaignName: '',
        numberOfArticles: '',
    })

    // const [publishTime, setPublishTime] = useState({
    //     day: '',
    //     hour: '',
    // })

    // const handlePublishTimeChange = e => {
    //     const { name, value } = e.target;
    //     setPublishTime({
    //         ...publishTime,
    //         [name]: value
    //     });
    // }
    const handlePublishTimeChange = (name, value) => {
        if (name === 'publishTime') {
            setPublishTime(value);
            if (value === 'day') {
                // If the user selects 'day' as publishing time, set the interval to 1 by default
                setPublishTimeInterval('1');
            }
        } else if (name === 'interval') {
            setPublishTimeInterval(value);
        }
    }

    const [selectedLanguage, setSelectedLanguage] = useState('English');
    const [publishingPreference, setPublishingPreference] = useState('');
    const [publishingTime, setPublishingTime] = useState('');

    useEffect(() => {
        if (!generateArticles) {
            setTags([]);
            setTitle('')
            listOfArticles = [];
            setArticlesList([]);
        }
    }, [generateArticles])

    const handleChange = e => {
        const { name, value } = e.target;
        setValues({
            ...values,
            [name]: value
        });
    };
    const expiryDetail = useSelector((state) => state?.Pricing?.expiryDetail);

    const handleNextButton = (e) => {
        e.preventDefault();
    
        const { expiryStatus, subscription } = expiryDetail || {};
        const remainingCredits = subscription?.noOfArticles || 0;
        const creditsPerDay = subscription?.creditsPerDay || 0;
        const referredNoOfArticles = subscription?.referredNoOfArticles || 0;
        const requiredCredits = values.numberOfArticles * (articleLength === "short" ? 1 : (["long", "longPro"].includes(articleLength) ? 2 : 0));
        const isDisabled = expiryStatus !== "active";
        const isInvalidNumberOfArticles = Number(values.numberOfArticles) <= 0 || Number(values.numberOfArticles) > 30;
        const isInsufficientCredits = remainingCredits < requiredCredits && creditsPerDay < requiredCredits && referredNoOfArticles < requiredCredits;

    
        if (isDisabled) {
            toast.error("Your payment plan has ended. Go to pricing to buy/upgrade your subscription.");
        } else if (!values.campaignName) {
            toast.error('Enter your campaign name.');
        } else if (!values.numberOfArticles) {
            toast.error('Enter the number of articles.');
        } else if (isInvalidNumberOfArticles) {
            toast.error('Number of articles must be between 1 and 30.');
        } else if (isInsufficientCredits) {
            toast.error('Not enough articles available.');
        } else if (!selectedLanguage) {
            toast.error('Select a language.');
        } else if (!publishingPreference) {
            toast.error('Select a preference to publish articles.');
        } else if (!articleLength) {
            toast.error('Select article length.');
        } else if (publishingPreference === 'Automatic') {
            if (!publishingTime) {
                toast.error('Select a publishing time.');
            } else if (publishingTime === 'Everyday') {
                if (!publishTime) {
                    toast.error('Enter the publishing days and hours.');
                } else if (!interval) {
                    toast.error('Enter the publishing interval.');
                } else {
                    setGenerateArticles(true);
                }
            } else {
                setGenerateArticles(true);
            }
        } else {
            setGenerateArticles(true);
        }
    };

    useEffect(() => {
        setArticlesList(listOfArticles)
    }, [listOfArticles])

    const handleGoBack = () => {
        setGenerateArticles(false);
        listOfArticles = [];
        setArticlesList([]);
        setTags([]);
        setTitle('');
    }

    const handlePrevious = () => {
        setTitle('')
        if (currentForm !== 1) {
            setCurrentForm((prevForm) => prevForm - 1);

            if (listOfArticles.length === (currentForm - 1)) {
                setTitle(listOfArticles[(listOfArticles.length - 1)]?.topic);
                setTags(listOfArticles[(listOfArticles.length - 1)]?.keywords)
                listOfArticles.pop();
            }
            else {
                listOfArticles.pop();
                setTitle(listOfArticles[(listOfArticles.length - 1)]?.topic);
                setTags(listOfArticles[(listOfArticles.length - 1)]?.keywords)
            }
        }
    };



    const handleNext = (e) => {
        e.preventDefault();
        if (title === "") {
            setTitleError("Enter a title to proceed.");
            return;
        } else if (tags.length === 0) {
            setError("There should be atleast one keyword");
            return;
        } else {
            if (currentForm < values.numberOfArticles) {
                setCurrentForm((prevForm) => prevForm + 1);
                
                const item = {
                    topic: title,
                    keywords: tags,
                    isPublished: false,
                }
                listOfArticles.push(item);
                setTitle('');
                setTags([]);

            }
        }
    };

    const user = useSelector((state) => state?.Authentication?.user) 

    const userId = JSON.parse(user)?._id;
    const role = JSON.parse(user)?.role;

    const handleGenerateCampaign = (e) => {
        e.preventDefault();
    
        // Validation
        if (title === "") {
            setTitleError("Enter a title to proceed.");
            return;
        }
        if (tags.length === 0) {
            setError("There should be at least one keyword");
            return;
        }
    
        // Create item
        const item = {
            topic: title,
            keywords: tags,
            isPublished: false,
        };
       listOfArticles.push(item);

        setRedirection(true);
        // Prepare payload
        const parentUserId = JSON.parse(user)?.parentUserId;
        const payload = {
            name: values.campaignName,
            noOfArticles: Number(values.numberOfArticles),
            language: selectedLanguage,
            role,
            articles: articlesList,
            articleType: articleLength,
            publishingPreference: {
                preference: publishingPreference.toLowerCase(),
            },
        };
    
        // Set parentUserId or userId based on role
        if (role === 'Team Member') {
            payload.parentUserId = parentUserId;
        } else {
            payload.userId = userId;
        }
    
        // Set unit and interval for 'Everyday' publishing time
        if (publishingPreference === 'Automatic' && publishingTime === 'Everyday') {
            payload.publishingPreference.unit = publishTime;
            payload.publishingPreference.interval = Number(interval);
        }
        // Dispatch action
        dispatch(createCampaign(payload));
    };
    
    if (redirection) {
        return <Redirect to={routes.panel.bulkGeneration} />;
    }

    let tabShowLongPro = expiryDetail?.subscription?.subscriptionPlan === "Free Trial" ? false : true;

    
    return (
        <>
            <section id="CreateNewCampaign">
                <h2>Bulk Campaign Wizard</h2>

                <div className="inner">
                    <h3>Generate SEO Optimized Article</h3>
                    <p>Please fill the form below to start automatic bulk campaign</p>
                    {
                        !generateArticles &&
                        <>
                            <form className="headline_form">
                                <div className="progress_blk">
                                    <img src={bulkProgress1} alt="" />
                                </div>
                                <hr />
                                <label className="mb-4">Name of campaign</label>
                                <div className="input_blk">
                                    <input type="text" name='campaignName' placeholder="e.g campaign no 1" value={values.campaignName} onChange={handleChange} />
                                </div>
                                <span>
                                    check bulk generation limit above before starting campaign
                                </span>
                                <label className="mb-4">Number of articles</label>
                                <div className="input_blk">
                                    <input name='numberOfArticles'
                                        type="number"
                                        placeholder="Enter number of Article You want to generate"
                                        value={values.numberOfArticles}
                                        onChange={handleChange} />
                                </div>
                                <label className="mb-4">Choose Language</label>
                                <select
                                    className="select_blk"
                                    value={selectedLanguage} onChange={(e) => setSelectedLanguage(e.target.value)}
                                >
                                    	{
								languagesOption.map(language => (
									<option key={language}>{language}</option>
								))
							}
                                </select>
                                <label className="mb-4">Choose Mode</label>
                                <div className="radio_btn_container mt-4">
                                    <div class="form-check ">
                                        <input
                                            class="form-check-input"
                                            type="radio"
                                            name="publishingPreference"
                                            id="flexRadioDefault1"
                                            value='Automatic'
                                            onChange={(e) => setPublishingPreference(e.target.value)}
                                            checked={publishingPreference === 'Automatic'}
                                        />
                                        <label class="form-check-label ml-3" for="flexRadioDefault1">
                                            Automatic
                                        </label>
                                    </div>
                                    <div class="form-check">
                                        <input
                                            class="form-check-input "
                                            type="radio"
                                            name="publishingPreference"
                                            id="flexRadioDefault2"
                                            value='Manual'
                                            onChange={(e) => setPublishingPreference(e.target.value)}
                                            checked={publishingPreference === 'Manual'}
                                        />
                                        <label class="form-check-label ml-3" for="flexRadioDefault2">
                                            Manual
                                        </label>
                                    </div>
                                </div>
                                {
                                    publishingPreference === 'Automatic' &&
                                    <>
                                        <div class="form-check mt-4">
                                            <input
                                                class="form-check-input"
                                                type="radio"
                                                value="AtOnce"
                                                name='time'
                                                id="flexCheckDefaultTime2"
                                                onChange={(e) => setPublishingTime(e.target.value)}
                                            />
                                            <label class="form-check-label ml-3 mb-0" for="flexCheckDefaultTime2">
                                                All at once
                                            </label>
                                        </div>
                                        <div className="checkbox_container">
                                            <div class="form-check">
                                                <input
                                                    class="form-check-input"
                                                    type="radio"
                                                    name='time'
                                                    value="Everyday"
                                                    id="flexCheckDefaultTime1"
                                                    onChange={(e) => setPublishingTime(e.target.value)}
                                                />
                                                <label
                                                    class="form-check-label ml-3 mb-0"
                                                    for="flexCheckDefaultTime1"
                                                >
                                                    Publish after
                                                </label>
                                            </div>
                                            {/* {
                                                publishingTime === 'Everyday' &&
                                                <div className="input_container">
                                                    <label className="mr-2">Day</label>
                                                    <input type="text" className="mr-3" name='day' value={publishTime.day} onChange={handlePublishTimeChange} />
                                                    <label className="mr-2">Hour</label>
                                                    <input type="text" name='hour' value={publishTime.hour} onChange={handlePublishTimeChange} />
                                                </div>
                                            } */}

{publishingTime === 'Everyday' &&
    <div className="input_container">
        <div className="radio_btn_container mt-4">
            <div className="form-check">
                <input
                    className="form-check-input"
                    type="radio"
                    name="publishTime"
                    id="flexRadioDefaultDay"
                    value="day"
                    onChange={(e) => handlePublishTimeChange('publishTime', e.target.value)}
                    checked={publishTime === 'day'}
                />
                <label className="form-check-label ml-3" htmlFor="flexRadioDefaultDay">
                    Day
                </label>
            </div>
            <div className="form-check">
                <input
                    className="form-check-input"
                    type="radio"
                    name="publishTime"
                    id="flexRadioDefaultHour"
                    value="hour"
                    onChange={(e) => handlePublishTimeChange('publishTime', e.target.value)}
                    checked={publishTime === 'hour'}
                />
                <label className="form-check-label ml-3" htmlFor="flexRadioDefaultHour">
                    Hour
                </label>
            </div>
        </div>
        <label className="mr-2">Interval</label>
        <input type="text" name="interval" value={interval} onChange={(e) => handlePublishTimeChange('interval', e.target.value)} />
    </div>
}
                                        </div>
                                    </>
                                }
                               
                                <br />
                                <label className="mt-4 mb-4">Choose Template</label>
                                <button type="button" className={`tab_btn ${articleLength === 'short' ? 'active' : ''}`} onClick={() => {
                                    setArticleLength('short')
                                    setShowErrorDiv(false)
                                }}>
                                    Short Form
                                </button>
                                {
                                    articleLength === 'short' &&
                                    <div className="tab_data">
                                        Cost: 1 Credit for each article- Article Length: 1500-2000 words
                                    </div>
                                }
                                <button type="button" className={`tab_btn ${articleLength === 'long' ? 'active' : ''}`} onClick={() => {
                                    setArticleLength('long')
                                    setShowErrorDiv(false)
                                }}>
                                    Long Form
                                </button>
                                {
                                    articleLength === 'long' &&
                                    <div className="tab_data">
                                        Cost: 2 Credits for each article - Article Length: 2000-3000 words
                                    </div>
                                }
                              
                                {tabShowLongPro ? (
                                    <>
                                        <button
                                            type="button"
                                            className={`tab_btn ${articleLength === "long_pro" ? "active" : ""
                                                }`}
                                            onClick={() => setArticleLength("long_pro")}
                                        >
                                            Long Form Pro
                                        </button>
                                        {articleLength === "long_pro" && (
                                            <div className="tab_data">
                                                Cost: 2 Credits for each article- Article Length: 5000-7000 words
                                            </div>
                                        )}
                                    </>
                                ) : (
                                    <>
                                        <button
                                            type="button"
                                            className={`tab_btn ${articleLength === "long_pro" ? "active" : ""
                                                }`}
                                            onClick={() => {
                                                setArticleLength('')
                                                setShowErrorDiv(!showErrorDiv)
                                                !showErrorDiv && toast.info(
                                                    "You are not subscribed to monthly or yearly plan."
                                                )
                                            }
                                            }
                                        >
                                            Long Form Pro
                                        </button>
                                        {
                                            showErrorDiv &&
                                            <div className="tab_data_red">
                                                Feature only available to monthly/yearly subscription users.
                                                <br />
                                                Cost: 2 Credits - Article Length: 5000-7000 words
                                            </div>
                                        }
                                    </>
                                )}
                                
                            </form>
                            <div className="generate_title_btn" style={{ cursor: 'pointer' }} onClick={handleNextButton}>Next</div>
                        </>
                    }
                    {
                        generateArticles &&
                        <>
                            <form className="headline_form article_sec">
                                <div className="progress_blk">
                                    <img src={bulkProgress2} alt="" />
                                </div>
                                <hr className="mb-5" />
                                <div className="article_blk_outer">
                                    <div className="article_card">
                                        <div className="article_tag">Article {currentForm}</div>
                                        <label className="mb-4">Headline/Topic</label>
                                        <div className="input_blk">
                                            <input type="text" placeholder="e.g Malaysia Travel Plan" value={title} onChange={handleTitle} />
                                        </div>
                                        {titleError && <p className="mb-4" style={{ color: 'red' }}>{titleError}</p>}
                                        <label className="mb-1">Enter SEO Keywords (Max 10)</label>
                                        <span>Add one keyword & press enter for adding next</span>
                                        <div className="input_blk">
                                            <input type="text"
                                                onKeyDown={(event) => handleTags(event)}
                                                onChange={handleError}
                                                placeholder="Write some tag and press enter" />
                                        </div>
                                        <div className="mb-4" style={{ color: 'red' }}>{error}</div>
                                        <ul className="keyword">
                                            {tags?.map((tag, index) => (
                                                <li className="keyword_list" key={index}>
                                                    {tag}
                                                    <i
                                                        class="fa-solid fa-xmark mr-2"
                                                        onClick={() => removeTags(index)}
                                                    ></i>
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                </div>

                            </form>
                            <div className="btns_blk">
                                {
                                    currentForm === 1 ?
                                        <button type="button" className="re_generate_title_btn" onClick={handleGoBack}>
                                            Go back
                                        </button>
                                        :
                                        <button type="button" className="re_generate_title_btn" onClick={handlePrevious}>
                                            Previous
                                        </button>
                                }
                                {
                                    currentForm < Number(values.numberOfArticles) ?
                                        <button type="button" className="generate_title_btn" onClick={handleNext}>
                                            Next
                                        </button>
                                        :
                                        <button type="button" className="generate_title_btn" onClick={handleGenerateCampaign}>
                                            Start Campaign
                                        </button>
                                }
                            </div>
                        </>
                    }
                </div>
            </section>
        </>
    );
};

export default CreateNewCampaign;
