import React, { useState } from "react";
import {
    useStripe,
    useElements,
    CardElement,
} from "@stripe/react-stripe-js";

import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import axios from "axios";
import api from "../../../../../config/api";
import { userExpiryDetails } from "../../../../../redux/actions/pricing";
import { DotLoader } from "react-spinners";
import { Redirect } from "react-router-dom/cjs/react-router-dom.min";
import routes from "../../../../../config/routes";

import checkicon from "../../../../../assets/images/checkicon.svg";
import '../pricingPlanListing.scss'

const ProYearPlan = () => {
    const [loader, setLoader] = useState(false);
    const [coupon, setCoupon] = useState('');

    const [redirect, setRedirect] = useState(false);

    const dispatch = useDispatch();

    const token = useSelector((state) => state?.Authentication?.token);

    const user =
        useSelector((state) => state?.Authentication?.user) ||
        localStorage.getItem("User");
    const { email, name, _id: userId, role } = JSON.parse(user);

    const stripe = useStripe();
    const elements = useElements();

    const createSusbcription = async (e) => {
        setLoader(true)
        e.preventDefault();
        try {
            const paymentMethod = await stripe?.createPaymentMethod({
                type: "card",
                card: elements?.getElement(CardElement),
                billing_details: {
                    name,
                    email,
                },
            });

            if (paymentMethod?.error) {
                setLoader(false)
                toast.error(`Error: ${paymentMethod?.error?.message}`);
            } else {
                let priceId = process.env.REACT_APP_PRO_YEARLY_PRICE_ID;

                if (coupon) {
                    const payload = {
                        paymentMethod: paymentMethod?.paymentMethod?.id,
                        name: name,
                        email: email,
                        priceId,
                        userId: userId,
                        subscriptionPlan: "Pro Yearly",
                        couponCode: coupon,
                    };

                    await axios.post(api.pricing.createSubscription, payload)
                        .then((res) => {
                            dispatch(userExpiryDetails(userId))
                            toast.success('Success!!!')
                        })
                        .catch((err) => {
                            console.log({ err })
                            toast.error(`${err?.response?.data?.message}`)
                        })
                    setRedirect(true);
                    setLoader(false);

                } else {
                    const payload = {
                        paymentMethod: paymentMethod?.paymentMethod?.id,
                        name: name,
                        email: email,
                        priceId,
                        userId: userId,
                        subscriptionPlan: "Pro Yearly",
                    };

                    await axios.post(api.pricing.createSubscription, payload)
                        .then((res) => {
                            dispatch(userExpiryDetails(userId))
                            toast.success('Success!!!')
                        })
                        .catch((err) => {
                            console.log({ err })
                            toast.error(`${err?.response?.data?.message}`)
                        })
                    setRedirect(true);
                    setLoader(false);
                }
            }
        } catch (error) {
            setLoader(false)
            toast.error("Error! Try again later..");
            console.log(error);
        }
    };

    if (user === undefined || token === undefined) {
        return <Redirect to={routes.auth.logIn} />
    }

    if (redirect) {
        return <Redirect to={routes.panel.pricing} />
    }

    if (role === 'Team Member') {
        return <Redirect to={routes.panel.dashboard} />
    }

    return (
        <>
            <section id='pricing-plan-listing'>
                <div className="row">
                    <div className="col-lg-6 col-md-6 col-sm-12 col-12">
                        <div className="pricing_card">
                            <h3>Pro </h3>
                            <h3>
                                $599<span>/year</span>
                            </h3>
                            <p>
                                All the AI-features you need for outranking your competitors.
                            </p>
                            <hr />
                            <ul>
                                <li>
                                    <div className="icon_blk">
                                        <img src={checkicon} alt="" />
                                    </div>
                                    300 Articles
                                </li>
                                <li>
                                    <div className="icon_blk">
                                        <img src={checkicon} alt="" />
                                    </div>
                                    Text Editor
                                </li>
                                <li>
                                    <div className="icon_blk">
                                        <img src={checkicon} alt="" />
                                    </div>
                                    Auto SEO Optimization
                                </li>
                                <li>
                                    <div className="icon_blk">
                                        <img src={checkicon} alt="" />
                                    </div>
                                    10+ Output Styles
                                </li>
                                <li>
                                    <div className="icon_blk">
                                        <img src={checkicon} alt="" />
                                    </div>
                                    20+ Languages
                                </li>
                                <li>
                                    <div className="icon_blk">
                                        <img src={checkicon} alt="" />
                                    </div>
                                    Bulk Generation
                                </li>
                                <li>
                                    <div className="icon_blk">
                                        <img src={checkicon} alt="" />
                                    </div>
                                    Zapier Automation
                                </li>
                                <li>
                                    <div className="icon_blk">
                                        <img src={checkicon} alt="" />
                                    </div>
                                    In-Articles Images
                                </li>
                                <li>
                                    <div className="icon_blk">
                                        <img src={checkicon} alt="" />
                                    </div>
                                    Grammar Checker
                                </li>
                                <li>
                                    <div className="icon_blk">
                                        <img src={checkicon} alt="" />
                                    </div>
                                    API Access
                                </li>
                                <li>
                                    <div className="icon_blk">
                                        <img src={checkicon} alt="" />
                                    </div>
                                    Add 1 Member
                                </li>
                                <li>
                                    <div className="icon_blk">
                                        <img src={checkicon} alt="" />
                                    </div>
                                    SERP Analyzer (soon)
                                </li>
                                <li>
                                    <div className="icon_blk">
                                        <img src={checkicon} alt="" />
                                    </div>
                                    SEO Analyzer (soon)
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12 col-12">
                        <div className="DemoWrapper" id="section">
                            <div className="DemoPickerWrapper">
                                <form
                                    onSubmit={createSusbcription}
                                    className="stripe-form-pricing"
                                >
                                    <label>Name</label>
                                    <input
                                        className="stripe_input"
                                        placeholder="Name"
                                        type="text"
                                        value={name}
                                        disabled
                                    />
                                    <label>Email</label>
                                    <input
                                        className="stripe_input"
                                        placeholder="Email"
                                        type="text"
                                        value={email}
                                        disabled
                                    />
                                    <label>Coupon (if you have any)</label>
                                    <input
                                        className="stripe_input"
                                        placeholder="Enter your Coupon code"
                                        type="text"
                                        value={coupon}
                                        onChange={(e) => setCoupon(e.target.value)}
                                    />
                                    <label>Card Details</label>
                                    <CardElement />
                                    <br />
                                    {loader ? (
                                        <div className="loaderWrapper">
                                            <DotLoader
                                                className="login-clip-loader"
                                                color={"#CC34E4"}
                                            />
                                            <h1
                                                style={{
                                                    color: "#CC34E4",
                                                    fontSize: "2.4rem",
                                                }}
                                            >
                                                &nbsp;&nbsp;&nbsp;&nbsp;Subscribing you...
                                            </h1>
                                        </div>
                                    ) : (
                                        <button
                                            type="submit"
                                            className="stripe_btn"
                                        >
                                            Pay
                                        </button>
                                    )}
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default ProYearPlan;
