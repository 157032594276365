import React, { useCallback, useState } from "react";
import "../WizardPage/WizardPage.scss";
import { useDispatch, useSelector } from "react-redux";
import {
	generateArticle,
	generateLongArticle,
	generateTitleOutline,
} from "../../../redux/actions/panel";
import { Redirect } from "react-router";
import routes from "../../../config/routes";
import { DotLoader } from "react-spinners";
import { toast } from "react-toastify";
import progress2 from "../../../assets/images/progress2.jpg";

const TitleOutline = () => {
	const dispatch = useDispatch();
	const token = useSelector((state) => state?.Authentication?.token);
	const [selectedOutline, setSelelectedOutline] = useState("");
	const [error, setError] = useState("");
	const [redirect, setRedirect] = useState(false);
	let keyWords = JSON.parse(localStorage.getItem("Keywords"));
	let selectedTitle = localStorage.getItem("Selected-Fancy-Title");
	let title = localStorage.getItem("Title");
	const language = localStorage?.getItem("Language");

	const articleLoader = useSelector((state) => state?.PanelData?.articleLoader);

	const outlineData = useSelector((state) => state?.PanelData?.titleOutline);
	const panelLoader = useSelector((state) => state?.PanelData?.panelLoader);

	const user = useSelector((state) => state?.Authentication?.user)
	const { _id: userId, role } = JSON.parse(user);

	const handleSelectedOutline = (e, value) => {
		setError("");
		setSelelectedOutline(value?.outline);
	};

	const handleGenerateArticle = (e) => {
		e.preventDefault();
	  
		if (selectedOutline === "") {
		  toast.error("Please select an outline to generate an article.");
		  setError("Please select an outline to generate an article.");
		  return;
		}
	  
		const stringKeywords = keyWords?.join(",");
		localStorage?.setItem("Selected-Outline", selectedOutline);
		setRedirect(true);
	  
		const parentUserId = role === "Team Member" ? JSON.parse(user)?.parentUserId : null;
	  
		const payload = {
		  outline: selectedOutline,
		  headline: selectedTitle,
		  topic: title,
		  keywords: stringKeywords,
		  userId: role === "Team Member" ? parentUserId : userId,
		  language: language,
		  articleType: "short",
		};
	  
		const articleLengthToParse = localStorage.getItem("article-length");
		const articleLength = articleLengthToParse ? JSON.parse(articleLengthToParse) : articleLengthToParse;
	  
		if (articleLength === "long" || articleLength === "longPro") {
		  payload.articleType = articleLength;
		  dispatch(generateLongArticle(payload));
		} else {
		  dispatch(generateArticle(payload));
		}
	  };
	  

	const handleGenerateAnotherOutline = (e) => {
		setRedirect(false);
		e.preventDefault();
		
		let stringKeywords = keyWords?.join(",");
		const payload = {
			topic: selectedTitle,
			keywords: stringKeywords,
			language: language,
		};
		dispatch(generateTitleOutline(payload));
	};

	const itemData = useCallback(
		(item) => {
			return item?.replace(/^\d+\.\s/, "");
		},
		[outlineData]
	);

	if (redirect && articleLoader) {
		return <Redirect to={routes.panel.wizardEditor} />;
	}

	if (outlineData?.length === 0) {
		return <Redirect to={routes.panel.fancyTitles} />;
	}

	if (user === undefined || token === undefined) {
		return <Redirect to={routes.auth.logIn} />;
	}

	return (
		<>
			<section id="WizardPage">
				<h2>Wizard</h2>

				<div className="inner">
					<h3>Generate SEO Optimized Article</h3>
					<p>Please fill the form below to receive a well optimized article</p>
					<form className="headline_form">
						<div className="progress_blk">
							<img src={progress2} alt="progress 2" />
						</div>
						<hr />
						<label className="mb-4">Select Headline</label>
						{error && <div className="error-message mb-4">{error}</div>}
						{outlineData?.map((item, ind) => {
							return (
								<>
									<div key={item} className={`outline_head ${ind > 0 ? "mt-4" : ""}`}>
										Outline {ind + 1}
										<input
											type="radio"
											name="selected"
											id={item}
											onChange={(e) => handleSelectedOutline(e, item)}
										/>
										
									</div>
									<div key={ind} className="outline_box">
										<div className="outline_box_inner"></div>
										<ul className="outline_list">
											{item?.outline?.split("\n").map((items, index) => {
												return (
													<>
														<li key={index}>
															<span className="count">{index + 1}.&nbsp;</span>
															<p>{itemData(items)}</p>
														</li>
													</>
												);
											})}
										</ul>
									</div>
								</>
							);
						})}
					</form>
					{panelLoader ? (
						<div className="loaderWrapper">
							<DotLoader className="login-clip-loader" color={"#7F56D9"} />
							<h1
								style={{
									color: "#7F56D9",
									fontSize: "2.4rem",
								}}
							>
								&nbsp;&nbsp;&nbsp;&nbsp;
								{redirect
									? "Creating your article. Please wait a little..."
									: "Generating another outline..."}
							</h1>
						</div>
					) : (
						<div className="btns_blk">
							<button
								type="button"
								className="re_generate_title_btn"
								onClick={handleGenerateAnotherOutline}
							>
								Generate another outline
							</button>
							<button
								type="button"
								className="generate_title_btn"
								onClick={handleGenerateArticle}
								style={{ color: "white" }}
								// disabled={disbaled}
							>
								Generate Article
							</button>
						</div>
					)}
				</div>
			</section>
		</>
	);
};

export default TitleOutline;
