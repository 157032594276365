import { toast } from "react-toastify";
import * as api from "../../api/api";
import { auth, panelData } from "../type";

export const setUserDetails = (payload) => ({
	type: auth.SET_USER_DETAILS,
	payload,
});

export const setPanelTitle = (payload) => ({
	type: panelData.SET_TITLE,
	payload,
});

export const setFancyTitle = (payload) => ({
	type: panelData.SET_FANCY_TITLES,
	payload,
});

export const setFancyTitlesLoader = (payload) => ({
	type: panelData.SET_FANCY_TITLE_LOADER,
	payload,
});

export const setKeywords = (payload) => ({
	type: panelData.SET_KEYWORDS,
	payload,
});

export const setTitleOutline = (payload) => ({
	type: panelData.SET_TITLE_OUTLINE,
	payload,
});

export const setArticle = (payload) => ({
	type: panelData.SET_ARTICLE,
	payload,
});

export const setSaveArticle = (payload) => ({
	type: panelData.SET_SAVE_ARTICLE,
	payload,
});

export const setSaveArticleForUser = (payload) => ({
	type: panelData.SET_SAVE_ARTICLE_FOR_USER,
	payload,
});

export const setUserArticles = (payload) => ({
	type: panelData.SET_USER_ARTICLES,
	payload,
});

export const setOutlineLoader = (payload) => ({
	type: panelData.SET_OUTLINE_CREATE_LOADER,
	payload,
});

export const setArticleLoader = (payload) => ({
	type: panelData.SET_ARTICLE_CREATE_LOADER,
	payload,
});

export const setArticleById = (payload) => ({
	type: panelData.SET_ARTICLE_BY_ID,
	payload,
});

export const setArticleByIdLoader = (payload) => ({
	type: panelData.SET_ARTICLE_BY_ID_LOADER,
	payload,
});

export const setArticleSeoScore = (payload) => ({
	type: panelData.SET_ARTICLE_SEO_SCORE,
	payload,
});

export const setPanelLoader = (payload) => ({
	type: panelData.SET_PANEL_LOADER,
	payload,
});

export const setViewArticleByIdLoader = (payload) => ({
	type: panelData.SET_VIEW_ARTICLE_BY_ID_LOADER,
	payload,
});

export const setArticleSummary = (payload) => ({
	type: panelData.SET_ARTICLE_SUMMARY,
	payload,
});

export const setTeamMemberList = (payload) => ({
	type: panelData.SET_TEAM_MEMBER_LIST,
	payload,
});

export const generateFancyHeadline = (data) => (dispatch, getState) => {
	dispatch(setPanelLoader(true));
	dispatch(setFancyTitlesLoader(false));
	const state = getState();
	const token = state?.Authentication?.token;
	const config = {
		headers: {
			Authorization: `Bearer ${token}`,
		},
	};

	api
		.generateFancyHeadlines(data, config)
		// .generateFancyHeadlines(title, config)
		.then((response) => {
			dispatch(setFancyTitle(response?.data));
			dispatch(setFancyTitlesLoader(true));
			dispatch(setPanelLoader(false));
			localStorage.setItem("123", JSON.stringify(response?.data));
			localStorage.setItem("Fancy-Headline", JSON.stringify(response?.data));
		})
		.catch((err) => {
			dispatch(setPanelLoader(false));
			dispatch(setFancyTitlesLoader(false));
			console.log({ err });
		});
};

export const generateTitleOutline = (data) => (dispatch, getState) => {
	dispatch(setPanelLoader(true));
	dispatch(setOutlineLoader(false));
	const state = getState();
	const token = state?.Authentication?.token;
	const outlineData = state?.PanelData?.titleOutline;
	const config = {
		headers: {
			Authorization: `Bearer ${token}`,
		},
	};

	api
		.generateTitleOutlines(data, config)
		.then((response) => {
			dispatch(setOutlineLoader(true));
			dispatch(setTitleOutline([...outlineData, response?.data]));
			dispatch(setPanelLoader(false));
		})
		.catch((err) => {
			dispatch(setPanelLoader(false));
			dispatch(setOutlineLoader(false));
			console.log({ err });
		});
};

export const getArticleSeo = (data) => (dispatch, getState) => {
	dispatch(setPanelLoader(true));
	dispatch(setViewArticleByIdLoader(false));
	dispatch(setArticleByIdLoader(true));
	const state = getState();
	const token = state?.Authentication?.token;
	const config = {
		headers: {
			Authorization: `Bearer ${token}`,
		},
	};

	api
		.getSeoScore(data, config)
		.then((response) => {
			dispatch(setPanelLoader(false));
			dispatch(setArticleByIdLoader(false));
			dispatch(setViewArticleByIdLoader(true));
			localStorage.setItem("Article-SEO", JSON.stringify(response?.data));
			dispatch(setArticleSeoScore(response?.data));
		})
		.catch((err) => {
			dispatch(setPanelLoader(false));
			console.log({ err });
		});
};

export const generateArticle = (data) => (dispatch, getState) => {
	dispatch(setArticleLoader(false));
	dispatch(setPanelLoader(true));
	const state = getState();
	const token = state?.Authentication?.token;
	const outlineData = state?.PanelData?.titleOutline;
	const config = {
		headers: {
			Authorization: `Bearer ${token}`,
		},
	};

	api
		.generateArticle(data, config)
		.then((response) => {
			dispatch(setArticleLoader(true));
			localStorage?.setItem(
				"Article-Generated",
				JSON.stringify(response?.data)
			);
			const payload = {
				outline: data?.outline,
				headline: data?.headline,
				language: data?.language,
			};
			const seoPayload = {
				title: response?.data?.article?.introduction,
				metaDescription: response?.data?.article?.metaDescription,
				article: response?.data?.article?.conclusion,
			};
			dispatch(getArticleSeo(seoPayload));
			dispatch(getArticleSummary(payload));
			dispatch(setArticle(response?.data));
			dispatch(setPanelLoader(false));
		})
		.catch((err) => {
			toast.error(`Sorry couldn't create article. Try again.`);
			dispatch(setPanelLoader(false));
			dispatch(setArticleLoader(false));
			console.log({ err });
		});
};

export const generateLongArticle = (data) => (dispatch, getState) => {
	dispatch(setArticleLoader(false));
	dispatch(setPanelLoader(true));
	const state = getState();
	const token = state?.Authentication?.token;
	const config = {
		headers: {
			Authorization: `Bearer ${token}`,
		},
	};

	api
		.generateLongArticle(data, config)
		.then((response) => {
			dispatch(setArticleLoader(true));
			localStorage?.setItem(
				"Article-Generated",
				JSON.stringify(response?.data)
			);
			const payload = {
				outline: data?.outline,
				headline: data?.headline,
				language: data?.language,
			};
			const seoPayload = {
				title: response?.data?.article?.introduction,
				metaDescription: response?.data?.article?.metaDescription,
				article: response?.data?.article?.conclusion,
			};
			dispatch(getArticleSeo(seoPayload));
			dispatch(getArticleSummary(payload));
			dispatch(setArticle(response?.data));
			dispatch(setPanelLoader(false));
		})
		.catch((err) => {
			toast.error(`Sorry couldn't create article. Try again.`);
			dispatch(setPanelLoader(false));
			dispatch(setArticleLoader(false));
			console.log({ err });
		});
};

export const saveArticleData = (data, payload) => (dispatch, getState) => {
	const state = getState();
	const token = state?.Authentication?.token;
	const config = {
		headers: {
			Authorization: `Bearer ${token}`,
		},
	};

	api
		.saveArticleData(data)
		.then((response) => {
			let x = true;
			const articleType = JSON.parse(localStorage.getItem("article-length"));
			const res = {
				dataTitle: payload?.dataTitle,
				dataId: response?.data?._id,
				userId: payload?.userId,
				articleType: articleType,
			};
			localStorage?.setItem("Article-Generate-Check", x);
			dispatch(setSaveArticle(response?.data));
			localStorage?.setItem("Saved-Article", JSON.stringify(response?.data));

			dispatch(saveArticleForUser(res));
		})
		.catch((err) => {
			console.log({ err });
		});
};

export const saveArticleForUser = (data) => (dispatch, getState) => {
	const state = getState();
	const token = state?.Authentication?.token;
	const config = {
		headers: {
			Authorization: `Bearer ${token}`,
		},
	};

	api
		.saveArticleForUser(data)
		.then((response) => {})
		.catch((err) => {
			console.log({ err });
		});
};

export const getUserArticles = (userId) => (dispatch, getState) => {
	dispatch(setPanelLoader(true));
	const state = getState();
	const token = state?.Authentication?.token;
	const config = {
		headers: {
			Authorization: `Bearer ${token}`,
		},
	};

	api
		.getUserArticles(userId, config)
		.then((response) => {
			dispatch(setPanelLoader(false));
			dispatch(setUserArticles(response?.data));
		})
		.catch((err) => {
			dispatch(setPanelLoader(false));
			console.log({ err });
		});
};

export const getArticleById = (id) => (dispatch, getState) => {
	dispatch(setPanelLoader(true));
	dispatch(setViewArticleByIdLoader(false));
	dispatch(setArticleByIdLoader(true));
	const state = getState();
	const token = state?.Authentication?.token;
	const config = {
		headers: {
			Authorization: `Bearer ${token}`,
		},
	};

	api
		.getUserArticleById(id)
		.then((response) => {
			const seoPayload = {
				title: response?.data?.dataTitle,
				metaDescription: response?.data?.metaDescription,
				article: response?.data?.data,
			};
			dispatch(setPanelLoader(false));
			dispatch(setArticleByIdLoader(false));
			dispatch(setViewArticleByIdLoader(true));
			dispatch(setArticleById(response?.data));
			dispatch(getArticleSeo(seoPayload));
			localStorage.setItem("Article-By-Id", JSON.stringify(response?.data));
		})
		.catch((err) => {
			dispatch(setPanelLoader(false));
			dispatch(setViewArticleByIdLoader(false));
			dispatch(setArticleByIdLoader(false));
			console.log({ err });
		});
};

export const saveEditedArticle = (id, payload) => (dispatch, getState) => {
	const state = getState();
	const token = state?.Authentication?.token;
	const config = {
		headers: {
			Authorization: `Bearer ${token}`,
		},
	};

	api
		.saveEditedArticle(id, payload)
		.then((response) => {
			localStorage.setItem("Article-By-Id", JSON.stringify(response?.data));
			localStorage.setItem("Saved-Article", JSON.stringify(response?.data));
		})
		.catch((err) => {
			console.log({ err });
		});
};

export const getArticleSummary = (data) => (dispatch, getState) => {
	const state = getState();
	const token = state?.Authentication?.token;
	const config = {
		headers: {
			Authorization: `Bearer ${token}`,
		},
	};
	api
		.generateArticleSummary(data, config)
		.then((response) => {
			dispatch(setArticleSummary(response?.data?.summary));
		})
		.catch((err) => {
			console.log({ err });
		});
};

export const addNewTeamMember = (email, username) => (dispatch, getState) => {
	dispatch(setPanelLoader(true));
	const state = getState();
	const token = state?.Authentication?.token;
	const config = {
		headers: {
			Authorization: `Bearer ${token}`,
		},
	};
	api
		.addTeamMember(email, username, {}, config)
		.then((response) => {
			toast.success("Mail sent to member.");
			dispatch(setPanelLoader(false));
		})
		.catch((err) => {
			dispatch(setPanelLoader(false));
			console.log({ err });
		});
};

export const viewTeamMembersList = () => (dispatch, getState) => {
	dispatch(setPanelLoader(true));
	const state = getState();
	const token = state?.Authentication?.token;
	const config = {
		headers: {
			Authorization: `Bearer ${token}`,
		},
	};
	api
		.viewTeamMembers(config)
		.then((response) => {
			dispatch(setTeamMemberList(response?.data));
			dispatch(setPanelLoader(false));
		})
		.catch((err) => {
			dispatch(setPanelLoader(false));
		});
};

export const deleteTeamMember = (teamMemberId) => (dispatch, getState) => {
	dispatch(setPanelLoader(true));
	const state = getState();
	const token = state?.Authentication?.token;
	const config = {
		headers: {
			Authorization: `Bearer ${token}`,
		},
	};
	api
		.deleteTeamMember(teamMemberId, config)
		.then((response) => {
			dispatch(viewTeamMembersList(config));
			dispatch(setPanelLoader(false));
		})
		.catch((err) => {
			dispatch(setPanelLoader(false));
		});
};

export const updateName = (userId, payload) => (dispatch, getState) => {
	dispatch(setPanelLoader(true));
	const state = getState();
	const token = state?.Authentication?.token;
	const config = {
		headers: {
			Authorization: `Bearer ${token}`,
		},
	};

	api
		.updateName(userId, payload, config)
		.then((response) => {
			localStorage.setItem("User", JSON.stringify(response?.data));
			dispatch(setUserDetails(JSON.stringify(response?.data)));
			dispatch(setPanelLoader(false));
			toast.success("Name updated successfully.");
		})
		.catch((err) => {
			dispatch(setPanelLoader(false));
			console.log({ err });
		});
};
