import React, { useState } from "react";
import "./articleEditor.scss";
import { ContentState, EditorState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import { convertFromHTML } from "draft-js";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { useDispatch, useSelector } from "react-redux";
import routes from "../../../config/routes";
import { Redirect } from "react-router";
import { useEffect } from "react";
import { stateToHTML } from "draft-js-export-html";
import {
	generateArticle,
	generateLongArticle,
	saveArticleData,
	saveEditedArticle,
} from "../../../redux/actions/panel";
import { userExpiryDetails } from "../../../redux/actions/pricing";
import { DotLoader } from "react-spinners";
import { toast } from "react-toastify";
import axios from "axios";
import Button from "react-bootstrap/Button";
import { FaRegCopy } from "react-icons/fa6";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import ReactSpeedometer from "react-d3-speedometer/slim";
const ArticleEditor = () => {
	const dispatch = useDispatch();
	const [openAddImages, setOpenAddImages] = useState(false);
	const [imagesData, setImagesData] = useState(null);
	const [imageSearch, setImageSearch] = useState("technology");
	const [loaderForImages, setLoaderForImages] = useState(false);
	let keyWords = JSON.parse(localStorage.getItem("Keywords"));
	const language = localStorage?.getItem("Language");
	let title = localStorage.getItem("Title");
	let selectedTitle = localStorage.getItem("Selected-Fancy-Title");
	const [goBackToWizard, setGoBackToWizard] = useState(false);
	const [generatedArticle, setGeneratedArticle] = useState(null);
	const [articleDelay, setArticleDelay] = useState(false);

	useEffect(() => {
		setArticleDelay(true);
		setTimeout(() => {
			setArticleDelay(false);
		}, 5000);
	}, []);

	const articleLengthToParse = localStorage.getItem("article-length");
	const articleLength = articleLengthToParse
		? JSON.parse(articleLengthToParse)
		: articleLengthToParse;

	const panelLoader = useSelector((state) => state?.PanelData?.panelLoader);

	const articleSummary = useSelector(
		(state) => state?.PanelData?.articleSummary
	);

	const [value, setValue] = useState(() => EditorState.createEmpty());

	const generatedArticles =
		useSelector((state) => state?.PanelData?.article) ||
		localStorage.getItem("Article-Generated");

	useEffect(() => {
		if (!keyWords || !title || !language || !selectedTitle) {
			setGoBackToWizard(true);
		} else {
			setGoBackToWizard(false);
			let generatedArticleData = generatedArticles?.article
				? generatedArticles
				: JSON.parse(generatedArticles);
			setGeneratedArticle(generatedArticleData);
		}
		localStorage.removeItem("refreshed");
	}, [generatedArticles]);

	const user =
		useSelector((state) => state?.Authentication?.user) ||
		localStorage.getItem("User");

	const userId = JSON.parse(user)?._id;
	const role = JSON.parse(user)?.role;

	const token = useSelector((state) => state?.Authentication?.token);

	useEffect(() => {
		if (role === "Team Member") {
			const parentUserId = JSON.parse(user)?.parentUserId;
			dispatch(userExpiryDetails(parentUserId));
		}
		if (role !== "Team Member") {
			dispatch(userExpiryDetails(userId));
		}
	}, [user]);

	const contentState = value?.getCurrentContent();
	const contentHTML = stateToHTML(contentState);

	const fancyTitle = localStorage?.getItem("Selected-Fancy-Title");

	const articleSaved =
		useSelector((state) => state?.PanelData?.saveArticle) ||
		localStorage.getItem("Saved-Article");

	const apiCalled = localStorage?.getItem("Article-Generate-Check");

	let articleData;

	if (apiCalled) {
		articleData = articleSaved?._id ? articleSaved : JSON.parse(articleSaved);
	}

	const seo = localStorage.getItem("Article-SEO");

	let articleSeoData = null;
	try {
		articleSeoData = seo ? JSON.parse(seo) : null;
	} catch (e) {
		console.error("Parsing error:", e);
	}

	const [articleEditedTitle, setArticleEditedArticle] = useState("");
	const [articleMetaDescription, setArticleMetaDescription] = useState("");

	useEffect(() => {
		if (articleLength === "short") {
			let removed = [
				...new Set(
					generatedArticle?.article?.explainedHeadings
						?.replaceAll(":", "\n")
						?.split("\n")
				),
			];

			removed = removed.filter((item) => item !== "");

			removed = removed?.map((str) => {
				if (str?.length < 150) {
					return `<h3>${str}</h3>\n`;
				} else {
					return `<p>${str}<p>\n`;
				}
			});

			const explanation = removed?.join("");

			if (!apiCalled) {
				setValue(() =>
					EditorState.createWithContent(
						ContentState.createFromBlockArray(
							convertFromHTML(`<h1>Introduction:</h1><br/><br/><p>${generatedArticle?.article?.introduction}</p><br/>
              <br/>
              ${explanation}
            <br/>
            <h1>Conclusion:</h1>
            <p>${generatedArticle?.article?.conclusion}</p><br/>
            `)
						)
					)
				);
			} else if (articleSummary) {
				setValue(() =>
					EditorState.createWithContent(
						ContentState.createFromBlockArray(
							convertFromHTML(`<h1>Introduction:</h1><br/><br/><p>${generatedArticle?.article?.introduction}</p><br/>
            <br/>
            ${explanation}
          <br/>
          <h1>Conclusion:</h1>
          <p>${generatedArticle?.article?.conclusion}</p><br/>
          <h1>Summary</h1><br/>
          <p>${articleSummary}</p><br/>
          `)
						)
					)
				);
			} else {
				if (articleData?.data !== "<p><br></p>") {
					setArticleEditedArticle(
						articleSaved?._id ? articleSaved?.dataTitle : fancyTitle
					);
					setArticleMetaDescription(
						articleSaved?._id
							? articleSaved?.metaDescription
							: generatedArticle?.article?.metaDescription
					);
					setValue(() =>
						EditorState.createWithContent(
							ContentState.createFromBlockArray(
								convertFromHTML(`${articleData?.data}`)
							)
						)
					);
				}
			}
		} else {
			if (!apiCalled) {
				setValue(() =>
					EditorState.createWithContent(
						ContentState.createFromBlockArray(
							convertFromHTML(`<h1>Introduction:</h1><br/><br/><p>${generatedArticle?.article?.introduction}</p><br/>
              <br/>
              ${generatedArticle?.article?.explainedHeadings}
            <br/>
            <h1>Conclusion:</h1>
            <p>${generatedArticle?.article?.conclusion}</p><br/>
            `)
						)
					)
				);
			} else if (articleSummary) {
				setValue(() =>
					EditorState.createWithContent(
						ContentState.createFromBlockArray(
							convertFromHTML(`<h1>Introduction:</h1><br/><br/><p>${generatedArticle?.article?.introduction}</p><br/>
            <br/>
            ${generatedArticle?.article?.explainedHeadings}
          <br/>
          <h1>Conclusion:</h1>
          <p>${generatedArticle?.article?.conclusion}</p><br/>
          <h1>Summary</h1><br/>
          <p>${articleSummary}</p>
          <br/>
          `)
						)
					)
				);
			} else {
				if (articleData?.data !== "<p><br></p>") {
					setArticleEditedArticle(
						articleSaved?._id ? articleSaved?.dataTitle : fancyTitle
					);
					setArticleMetaDescription(
						articleSaved?._id
							? articleSaved?.metaDescription
							: generatedArticle?.article?.metaDescription
					);
					setValue(() =>
						EditorState.createWithContent(
							ContentState.createFromBlockArray(
								convertFromHTML(`${articleData?.data}`)
							)
						)
					);
				}
			}
		}
	}, [generatedArticles, articleData?.data, articleSummary]);

	useEffect(() => {
		setArticleEditedArticle(
			articleSaved?._id ? articleSaved?.dataTitle : fancyTitle
		);
		setArticleMetaDescription(
			articleSaved?._id
				? articleSaved?.metaDescription
				: generatedArticle?.article?.metaDescription
		);
	}, [generatedArticle]);

	useEffect(() => {
		let savedArticleId = "";
		if (!keyWords || !title || !language || !selectedTitle) {
			setGoBackToWizard(true);
		} else {
			if (apiCalled) {
				savedArticleId = articleSaved?._id
					? articleSaved?._id
					: JSON.parse(articleSaved)?._id;

				const payload = {
					data: contentHTML,
					dataTitle: articleEditedTitle ? articleEditedTitle : fancyTitle,
					metaDescription: articleMetaDescription
						? articleMetaDescription
						: generatedArticle?.article?.metaDescription,
				};
				dispatch(saveEditedArticle(savedArticleId, payload));
			}
		}
	}, [contentHTML, articleEditedTitle, articleMetaDescription, apiCalled]);

	useEffect(() => {
		if (!keyWords || !title || !language || !selectedTitle) {
			setGoBackToWizard(true);
		} else {
			if (!apiCalled) {
				if (contentHTML !== "<p><br></p>") {
					const data = {
						dataType: "Article",
						dataTitle: fancyTitle,
						data: contentHTML,
						metaDescription: articleMetaDescription
							? articleMetaDescription
							: generatedArticle?.article?.metaDescription,
					};
					let payload;
					if (role === "Team Member") {
						const parentUserId = JSON.parse(user)?.parentUserId;
						payload = {
							dataTitle: fancyTitle,
							userId: parentUserId,
						};
					}
					if (role !== "Team Member") {
						payload = {
							dataTitle: fancyTitle,
							userId: userId,
						};
					}
					dispatch(saveArticleData(data, payload));
				}
			} else {
				console.log("Article Saved");
			}
		}
	}, [contentHTML]);

	const expiryDetail = useSelector((state) => state?.Pricing?.expiryDetail);
	const handleRegenerateArticle = () => {
		const stringKeywords = keyWords.join(",");
		const selectedOutline = localStorage.getItem("Selected-Outline");
		const parentUserId = role === "Team Member" ? JSON.parse(user)?.parentUserId : null;
		const remainingCredits = expiryDetail?.subscription?.noOfArticles || 0;
		const referredNoOfArticles = expiryDetail?.subscription?.referredNoOfArticles || 0;
		const creditsPerDay = expiryDetail?.subscription?.creditsPerDay || 0
		const requiredCredits = articleLength === "short" ? 1 : (articleLength === "long" || articleLength === "longPro" ? 2 : 0);
		const lessCredit = remainingCredits < requiredCredits && referredNoOfArticles < requiredCredits && creditsPerDay < requiredCredits;
	  
		if (lessCredit) {
		  toast.error("You do not have enough credits.");
		  return;
		}
	  
		const payload = {
		  outline: selectedOutline,
		  headline: selectedTitle,
		  topic: title,
		  keywords: stringKeywords,
		  userId: role === "Team Member" ? parentUserId : userId,
		  language: language,
		  articleType: "short",
		};
	  
		if (articleLength === "long" || articleLength === "longPro") {
		  payload.articleType = articleLength;
		}
	  
		if (articleLength === "short") {
		  dispatch(generateArticle(payload));
		} else {
		  dispatch(generateLongArticle(payload));
		}
	  };
	  

	const accessKeyUnsplash = "rQdDZ-pCCOSDeBNKyurLxCryT2kgfPkvtTzWiORqNB4";

	useEffect(() => {
		if (openAddImages) {
			setLoaderForImages(true);
			axios
				.get(
					`https://api.unsplash.com/search/photos?page=1&per_page=30&query=${imageSearch}&client_id=${accessKeyUnsplash}`
				)
				.then((response) => {
					setImagesData(response?.data?.results);
					setLoaderForImages(false);
				})
				.catch((err) => {
					setLoaderForImages(false);
					console.log({ err });
				});
		}
	}, [openAddImages]);

	const handleCopyImageUrl = (url) => {
		navigator.clipboard
			.writeText(url)
			.then(() => {
				toast.success("Image Url Copied!");
			})
			.catch((error) => {
				toast.error(`Didn't copy. Try Again.`);
			});
	};

	const handleSearchImages = (e) => {
		e.preventDefault();

		if (!imageSearch) {
			toast.error("Please type something to search.");
		} else {
			setLoaderForImages(true);
			axios
				.get(
					`https://api.unsplash.com/search/photos?page=1&per_page=30&query=${imageSearch}&client_id=${accessKeyUnsplash}`
				)
				.then((response) => {
					setImagesData(response?.data?.results);
					setLoaderForImages(false);
				})
				.catch((err) => {
					setLoaderForImages(false);
					console.log({ err });
				});
		}
	};

	// if (user === undefined || token === undefined) { //this will be removed
	// 	return <Redirect to={routes.auth.logIn} />;
	// }

	if (goBackToWizard) {
		return <Redirect to={routes.panel.wizard} />;
	}

	const downloadFile = (content, fileType) => {
		let blob;
		let fileName = "download";

		switch (fileType) {
			case "html":
				blob = new Blob([content], { type: "text/html" });
				fileName += ".html";
				break;
			case "txt":
				blob = new Blob([content], { type: "text/plain" });
				fileName += ".txt";
				break;
			default:
				break;
		}

		// For HTML (and potentially other types directly supported by Blob), proceed to create a download link.
		if (blob) {
			const url = URL.createObjectURL(blob);
			const link = document.createElement("a");
			link.href = url;
			link.download = fileName;
			document.body.appendChild(link);
			link.click();
			document.body.removeChild(link);
			URL.revokeObjectURL(url);
		}
	};

	return (
		<>
			<div className="row">
				<div className="add-image-button-wrapper">
					<button
						type="button"
						className="add_img"
						onClick={() => setOpenAddImages(true)}
					>
						Add Images
					</button>
				</div>
				{openAddImages && (
					<div className="popup">
						<div className="popup-card">
							<div className="popup-button-wrapper">
								<button
									className="popup-acceptButton"
									onClick={() => setOpenAddImages(false)}
								>
									Close
								</button>
							</div>
							<div className="popup-input-wrapper">
								<input
									placeholder="Search Images By Name"
									name="imageSearch"
									value={imageSearch}
									onChange={(e) => setImageSearch(e.target.value)}
								/>
								<div className="button-popup">
									<button
										className="popup-acceptButton-search"
										onClick={handleSearchImages}
									>
										Search
									</button>
								</div>
							</div>
							{loaderForImages ? (
								<div className="loaderWrapper-popup">
									<DotLoader color="#7F56D9" className="login-clip-loader" />
									<h1
										style={{
											color: "#7F56D9",
											fontSize: "2.4rem",
										}}
									>
										&nbsp;&nbsp;&nbsp;&nbsp;Loading your images...
									</h1>
								</div>
							) : (
								<div className="popup-images-container">
									<div className="row" style={{ justifyContent: "center" }}>
										{imagesData?.length === 0 ? (
											<div className="heading-wrapper-popup">
												<h1 style={{ color: "black", height: "100%" }}>
													Sorry No Data Available for the search.
												</h1>
											</div>
										) : (
											imagesData?.map((item) => {
												return (
													<div className="col-md-3" key={item?.id}>
														<div className="popup-image-wrapper">
															<img
																className="popup-image"
																src={item?.urls?.small_s3}
																alt={item?.alt_description}
															/>
														</div>
														<div className="popup-image-button">
															<button
																className="popup-acceptButton-search"
																onClick={() =>
																	handleCopyImageUrl(item?.urls?.small_s3)
																}
															>
																Copy URL
															</button>
														</div>
													</div>
												);
											})
										)}
									</div>
								</div>
							)}
							<p className="popup-cookieDescription">
								Copy the url and paste it in the editor where you want to use
								it. <br />
								<span className="popup-span">
									Recommended image dimensions <b>350x350</b>.
								</span>
							</p>
						</div>
					</div>
				)}
			</div>
			{articleDelay ? (
				<div className="loaderWrapper">
					<DotLoader className="login-clip-loader" color={"#7F56D9"} />
					<h1
						style={{
							color: "#7F56D9",
							fontSize: "2.4rem",
						}}
					>
						&nbsp;&nbsp;&nbsp;&nbsp;Please wait...
					</h1>
				</div>
			) : (
				<>
					{panelLoader ? (
						<div className="loaderWrapper">
							<DotLoader className="login-clip-loader" color={"#7F56D9"} />
							<h1
								style={{
									color: "#7F56D9",
									fontSize: "2.4rem",
								}}
							>
								&nbsp;&nbsp;&nbsp;&nbsp;Please wait...
							</h1>
						</div>
					) : (
						<div
							id="editor"
							style={{
								display: "grid",
								gridTemplateColumns: "3fr 1fr",
								width: "100%",
								gap: "30px",
							}}
						>
							<div
								style={{
									display: "flex",
									flexDirection: "column",
									gap: "10px",
									width: "100%",
								}}
							>
								<div
									className="d-flex align-items-start pt-4"
									style={{
										position: "sticky",
										top: 0,
										background: "white",
									}}
								>
									<textarea
										className="article-textarea-input align-items-center justify-content-between"
										style={{ position: "sticky", top: 0 }}
										value={articleEditedTitle}
										onChange={(e) => setArticleEditedArticle(e.target.value)}
									/>
									<div
										style={{
											display: "flex",
											alignItems: "center",
											gap: "6px",
											marginTop: "8px",
										}}
									>
										<Button
											variant="secondary"
											size="lg"
											style={{
												borderRadius: "20px",
												display: "flex",
												alignItems: "center",
												gap: "5px",
											}}
											onClick={() => {
												navigator.clipboard.writeText(
													`# ${articleEditedTitle}\n\n${articleMetaDescription}\n\n${contentHTML}`
												);
												toast.success("Article Copied!");
											}}
										>
											<FaRegCopy />
											Copy Article
										</Button>
										<DropdownButton
											id="dropdown-item-button"
											title="Download as"
											style={{
												borderRadius: "60px",
											}}
											size="lg"
											as={Button}
										>
											<Dropdown.Item
												as="button"
												style={{
													width: "100%",
													fontSize: "15px",
												}}
												onClick={() => {
													downloadFile(contentHTML, "html");
												}}
											>
												HTML File
											</Dropdown.Item>
											<Dropdown.Item
												as="button"
												style={{
													width: "100%",
													fontSize: "15px",
												}}
												onClick={() => {
													downloadFile(contentHTML, "txt");
												}}
											>
												Text File
											</Dropdown.Item>
										</DropdownButton>
									</div>
								</div>

								<span
									style={{
										color: "black",
										fontSize: "2.3rem",
										fontWeight: "bold",
									}}
								>
									Meta Description
								</span>
								<textarea
									className="meta-description"
									value={articleMetaDescription}
									onChange={(e) => setArticleMetaDescription(e.target.value)}
								/>
								<Editor
									editorState={value}
									onEditorStateChange={(content) => setValue(content)}
									wrapperClassName="demo-wrapper"
									editorClassName="text-editor-package"
								/>
							</div>

							<div
								style={{
									width: "100%",
									minHeight: "200px",
									background: "#ececec",
									borderRadius: "10px",
									marginBottom: "10px",
									display: "flex",
									justifyContent: "flex-start",
									alignItems: "flex-start",
									flexDirection: "column",
									gap: "20px",
									padding: "30px 20px",
								}}
							>
								<h3
									style={{
										color: "black",
										fontSize: "1.6rem",
										fontWeight: "700",
									}}
								>
									Content Score:
								</h3>
								<ReactSpeedometer
									value={Number(articleSeoData?.score ?? 0)}
									maxValue={100}
									forceRender
									height={200}
								/>
								<div
									style={{
										display: "flex",
										flexDirection: "column",
										gap: "5px",
										alignItems: "flex-start",
									}}
								>
									<h3
										style={{
											color: "black",
											fontSize: "1.6rem",
											fontWeight: "700",
										}}
									>
										Semantic Keywords:
									</h3>
									<div
										style={{
											display: "flex",
											flexWrap: "wrap",
											gap: "10px",
											justifyContent: "flex-start",
											alignItems: "flex-start",
											width: "100%",
											textAlign: "center",
											padding: "10px 0",
										}}
									>
										{articleSeoData?.semanticallyRelatedWords &&
											articleSeoData?.semanticallyRelatedWords?.map(
												(item, index) => {
													return (
														<span
															key={index}
															style={{
																background: "#7F56D9",
																padding: "5px 10px",
																borderRadius: "5px",
																color: "white",
																textTransform: "capitalize",
															}}
														>
															{item}
														</span>
													);
												}
											)}
									</div>
								</div>
							</div>

							<button
								type="button"
								className="summary_btn"
								onClick={handleRegenerateArticle}
							>
								Regenerate Article
							</button>
						</div>
					)}
				</>
			)}
		</>
	);
};

export default ArticleEditor;
