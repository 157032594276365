import React from "react";
import ReactDOM from "react-dom/client";
import "./styles/styles.scss";
import { Provider } from "react-redux";

import App from "./App";
import reportWebVitals from "./reportWebVitals";

import { doCreateStore } from "./redux/store/createStore";
import initInterceptors from "./services/transportInterceptor";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";

// slick carusol //

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "react-toastify/dist/ReactToastify.css";

//prime-react
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.min.css";
import { BrowserRouter } from "react-router-dom";

const store = doCreateStore();
initInterceptors(store);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
	<Provider store={store}>
		<BrowserRouter>
			<App />
		</BrowserRouter>
		<ToastContainer autoClose={2000} />
	</Provider>
);

reportWebVitals();
